<div class="modal-header border-0">
  <span class="fs-3 h1 w-100 m-0">Favoritos</span>
  <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="col-12">
  <div class="form-group d-flex justify-content-center align-items-center">
    <div class="form-control border-0 d-flex justify-content-end" *ngIf="!showSearch" [@slideLeft]
      (click)="showSearch = !showSearch">
      <em class="material-icons search pointer">search</em>
    </div>
    <div class="col-11 position-relative" *ngIf="showSearch" [@slideRight]>
      <input type="text" class="form-control" [(ngModel)]="filterPost">
      <em class="material-icons close me-3" (click)="showSearch = !showSearch">close</em>
    </div>
  </div>
</div>
<div class="modal-body">
  <div class="col-12" id="all" role="tabpanel" *ngIf="favoritesList.length === 0">
    <div class="filter-error">
      <p>
        <icon-svg svgName="filter-error"></icon-svg>
      </p>
      <span class="br">Não encontramos nada correspondente com o que você buscou...</span>
      <span>Não há favoritos cadastrados!</span>
    </div>
  </div>
  <div class="accordion" id="accordionExample">
    <div class="border-bottom py-1" *ngFor="let item of favoritesList | filter: filterPost; let i = index">
      <div class="row">
        <div class="col-6 col-lg-6 col-sm-12">
          <div id="heading{{item.id}}">
            <span class="btn btn-text py-0 collapsed fs-5" type="button" data-toggle="collapse" role="button"
              attr.data-target="#collapse{{item.id}}" aria-expanded="true" aria-controls="collapse{{item.id}}">
              {{item.name}}
            </span>
          </div>
        </div>
      </div>
      <div id="collapse{{item.id}}" class="collapse" attr.aria-labelledby="heading{{item.id}}"
        data-parent="#accordionExample">
        <div class="row">
          <div class="col-6 col-lg-6 col-sm-12">
            <div class="px-3">
              <div class="col-12">Chave PIX: <strong>{{item.extra_data.key}}</strong></div>
              <div class="col-12">Banco: <strong>{{item.extra_data.ispbName}}</strong></div>
              <div class="col-12">Conta: <strong>{{item.extra_data.accountNumber}}</strong></div>
            </div>
          </div>
          <div class="col">
            <div class="d-flex align-items-center justify-content-end">
              <button class="btn btn-sm btn-primary" (click)="makeTransfer(item)">TRANSFERIR</button>
              <button class="btn btn-sm btn-danger" (click)="remove(item)">EXCLUIR</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>