import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TagModel } from 'ngx-chips/core/tag-model';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {

  @Output() filter_data = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Input() public filter_options = new BehaviorSubject<any>([]);

  bsConfig: Partial<BsDatepickerConfig>

  constructor(
    public modalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    // this.filter_options.subscribe(options => {
    //   for (let option of options) {
    //     if (option.type == 'accounts' && !option?.items.find(x => x.label == 'Todas')) {
    //       option.items.unshift({ 'label': 'Todas', 'value': 'all' })
    //     }
    //   }
    // })
    this.bsConfig = Object.assign({}, {
      containerClass: 'theme-blue',
      adaptivePosition: true,
      showWeekNumbers: false,
      selectFromOtherMonth: true,
      isAnimated: true,
      dateInputFormat: 'DD-MM-YYYY'
    })
  }

  public verifyExclusive(tag): Observable<TagModel> {
    return of(tag);
  }

  search(): void {
    this.filter_data.next(this.filter_options.getValue());
    this.close();
  }

  resetFilters() {

  }

  getOptions() {
    return this.filter_options.getValue()
  }

  getMinDate(field) {
    field.hasError = null
    let maxDate
    let minDate
    if (field.id?.includes('date' && 'max') && field.model) {
      maxDate = field.model

      this.filter_options.forEach(item => {
        if (item.id?.includes('date' && 'min') && item.model) {
          minDate = item.model
        }
      })

      if (moment(maxDate).unix() < moment(minDate).unix()) {
        field.hasError = 'smaller than min date'
        field.model = ''
      }

      return minDate
    }



    return field.minDate
  }

  onChange(filter, e) {
    if (filter.type == 'date')
      filter.model = filter.items[e.target.selectedIndex]
    else
      filter.model = filter.items.find(x => x.value == filter.model.value)
  }

  close(): void {
    this.confirmAndClose(false);
  }

  private confirmAndClose(value: boolean | string) {
    this.modalRef.hide();
  }
}
