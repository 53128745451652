<div class="row">
  <div class="text-center">
    <input type="file" (change)="fileChangeEvent($event)" class="d-none" [id]="idFile" #inputFile />
  </div>
  <div class="{{classUpload}}">
    <div class="d-flex align-items-center mt-n4">
      <img *ngIf="textTooltip" src="assets/images/svg/help-circle.svg" alt="help" class="me-2" [tooltip]="textTooltip">
      <span class="{{classLabelFile}}">{{file ? file.name : labelFile}}</span>
    </div>
    <div class="selfie col-12">
      <img *ngIf="!showImageCropper && src" alt=" " [ngStyle]="{content : srcImg}">
      <div class="row text-center" *ngIf="showImageCropper">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [roundCropper]="true"
          [aspectRatio]="4 / 4" [resizeToWidth]="512" format="png" (imageCropped)="imageCropped($event)"
          class="rounded w-100">
        </image-cropper>
      </div>
    </div>
    <div *ngIf="!showImageCropper" class="mt-2">
      <button type="button" class="btn btn-outline-secondary d-flex align-items-center mx-0" (click)="addSelfie()">
        <em role="button" class="material-icons me-2">upload_file</em>
        Selecionar um arquivo
      </button>
      <span *ngIf="showIcon" class="icon clip"></span>
    </div>
    <div class="col-12 mt-4">
      <div *ngIf="requiredFileText && requiredFile" class="required-file fs-8">{{ requiredFileText }}</div>
    </div>
    <div class="d-flex text-center mt-4" *ngIf="showImageCropper">
      <button type="button" class="btn btn-outline-primary rounded-pill" (click)="cancel(inputFile)">Cancelar</button>
      <button type="button" class="btn btn-primary rounded-pill" (click)="croppedSubmit(inputFile)">Aceitar</button>
    </div>
  </div>
</div>