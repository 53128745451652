export enum GoalEnum {
  car = 'car',
  house = 'house',
  events = 'events',
  travel = 'travel',
  emergency = 'emergency',
  retirement = 'retirement',
  wealth = 'wealth',
  health = 'health',
  education = 'studies',
  growth = 'growth',
  acquisition = 'acquisition',
  payments = 'payments',
  justice = 'justice',
  new = 'other',
  custom = 'custom',
}

export enum GoalIconEnum {
  car = 'icon-transporte',
  house = 'icon-moradia',
  events = 'icon-casamento',
  travel = 'icon-viagens',
  emergency = 'icon-emergencia',
  retirement = 'icon-aposentadoria',
  wealth = 'icon-negocios',
  health = 'icon-saude',
  education = 'icon-estudos',
  growth = 'icon-financeiro',
  acquisition = 'icon-aquisicoes',
  payments = 'icon-dividas',
  justice = 'icon-justica',
  new = 'icon-novos-objetivos',
}

export enum GoalNameEnum {
  car = 'Veículo',
  house = 'Moradia / Imóvel',
  events = 'Eventos',
  travel = 'Viagens',
  emergency = 'Reserva de emergência',
  retirement = 'Aposentadoria',
  wealth = 'Criando um negócio',
  health = 'Saúde',
  education = 'Formação',
  growth = 'Crescimento (investimentos)',
  acquisition = 'Aquisição de bens',
  payments = 'Quitar dívidas',
  justice = 'Acesso à justiça',
  new = 'Novo objetivo',
}

export enum GoalNumeraireIndexEnum {
  car = 'BRL',
  house = 'CDI',
  events = 'BRL',
  travel = 'USD',
  emergency = 'CDI',
  retirement = 'IPCA',
  wealth = 'CDI',
  health = 'IPCA',
  education = 'IPCA',
  growth = 'CDI',
  acquisition = 'BRL',
  payments = 'BRL',
  justice = 'BRL',
  new = 'IPCA',
}