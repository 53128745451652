import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UploadFileService } from 'src/app/components/upload/upload-file.service';
import { AppNavRouteLink } from 'src/app/shared/commons/AppNavRouteLink';
import { Utils } from 'src/app/shared/commons/Utils';
import { FileSubType, FileType } from 'src/app/shared/enums/file-type.enum';
import { AccountModel } from 'src/app/shared/interfaces/account-model';
import { ClientModel } from 'src/app/shared/interfaces/client-model';
import { ClientService } from 'src/app/shared/services/client.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { StorageService as storage } from 'src/app/shared/services/storage.service';
import { UtilService } from 'src/app/shared/services/util.service';

class EventEmitter<T> {
}

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit {
  quick_access: any;

  showNavigateMenu = false;
  showMenu = false;
  showMenuRight = false;
  disabledPix = true;
  client = {} as ClientModel;
  account = {} as AccountModel;
  pix: any = null;
  dashboard = [];
  photo = 'assets/images/svg/photo.svg';

  routes = {
    'dashboard': 'Início',
    'my-investments': 'Investimentos',
    'my-accounts': 'Minhas Contas',
    'my-cards': 'Meus Cartões',
    'my-data': "Meus Dados",
    'pix': 'PIX',
    'financial-planning': "Planejamento Financeiro",
    'limits': 'Limites',
    'notifications': 'Notificações',
    'deposit-by-bank-slip': 'Depósito por boleto',
    'exchange': 'Câmbio',
    'my-loans': 'Meus Empréstimos',
    'suitability': 'Perfil de investidor',
    'choice-risk-profile': 'Perfil de investidor',
  };

  @HostListener('document:click', ['$event']) clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target) || event.target.className === 'menu-item-link') {
      this.showMenu = false;
      this.showMenuRight = false;
    }
  }

  constructor(
    public router: Router,
    private eRef: ElementRef,
    private commonService: CommonService,
    private clientService: ClientService,
    private fileService: UploadFileService,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    this.clientService.clientData.subscribe(d => {
      if (!d) { return; }
      this.client = d;
    });

    this.clientService.accountData.subscribe(d => {
      if (!d) { return; }
      this.account.bank_code = d.bank_code;
      this.account.branch = d.branch;
      this.account.number = Utils.maskAccount(d.number);
    });

    // active menu
    this.showNavigateMenu = true;
    if (this.client.real_client) {
      this.getFiles()
    }

    this.clientService.pixData.subscribe(d => {
      this.disabledPix = !d;
    });

  }

  /**
   * CHECK PROTECTED ROUTER
   * @param url URL
   */
  checkProtectedRouter(url: string): Boolean {
    if (this.client) {
      return this.utilService.routerIsProtected(url, this.client);
    } else {
      return this.utilService.routerIsProtected(url);
    }
  }

  logout() {
    this.commonService.revokeJWT()
      .then(() => {
        this.clientService.resetData();
        storage.deleteClientJwt();
        storage.clear();
        this.router.navigate([AppNavRouteLink.PAGE.LOGIN]);
      })
      .catch(err => {
        this.router.navigate([AppNavRouteLink.PAGE.LOGIN]);
      });
  }

  getFiles() {
    this.fileService.read().then(result => {
      if (result.files.length > 0) {
        result.files.forEach(res => {
          if (res.type === FileType.document && res.sub_type === FileSubType.selfie) {
            this.photo = res.url_path;
          }
        });
      }
    })
  }

  scroll_top() {
    this.showMenu = false;
    this.showMenuRight = false;
    window.scrollTo(0, 0);
  }

  navigate(route: string) {
    this.quick_access = false
    if (route == 'any') {
      this.router.navigate(this.router.url.split("/").slice(0, 3));
    } else {
      this.router.navigate(this.router.url.split("/").slice(0, 4));
    }

    this.scroll_top();
  }

  get routerLink() { return AppNavRouteLink; }
  get utils() { return Utils }
}
