<div class="modal-content px-4">
  <div class="modal-header border-0 d-flex justify-content-center">
    <strong class="fs-3"> Características principais </strong>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col col-12 text-center" [innerHTML]="msg"></div>
      <div class="col col-12 text-center my-3">
        <strong class="fs-3">Ativos e seus limites</strong>
        <!--p class="fs-6 text-danger">
          <strong>Atualmente, o acesso à plataforma de investimentos não está liberado</strong>
        </p-->
      </div>
      <div class="col-12">
        <div class="d-flex align-items-center my-2" *ngFor="let item of assetList.options" [ngClass]="{'disabled': item.limit == 0}">
          <div class="col-11">
            <span [style.background]="item.color" class="px-2 rounded me-4"></span>
            {{item.asset}}
          </div>
          <div class="col-1">
            {{item.percent | percent: '1.0-2'}}
          </div>
        </div>
      </div>
    </div>
    <div class="col my-3 text-center">
      <button type="button" class="btn btn-primary" (click)="modalRef.hide()">OK, ENTENDI</button>
    </div>
  </div>
</div>
