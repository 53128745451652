import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { GoalEnum, GoalIconEnum } from 'src/app/shared/enums/goals.enum';
import { FinancialService } from 'src/app/shared/services/financial.service';

@Component({
  selector: 'app-goal-dialog',
  templateUrl: './goal-dialog.component.html',
  styleUrls: ['./goal-dialog.component.scss']
})
export class GoalDialogComponent implements OnInit {

  confirmResult: Subject<string>;
  listGoal: any[] = [];
  goalActive: any;

  constructor(
    private financialService: FinancialService,
    public modalRef: BsModalRef
  ){ }

  ngOnInit(): void {
    this.confirmResult = new Subject();

    this.listGoal = this.financialService.goals
  }

  onClose(): void {
    this.confirmAndClose('objectives');
  }

  onConfirm(): void {
    // if (this.goalActive) {
    //   alert(`TODO: O sistema frontend deverá apresentar direcionar para a página Criar Objetivo - ${this.goalActive.name}. 
    //   E  em seguida, o novo objetivo criado deve ser incluso no final da lista, ou seja, com a menor prioridade e deve ter o flag “garantido” desativado. 
    //   A barra de tempo até atingimento deve estar em 0% e as chances de atingimento devem estar em 0%.`);
    // }
    this.confirmAndClose(this.goalActive.value);
  }

  private confirmAndClose(value: string) {
    this.confirmResult.next(value);
    this.modalRef.hide();
  }
}
