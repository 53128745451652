export const INCOME_TYPE_ITEMS = (): any[] => {
	return [
		{
			value: "salary",
			label: "Salário",
		},
		{
			value: "retirement",
			label: "Aposentadoria",
		},
		{
			value: "other",
			label: "Outro",
		},
		{
			value: "cash",
			label: "Dinheiro",
		},
		{
			value: "house",
			label: "Casa",
		},
		{
			value: "pension",
			label: "Pensão",
		},
		{
			value: "rent",
			label: "Aluguel",
		},
		{
			value: "bonus",
			label: "Bônus",
		},
		{
			value: "patrimony",
			label: "Patrimônio",
		}
	]
}