import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Utils, valueOrDefault } from 'src/app/shared/commons/Utils';
import { Validations } from 'src/app/shared/commons/Validations';
import { GenderEnum } from 'src/app/shared/enums/gender.enum';
import { MaritalStatusEnum } from 'src/app/shared/enums/marital-status.enum';
import { AddressModel } from 'src/app/shared/interfaces/address-model';
import { ClientModel } from 'src/app/shared/interfaces/client-model';
import { ClientService } from 'src/app/shared/services/client.service';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-personal-data-dialog',
  templateUrl: './personal-data-dialog.component.html',
  styleUrls: ['./personal-data-dialog.component.scss']
})
export class PersonalDataDialogComponent implements OnInit {

  client = {} as ClientModel
  address = {} as AddressModel
  formGroup: UntypedFormGroup
  confirmResult: Subject<boolean | string>
  autoCompleteList: [] = null
  listMaritalStatus: any = []
  listGender: any = []
  birthDate: string
  income: string | number
  currencyOption = { prefix: 'R$ ', thousands: '.', decimal: ',', suffix: '', precision: 2 }

  constructor(
    private clientService: ClientService,
    private formBuilder: UntypedFormBuilder,
    public modalRef: BsModalRef,
    private utilService: UtilService,
  ) { }

  ngOnInit() {
    this.confirmResult = new Subject()
    this.listMaritalStatus = this.getMaritalStatus()
    this.listGender = this.getGenders()
    this.clientService.clientData.subscribe(d => {
      if (!d) { return }
      this.client = d
      this.birthDate = moment(this.client.birth_date).format('DD/MM/YYYY')
      this.income = this.client.income
    })

    this.buildInformationForm()

  }

  buildInformationForm() {
    this.formGroup = this.formBuilder.group({
      friendly_name: [this.client.friendly_name],
      name: [this.client.name, Validators.compose([
        Validators.required,
        Validators.pattern(Utils.getNamePattern())
      ])],
      document_number: [this.client.document_number, Validators.compose([
        Validators.required,
        Validations.CPF
      ])],
      marital_status: [valueOrDefault(this.client?.marital_status, 'single'), Validators.required],
      secondary_document_number: [this.client.secondary_document_number, Validators.compose([
        Validators.required
      ])],
      mother_name: [this.client.mother_name, Validators.compose([
        Validators.required,
        Validators.pattern(Utils.getNamePattern())
      ])],
      birth_date: [this.birthDate, Validators.compose([
        Validators.required,
        Validations.Date,
        Validations.IsMinor
      ])],
      income: [this.income, Validators.required],
      occupation: [this.client.occupation, Validators.required],
      gender: [this.client?.gender, Validators.required],
    })

    this.f.name.disable()
    this.f.secondary_document_number.disable()
    this.f.document_number.disable()
    this.f.mother_name.disable()
    this.f.mother_name.disable()
    this.f.birth_date.disable()
  }

  getProfessions(page: number = 1, name?: string) {
    let data: any = {
      page: page,
      profession: name
    }

    if (data.profession) {
      this.clientService.getCob(this.client.uuid, data).then(res => {
        this.autoCompleteList = res.professions
      })
    }
  }

  getMaritalStatus() {
    return Object.keys(MaritalStatusEnum).map(key => {
      return ({ value: key.toString(), name: MaritalStatusEnum[key] })
    })
  }

  getGenders() {
    return Object.keys(GenderEnum).map(key => {
      return ({ value: key.toString(), name: GenderEnum[key] })
    })
  }

  setOccupation(event) {
    this.client.occupation = event
    this.client.occupation_id = '000100'
  }

  save() {
    if (this.formGroup.valid) {
      const clientData = Object.assign({} as any, this.utilService.dirty_controls_data(this.f))

      if (Object.keys(clientData).length > 0) {
        if (clientData.document_number) {
          clientData.document_number = Utils.unMask(clientData.document_number)
        }
        if (clientData.birth_date) {
          clientData.birth_date = moment(moment(clientData.birth_date, 'DD/MM/YYYY').toDate()).format('YYYY-MM-DD')
        }
        if (clientData.income) {
          clientData.income = clientData.income
        }

        delete clientData.confirm_email
        this.clientService.update(clientData).then(result => {
          this.client = result
        })
      }

      this.closeSuccess()
    } else {
      this.formGroup.markAllAsTouched()
    }
  }

  close() {
    this.confirmResult.next(false)
    this.modalRef.hide()
  }

  closeSuccess() {
    this.confirmResult.next(true)
    this.modalRef.hide()
  }

  get f() {
    return this.formGroup.controls
  }
}
