<div class="modal-content poppins">
  <div class="modal-header border-0">
    <div class="fs-3 h1 w-100 m-0 text-center">Atenção!</div>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
  </div>
  <div class="modal-body">
    <div class="row justify-content-around align-items-center">
      <div class="col-12 col-md-11 mx-auto my-4">
        <strong class="fs-4 text-center">Para trilharmos esta jornada
          juntos precisamos informá-lo sobre o nosso Termo de Privacidade e o tratamentos dos seus dados.
        </strong>
      </div>
      <div class="col-11 col-md-10 mx-auto border rounded overflow-y" (scroll)="onScroll($event)">
        <p >
          A coleta deste dado tem como objetivo parametrizar as suas necessidades para o aprimoramento dos serviços
          oferecidos pela LadyBank.
        </p>
        <p >
          Além do aprimoramento, o tratamento de dados terá como objetivo auxiliar no desenvolvimento de marketing,
          prospecção, pesquisas de mercado, de opinião e promoção dos nossos produtos e serviços, ou de nossos
          parceiros, inclusive com viabilização de ofertas e envio de informações sobre produtos, serviços, novidades,
          funcionalidades, conteúdos, notícias e demais eventos relevantes para a manutenção do relacionamento com você.
        </p>
      </div>
      <div class="col-11 col-md-10 px-0 my-4">
        <input type="checkbox" id="check-term" [checked]="accept" [value]="accept" (change)="accept = !accept">
        <label for="check-term" class="poppins fw-bold ms-2 my-0">Aceito os termos e condições</label>
      </div>
      <div class="row col-12 justify-content-center">
        <button type="button" class="col col-12 col-md-5 btn btn-outline-dark" (click)="onClose()">Cancelar</button>
        <button type="button" class="col col-12 col-md-5 my-3 my-md-0 btn btn-primary" [disabled]="!accept"
          (click)="onClose()">
          Aceitar</button>
      </div>
    </div>
  </div>
</div>