export class ConfirmDialogViewModel {
  class?: string;
  backdrop?: boolean;
  title: string;
  subTitle: string;
  msg: string;
  txtOk?: string;
  txtCancel?: string;
  hiddenCancel?: boolean;
  showOk: boolean;
  txtLink?: string;
  success?: boolean;
  tryAgain?: boolean;
  txtTryAgain?: string;
  showPixLimits?: boolean;
  msgSuccess?: string;
  msgError?: string;
}

// CLASSES:
// Small	      .modal-sm	  300px
// Default      None	      500px
// fs-4       .modal-md	      500px
// Large	      .modal-lg	  800px
// Extra large	.modal-xl	  1140px

