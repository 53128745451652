import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { BlockStatusCard } from 'src/app/shared/commons/BlockStatusCard';
import { Validations } from 'src/app/shared/commons/Validations';
import { StatusCardEnum } from 'src/app/shared/enums/status-card.enum';

@Component({
  selector: 'app-options-card-dialog',
  templateUrl: './options-card-dialog.component.html',
  styleUrls: ['./options-card-dialog.component.scss']
})
export class OptionsCardDialogComponent implements OnInit {

  model: any = {};
  confirmResult: Subject<boolean>;
  formGroup: UntypedFormGroup
  reasonList: any = [];
  showForm = false
  showPassword = false;
  showConfirmPassword = false

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.confirmResult = new Subject();

    setTimeout(() => {
      if (this.model.type == this.Type.blocked) {
        this.formGroup = this.formBuilder.group({
          reason: ['', Validators.required]
        })
      } else if (this.model.type == this.Type.unblocked) {
        this.formGroup = this.formBuilder.group({})
      } else if (this.model.type == this.Type.received) {
        this.formGroup = this.formBuilder.group({
          last4_digits: ['', [Validators.required, Validators.minLength(4)]],
        })
      } else if (this.model.type == this.Type.active) {
        this.formGroup = this.formBuilder.group({
          last4_digits: ['', Validators.required],
          password: ['', Validators.compose([Validators.required])]
        })
      } else if (this.model.type == this.Type.newPin) {
        this.formGroup = this.formBuilder.group({
          password: ['', Validators.compose([Validators.required])],
          confirm_password: ['', Validators.required]
        }, {
          validator: Validations.PasswordMatch
        })
      }
      this.getReasonList()
      this.showForm = true
    }, 100);
  }

  getReasonList() {
    this.reasonList = BlockStatusCard.get().filter(x => x.type.includes(this.model.action))
  }

  close(value) {
    this.confirmResult.next(value);
    this.modalRef.hide();
  }

  send() {
    if (this.formGroup.valid) {
      this.close(this.formGroup.value);
    } else {
      this.formGroup.markAllAsTouched()
    }
  }

  get f() { return this.formGroup.controls }

  get Type() { return StatusCardEnum }
}
