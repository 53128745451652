<div class="overflow-auto vh-75">
  <div class="col-12 justify-content-center">
    <p class="fs-3 h1 my-2 text-center">Enviar Selfie</p>
    <h5 class="text-center">Use a barra de rolagem e confira os cuidados ao tirar e enviar a sua selfie</h5>
  </div>
  <div class="col-12 justify-content-center">
    <div class="selfie tip"></div>
  </div>
  <div class="col-12 d-flex justify-content-center my-lg-4 mt-5">
    <h5>A iluminação deve ser suficiente</h5>
  </div>
  <div class="mx-auto col col-11 justify-content-center">
    <div class="row">
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie iluminacao-1 img-height-1"></div>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie iluminacao-2 img-height-2"></div>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie iluminacao-3 img-height-2"></div>
      </div>
      <div class="col-12 mt-4">
        <p>O adequado é que a foto seja realizada em ambiente com luz intermediária, sem excesso de luz
          ou com pouca luz.
        </p>
        <p>
          Preste atenção às janelas e evite que a luz solar incida diretamente no seu rosto. Quando a Luz for
          insuficiente, use uma luminária de mesa para aumentar a luminosidade. Não direcione a luminária diretamente
          para o seu rosto. A Selfie não pode apresentar reflexos.
        </p>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-center my-lg-4 mt-5">
    <h5>Não utilizar o flash na Selfie</h5>
  </div>
  <div class="mx-auto col col-11 justify-content-center">
    <div class="row">
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie selfie-1 img-height-1"></div>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie selfie-2 img-height-2"></div>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie selfie-3 img-height-2"></div>
      </div>
      <div class="col-12 mt-4">
        <p>O brilho do flash pode criar o efeito “olhos vermelhos” dificultando o reconhecimento.
        </p>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-center my-lg-4 mt-5">
    <h5>A foto não pode apresentar sombras em partes do rosto</h5>
  </div>
  <div class="mx-auto col col-11 justify-content-center">
    <div class="row">
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie shadow-1 img-height-1"></div>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie shadow-2 img-height-2"></div>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie shadow-3 img-height-2"></div>
      </div>
      <div class="col-12 mt-4">
        <p>A selfie não pode apresentar uma parte do rosto coberta por sombras. Para evitar o
          sombreamento, faça a luz incidir de forma igual no rosto todo.
        </p>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-center my-lg-4 mt-5">
    <h5>Mantenha os olhos abertos e retire o cabelo de cima do rosto</h5>
  </div>
  <div class="mx-auto col col-11 justify-content-center">
    <div class="row">
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie eyes-and-hair-1 img-height-1"></div>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie eyes-and-hair-2 img-height-2"></div>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie eyes-and-hair-3 img-height-2"></div>
      </div>
      <div class="col-12 mt-4">
        <p>Não deixe que o cabelo, ou outros acessórios, cubra os olhos ou qualquer outra parte do
          rosto. Se tiver franja, faça com que ela vá para o lado e desobstrua os olhos.
        </p>
        <p>Na Selfie, você não pode estar com os olhos fechados.</p>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-center my-lg-4 mt-5">
    <h5>A selfie deve ser na pose frontal</h5>
  </div>
  <div class="mx-auto col col-11 justify-content-center">
    <div class="row">
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie front-pose-1 img-height-1"></div>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie front-pose-2 img-height-2"></div>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie front-pose-3 img-height-2"></div>
      </div>
      <div class="col-12 mt-4">
        <p>Para a identificação correta faça a foto de frente, assim o sistema de biometria facial fará
          o reconhecimento da imagem do rosto mais rapidamente.
        </p>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-center my-lg-4 mt-5">
    <h5>Fundo para foto</h5>
  </div>
  <div class="mx-auto col col-11 justify-content-center">
    <div class="row">
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie photo-background-1 img-height-1"></div>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie photo-background-2 img-height-2"></div>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie photo-background-3 img-height-2"></div>
      </div>
      <div class="col-12 mt-4">
        <p>Evite fundos com interferências. Não deve conter imagens ou objetos ao fundo. Centralize o
          rosto na Selfie nas posições vertical e horizontal. Não corte partes do rosto.
        </p>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-center my-lg-4 mt-5">
    <h5>Evite usar óculos</h5>
  </div>
  <div class="mx-auto col col-11 justify-content-center">
    <div class="row">
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie without-glasses-1 img-height-1"></div>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie without-glasses-2 img-height-2"></div>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie without-glasses-3 img-height-2"></div>
      </div>
      <div class="col-12 mt-4">
        <p>Só faça a selfie usando óculos se os documentos de papel, como RG, Carteira de Motorista,
          Passaporte, também apresentarem óculos.</p>
        <p>Óculos com aros grossos podem interferir no reconhecimento facial. </p>
        <p>Evite que o aro fique posicionado em cima dos olhos. </p>
        <p>Se utilizar óculos com lentes que escurecem de acordo com a iluminação ambiente, cuide para
          que as lentes não estejam muito escuras ou que haja reflexos nas lentes impedindo visualizar os olhos.</p>
      </div>
    </div>
  </div>
  <div class="col-12 d-flex justify-content-center my-lg-4 mt-5">
    <h5>Não use acessórios</h5>
  </div>
  <div class="mx-auto col col-11 justify-content-center">
    <div class="row">
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie without-accessories-1 img-height-1"></div>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie without-accessories-2 img-height-2"></div>
      </div>
      <div class="col-lg-4 col-sm-12 d-flex align-items-end">
        <div class="selfie without-accessories-3 img-height-2"></div>
      </div>
      <div class="col-12 mt-4">
        <p>Evite qualquer coisa que, em uma foto, coincida com o contorno de seu rosto, alterando-o,
          como por exemplo: Chapéus, bonés, echarpes, brincos muito grandes.</p>
      </div>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center my-3">
  <button type="button" class="btn btn-sm btn-sm-block btn-outline-dark" data-dismiss="modal"
    (click)="onClose()">CANCELAR
  </button>
  <button type="button" class="btn btn-sm btn-sm-block btn-primary" (click)="onConfirm()">SELECIONAR
    SELFIE
  </button>
</div>