import { Utils } from './Utils';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { validarBoleto } from '@mrmgomes/boleto-utils';

export class Validations {

  static Password(control: AbstractControl) {
    if (control.value == null) {
      return;
    }

    let valido = true;
    let password = control.value;
    // mínimo 6 caracteres, 1 número e 1 caractere especial
    const regex = /^(?=.*\d)(?=.*[$&+=?@#|'<>.^*()%!-])[A-Za-z\d$&+=?@#|'<>.^*()%!-]{6,200}$/

    if (!regex.test(password)) {
      valido = false;
    }

    if (valido) { return null; }

    return { invalidPassword: true };
  }

  static CPF(control: AbstractControl) {
    let s = control.value;
    if (s == null || !s || s == '' || s.value == '') {
      return;
    }

    if (Validations.prototype.testCpf(s)) { return null; }

    return { invalid: true };
  }

  static CNPJ(control: AbstractControl) {
    let s = control.value;
    if (s == null || !s || s == '' || s.value == '') {
      return;
    }

    if (Validations.prototype.testCnpj(s)) { return null; }

    return { invalid: true };
  }

  static Account(control: AbstractControl) {
    let s = control.value;
    if (s == null || !s || s == '' || s.value == '') {
      return;
    }

    let valid = true;
    const tam = s.replace(/\D/g, '').length;

    valid = (tam > 4 && tam <11)

    if (valid) { return null };

    return { invalid: true }
  }

  static CpfOrCnpj(control: AbstractControl) {
    let s = control.value;
    if (s == null || !s || s == '' || s.value == '') {
      return;
    }

    let valid = true;
    const tam = s.replace(/\D/g, '').length;

    if (tam <= 11) {
      // s = this.maskCPF(s);
      valid = Validations.prototype.testCpf(s);
    }
    else if (tam <= 14) {
      // s = this.maskCNPJ(s);
      valid = Validations.prototype.testCnpj(s);
    }

    if (valid) { return null };

    return { invalid: true }
  }

  testCpf(cpf): boolean {
    let valido = true;

    if (cpf !== null && cpf.length > 0) { cpf = cpf.replace(/\D/g, ''); }
    const regex = /1{11}|2{11}|3{11}|4{11}|5{11}|6{11}|7{11}|8{11}|9{11}|0{11}/;

    let x = cpf.length - 1;
    let digitosTemp = 0;
    let e = 0;
    let h = '';

    if (cpf.length !== 11 || regex.test(cpf)) {
      valido = false;
    }
    else {
      for (let i = 0; i <= cpf.length - 3; i++) {
        digitosTemp = cpf.substring(i, i + 1);
        e = e + (digitosTemp * x);
        x -= 1;
        h = h + digitosTemp;
      }

      let numbers = 11 - (e % 11);
      if (numbers === 10 || numbers === 11) {
        numbers = 0;
      }

      const cpfSemDigVer = cpf.substring(0, cpf.length - 2) + numbers;
      x = 11;
      e = 0;
      for (let j = 0; j <= (cpf.length - 2); j++) {
        e += (cpfSemDigVer.substring(j, j + 1) * x);
        x -= 1;
      }

      let digVerification = 11 - (e % 11);
      if (digVerification === 10 || digVerification === 11) {
        digVerification = 0;
      }

      valido = ((numbers + '' + digVerification) === cpf.substring(cpf.length, cpf.length - 2));
    }

    return valido;
  }

  testCnpj(cnpj): boolean {
    let valido = true;

    if (cnpj !== null && cnpj.length > 0) { cnpj = cnpj.replace(/\D/g, ''); }

    const regex = /1{11}|2{11}|3{11}|4{11}|5{11}|6{11}|7{11}|8{11}|9{11}|0{11}/;

    if (cnpj.length !== 14 || regex.test(cnpj)) {
      valido = false;
    } else if (cnpj == '00000000000000' ||
      cnpj == '11111111111111' ||
      cnpj == '22222222222222' ||
      cnpj == '33333333333333' ||
      cnpj == '44444444444444' ||
      cnpj == '55555555555555' ||
      cnpj == '66666666666666' ||
      cnpj == '77777777777777' ||
      cnpj == '88888888888888' ||
      cnpj == '99999999999999') {
      valido = false;
    } else {
      let tamanho = cnpj.length - 2
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;

      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
          pos = 9;
      }

      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0)) {
        valido = false;
      } else {
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
            pos = 9;
        }

        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
          valido = false;
      }
    }

    return valido;
  }

  static Date(control: AbstractControl) {
    if (control.value == null) {
      return;
    }

    let dateFild = control.value.toString();
    dateFild = dateFild.replace(/\D/g, '');
    let valido = false;

    if (moment(dateFild, 'DDMMYYYY', true).isValid()) {
      valido = true;
    }

    if (moment(control.value, 'DDMMYYYY', true).isValid()) {
      valido = true;
    }

    if (valido) { return null; }

    return { invalidDate: true };
  }

  static IsMinor(control: AbstractControl) {
    if (control.value == null) {
      return;
    }

    let dateFild = control.value.toString();
    dateFild = dateFild.replace(/\D/g, '');

    if (moment(dateFild, 'DDMMYYYY', true).isValid()) {
      const birth = moment(dateFild, 'DDMMYYYY').toDate();
      const today = new Date();

      if (today != null && birth != null) {
        let betweenYears = today.getFullYear() - birth.getFullYear();
        if (
          new Date(today.getFullYear(), today.getMonth(), today.getDate()) <
          new Date(today.getFullYear(), birth.getMonth(), birth.getDate())
        ) {
          betweenYears--;
        }
        if (betweenYears < 18) {
          return { isMinor: true };
        }

        return null;
      }
    }
  }

  static EmailsMatch(control: AbstractControl) {
    const email = control.get('email').value;
    const confirmEmail = control.get('confirm_email').value;
    if (!confirmEmail) {
      return;
    }

    if (email === confirmEmail) { return null; }

    control.get('confirm_email').setErrors({ doNotMatch: true });
  }

  static GroupEmailsMatch(control: AbstractControl) {
    const email = control.get('client.email').value;
    const confirmEmail = control.get('client.confirm_email').value;
    if (!confirmEmail) {
      return;
    }

    if (email === confirmEmail) { return null; }

    control.get('client.confirm_email').setErrors({ doNotMatch: true });
  }

  static PasswordMatch(control: AbstractControl) {
    const password = control.get('password').value;
    const confirmPassword = control.get('confirm_password').value;
    if (!confirmPassword) {
      return;
    }

    if (password === confirmPassword) { return null; }

    control.get('confirm_password').setErrors({ doNotMatch: true });
  }

  static NewPasswordMatch(control: AbstractControl) {
    const password = control.get('password').value;
    const newPassword = control.get('new_password').value;
    const confirmPassword = control.get('confirm_password').value;

    if (newPassword !== undefined && password === newPassword) {
      control.get('new_password').setErrors({ match: true });
    } else if (confirmPassword != '' && confirmPassword !== newPassword) {
      control.get('confirm_password').setErrors({ doNotMatch: true });
    }

    return null;
  }

  public static IsNullOrEmpty(value) {
    if (value === undefined) {
      return true;
    } else if (value === null) {
      return true;
    } else if (value === '') {
      return true;
    }

    return false;
  }

  static Phone(control: AbstractControl) {
    if (control.value == null) {
      return;
    }

    let phone = control.value.toString();

    phone = !phone || phone.replace(/\D/g, '');
    const phonesInvsRegex2 = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;

    if (phone === true) {
      return null;
    }

    if (phonesInvsRegex2.test(phone) === false) {
      return { invalid: true };
    }

    if (phone.length !== 11) {
      return { invalid: true };
    }

    return null;
  }

  /**
   * SIZE EM MB
   * @param type 
   * @param size 
   */
  static File(type: string[], size?: number, required?: boolean) {
    return function (control: UntypedFormControl) {

      if (required && Object.keys(control.value).length == 1) {
        return { required: true };
      }

      if (!control.pristine) {
        const file = control.value;
        if (file) {
          if (file.url_path) {
            return null;
          }

          size = size * 1024000;
          if (file.size > size || !type.includes(file.type)) {
            return {
              requiredFileType: true
            };
          }

          return null;
        }
      }

      return null;
    };
  }

  static Boleto(control: AbstractControl) {
    if (!control.pristine) {
      if (control.value) {
        try {
          let result = validarBoleto(control.value);
          if (!result.sucesso) {
            return { invalid: true, msg: result.mensagem }
          }
        } catch(e) {
          console.error(e);
          return {invalid: true, msg: 'Código inválido'};
        }
      }
    }

    return null;
  }

  static minCurrency(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return;
      }

      let value = Utils.moneyTofloat(control.value);

      if (value < min) {
        return { minValue: true };
      }

      return null;
    }
  }

  static maxCurrency(max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return;
      }

      let value = Utils.moneyTofloat(control.value);

      if (value > max) {
        return { maxValue: true };
      }

      return null;
    }
  }
}
