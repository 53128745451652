<div class="modal-header border-0">
  <span class="fs-3 text-center fw-bold w-100 m-auto"> Ótimo! Agora estabeleça os seus objetivos de vida </span>
  <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<p class="modal-text">
  Considerando seu momento atual, sugerimos alguns objetivos que podem ter impacto no seu orçamento e nos planos de
  investimento. Clique para incluir ou excluir os objetivos desejados ou crie novos objetivos de acordo com as suas
  necessidades.
</p>
<div class="modal-body">
  <div class="col-12 px-4">
    <div class="row">
      <ul *ngFor="let item of listGoal" class="col-6 col-md-4 col-lg-2">
        <li *ngIf="item.contem">
          <label class="card" [class.active]="item?.selected" for="goal-{{ item.value }}" role="button">
            <div class="card-body d-flex align-items-center justify-content-center py-2 px-0" [style.height.px]="70">
              <icon-svg [svgName]="item.icon" class="me-2"></icon-svg>
              <span class="text-wrap">{{item.name}}</span>
              <input type="checkbox" class="d-none" name="situation" id="goal-{{ item.value }}"
                [(ngModel)]="item.selected" />
            </div>
          </label>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center mb-4">
  <button type="button" class="btn btn-outline-dark" data-dismiss="modal" (click)="modalRef.hide()">VOLTAR</button>
  <button type="button" class="btn btn-primary" (click)="onConfirm()">CONTINUAR</button>
</div>