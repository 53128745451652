<div class="modal-header border-0">
  <span class="fs-3 text-center fw-bold w-100 m-auto"> Adicionar novo objetivo </span>
  <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<p class="text-center">Por favor, escolha um objetivo pré-definido ou crie um novo objetivo.</p>
<div class="modal-body">
  <ul class="row gap-0 row-gap-3">
    <li class="col-11 col-md-6 col-lg-4" *ngFor="let item of listGoal">
      <button class="btn btn-goal m-0" [class.active]="goalActive === item" (click)="goalActive = item">
        <icon-svg [svgName]="item.icon" class="me-2"></icon-svg>
        <span>{{item.name}}</span>
      </button>
    </li>
  </ul>
</div>
<div class="modal-footer text-center border-0 m-auto">
  <button type="button" class="btn btn-outline-dark" data-dismiss="modal" (click)="modalRef.hide()">VOLTAR</button>
  <button type="button" class="btn btn-primary" (click)="onConfirm()" [disabled]="!goalActive">CONTINUAR</button>
</div>