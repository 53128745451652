<div class="content-login">
  <div class="content-image">
    <div class="col-11 col-md-7 col-lg-5 col-xl-4 mx-auto">
      <div class="shadow bg-white rounded pt-5" id="teste_pode_remover">
        <a href="/" class="d-flex justify-content-center">
          <img src="assets/images/png/logo_ladybank_lg.png" alt="">
        </a>
        <div class="px-5 poppins">
          <p class="my-4 poppins-bold text-center fs-4">Fazer Login</p>
          <div class="col-12 col-lg-11 mx-auto fs-7">
            <ul>
              <li>
                <strong>Acesso pessoal</strong>, insira e-mail ou CPF cadastrado.
              </li>
              <li>
                <strong>Acesso profissional</strong>, insira e-mail ou CNPJ cadastrado.
              </li>
            </ul>
          </div>
          <form [formGroup]="formLogin" class="row gap-3 justify-content-center px-3 pb-5"
            [autocomplete]="autocomplete ? 'on' : 'off'" novalidate (submit)="login($event)">
            <div class="form-group">
              <label for="login">E-mail, CPF ou CNPJ</label>
              <input type="email" class="form-control" placeholder="" formControlName="login" (blur)="verifyField()"
                maxlength="128">
              <div *ngIf="(!f.login.pristine || f.login.touched) && f.login.errors" class="invalid-feedback fs-8">
                <div *ngIf="f.login.errors.required"> Campo obrigatório. </div>
                <div *ngIf="f.login.errors.invalid"> E-mail ou Documento inválido </div>
              </div>
            </div>
            <div class="form-group">
              <label for="password">Senha</label>
              <div class="input-group">
                <input [type]="typeInput" id="password" name="password" class="form-control border-end-0"
                  placeholder="&exemplo1" formControlName="password">
                <div class="input-group-text bg-transparent rounded-end"
                  [ngClass]="{'border border-danger border-start-0': (!f.password.pristine || f.password.touched) && f.password.errors }">
                  <em class="material-icons pointer" (click)="previewPassword()">{{iconPassword}}</em>
                </div>
                <div *ngIf="(!f.password.pristine || f.password.touched) && f.password.errors"
                  class="invalid-feedback fs-8">
                  <div *ngIf="f.password.errors.required"> Campo obrigatório. </div>
                  <div *ngIf="!f.password.errors.required && f.password.errors.invalidPassword"> A senha deve conter
                    no mínimo 6 caracteres, 1 número e 1 caractere especial. </div>
                </div>

              </div>
            </div>
            <div class="d-flex justify-content-between fs-8">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="remember" [(ngModel)]="autocomplete"
                  [ngModelOptions]="{standalone: true}">
                <label class="form-check-label" for="remember">Relembrar</label>
              </div>
              <label class="text-primary m-0 p-0" role="button" (click)="onForgotPassword()">Esqueci a senha</label>
            </div>
            <div class="row mt-4">
              <button class="btn btn-outline-primary col my-1" type="button" (click)="back()">VOLTAR</button>
              <button class="btn btn-primary col my-1" type="submit" [disabled]="!formLogin.valid">ENTRAR</button>
            </div>
            <div class="d-flex justify-content-center align-items-center py-2 mt-3">
              <label>Ainda não é cliente? <a class="pointer" (click)="onOpenAccount()">Cadastre-se</a></label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>