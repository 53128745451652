import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppNavRouteLink } from 'src/app/shared/commons/AppNavRouteLink';
import { ClientService } from 'src/app/shared/services/client.service';

@Component({
  selector: 'app-finish-register',
  templateUrl: './finish-register.component.html',
  styleUrls: ['./finish-register.component.scss']
})
export class FinishRegisterComponent implements OnInit {

  constructor(
    private clientService: ClientService,
    private rounter: Router
  ) { }

  ngOnInit(): void {
    this.clientService.emailVerify()
  }

  go() {
    this.rounter.navigate([AppNavRouteLink.PAGE.LOGIN]);
  }
}
