import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Validations } from 'src/app/shared/commons/Validations';
import { ClientService } from 'src/app/shared/services/client.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { StorageService as storage } from 'src/app/shared/services/storage.service';
import * as moment from 'moment';

@Component({
  selector: 'app-broker-dialog',
  templateUrl: './broker-dialog.component.html',
  styleUrls: ['./broker-dialog.component.scss']
})
export class BrokerDialogComponent implements OnInit {
  confirmResult: Subject<any>;
  broker;
  formOrame: UntypedFormGroup;
  formBroker: UntypedFormGroup;
  formBitcoin: UntypedFormGroup;
  brokerList = []
  constructor(
    public modalRef: BsModalRef,
    private client: ClientService,
    private dialogService: DialogService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();
    this.getBrokers();
    this.initForms();
  }

  getBrokers() {
    this.client.getListBrokers(storage.getClientJwt()).then(
      res => {
        this.brokerList = res.brokers; 
        console.log("res: ", res.brokers);
      }, 
      err => {}
    )
  }

  initForms(){
    this.formOrame = this.formBuilder.group({
      cpf: ['', Validators.compose([
          Validators.required,
          Validations.CPF
        ])]
    })
    this.formBroker = this.formBuilder.group({
      broker: ['1']
    })
    this.formBitcoin = this.formBuilder.group({
      identifier: ['', Validators.required],
      secret: ['', Validators.required],

    })
  }

  // convenience getter for easy access to form fields
  get fo() { return this.formOrame.controls }
  // convenience getter for easy access to form fields
  get fb() { return this.formBitcoin.controls }

  saveBrokerOrame(){
    let broker = this.getBrokerSelected(this.formBroker.get('broker').value)
    let obj = {
      "data_connection": {
        "whatever": "anything"
      }
    }
       
    this.client.saveBroker(storage.getClientUuid(), obj, this.formBroker.get('broker').value, storage.getClientJwt()).then(
      res => {
        if(res.status == 400){
          this.dialogService.showDialogError('Houve um erro ao tentar salvar configuração!')
        } else {
          this.dialogService.showSnackBar('Broker configurado com sucesso!', 'success')
          this.modalRef.hide();
        }
      },
      err => {
        this.dialogService.showDialogError('Houve um erro ao tentar salvar configuração!')
      }
    )    
  }

  getBrokerSelected(broker){
    return this.brokerList.filter(item => item.uuid == broker);
  }
}
