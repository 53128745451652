export const enum RouterEnum {
  MENU = '',
  HOME = 'home',
  LOGIN = 'login',
  SIGNUP = 'signup',
  PORTAL = 'portal',
  REGISTER = 'register',
  PARTNERS = 'partners',
  CLIENT = 'client',
  CONTACT = 'contact-information',
  OPEN_ACCOUNT = 'open-account',
  PERSONAL = 'personal',
  JURIDICAL = 'juridical',
  ADDRESS = 'address',
  FINANCIAL_DATA = 'financial-data',
  UPLOAD_DOCUMENTS = 'upload-documents',
  SELFIE = 'selfie',
  SUMMARY = 'summary',
  CONFIRM_INFORMATION = 'confirm-information',
  ACCESS_PASSWORD = 'access-password',
  CHANGE_PASSWORD = 'recover',
  CHANGE_PORTAL_PASSWORD = 'change-password',
  SUITABILITY = 'suitability',
  FINISH_SUITABILITY = 'finish-suitability',
  FINISH_REGISTER = 'finish-register',
  PROFILE = 'profile',
  CHOICE_RISK_PROFILE = 'choice-risk-profile',
  DASHBOARD = 'dashboard',
  MY_DATA = 'my-data',
  MY_INVESTMENTS = 'my-investments',
  MY_ACCOUNTS = 'my-accounts',
  CURRENT_PORTFOLIO = 'current-portfolio',
  IDEAL_PORTFOLIO = 'ideal-portfolio',
  MY_ORDERS = "orders",
  MY_EXPECTATIONS = 'my-expectations',
  MY_THEMES = 'my-themes',
  PERFORMANCE = 'performance',
  CURRENT_POSITION = 'current-position',
  FINANCIAL_PLANNING = 'financial-planning',
  EXTRACT = 'extract',
  INSIGHT = 'insight',
  INCOME = 'income',
  EXPENSE = 'expense',
  PAYMENTS = 'payments',
  TRANSFERS = 'transfers',
  MANAGE_ACCOUNTS = 'manage-accounts',
  HOW_TO_PLAN = 'how-to-plan',
  GOALS = 'goals',
  BUDGET = 'budget',
  PATRIMONY = 'patrimony',
  JUSTICE = 'justice',
  NEW_CAR = 'car',
  STUDIES = 'studies',
  TRAVELS = 'travels',
  NEW_HOUSE = 'house',
  EMERGENCY_RESERVE = 'emergency',
  EVENTS = 'events',
  HEALTH = 'health',
  GROWTH = 'growth',
  RETIREMENT = 'retirement',
  WEALTH = 'wealth',
  ACQUISITION = 'acquisition',
  CUSTOM = 'custom',
  ACCOUNT_DATA = 'account-data',
  PERSONAL_DATA = 'personal-data',
  ACCOUNT_PLAN = 'account-plan',
  INVESTOR_PROFILE = 'investor-profile',
  AUTOMATIC_PILOT = 'automatic-pilot',
  CONTACT_US = 'contact-us',
  NOTIFICATIONS = 'notifications',
  MY_CARDS = 'my-cards',
  NEW = 'new',
  EDIT = 'edit',
  ACTIVE_CARD = 'active-card',
  SCHEDULES = 'schedules',
  MADES = 'mades',
  FAVORITES = 'favorites',
  INVOICE_CARD = 'invoice-card',
  PIX = 'pix',
  MY_KEYS = 'my-keys',
  MY_AREA = 'my-area',
  MAKE_PAYMENT = 'make-payment',
  SCHEDULED_PAYMENTS = 'scheduled-payments',
  RECEIPTS = 'receipts',
  EMAIL_VERIFY = 'verify-email',
  COPY_PASTE = 'copy-paste',
  RECEIVE = 'receive',
  PAY_QRCODE = 'qrcode',
  PIX_AREA = 'pix-area',
  LIMITS = 'limits',
  PORTABILITY = 'portability',
  DATA = 'dados',
  VALIDATION = 'validation',
  CONFIRMATION = 'confirmation',
  EFFECTUATION = 'effectuation',
  ACCOUNT_AREA = 'account-area',
  DEPOSIT_BY_BANK_SLIP = 'deposit-by-bank-slip',
  LIFE_SITUATION = 'life-situation',
  EXCHANGE = 'exchange',
  OPTIONS = 'options',
  PAPER_MONEY = 'paper-money',
  SHIPPING = 'shipping',
  TO_MANAGER = 'to-manager',
  MY_LOANS = 'my-loans',
  P2P = 'p2p',
  PERSONAL_CREDIT = 'personal-credit',
  PERSONAL_CREDIT_TWO = 'personal-credit-two',
  REAL_STATE_CREDIT = 'real-state-credit',
  ALL_OFFERS = 'all-ofers',
  MY_CONTRACTS = 'my-contracts',
  IN_PROGRESS = 'in-progress',
  NEW_CONTRACT = 'new-contract',
  OFFER_AVAILABLE = 'offer-available',
  CONSIGNED_CREDIT = 'consigned-credit',
  MOBILE_CREDIT = 'mobile-credit',
  SOLAR_PANEL_CREDIT = 'solar-panel-credit',
  CAR_CREDIT = 'car-credit',
  SECURED_LOAN = 'secured-loan',
  SIMULATE = 'simulate',
  MY_WALLETS = 'my-wallets',
  MY_RECIPES = 'my-recipes',
  FUTURE_PLANS = 'future-plans',
  BUSINESS_DATA = 'business-data',
  OCCUPATION_AREA = 'occupation-area',
  DISCLOSURE_DATA = 'disclosure-data',
  PLANS = 'plans',
  FINISH = 'finish',
  PLANS_AND_PAYMENTS = 'plans-and-payments',
  CHECKOUT = 'checkout',
  DETAILS = 'details',
  FORGOT_PASSWORD = 'forgot-password',
  COMPANY = 'company',
  BROKERS = 'brokers',
  MONEY_IN_HAND = 'money-in-hand',
  ALLOCATIONS = 'allocations',
  SCENARIOS = 'scenarios',
  ACCOUNT_OVERVIEW = 'account-overview',
  MY_PAGE = 'my-page',
  VIDEOS = 'videos',
  OPINIONS = 'opinions',
  ASSETS = 'assets',
  LIABILITIES = 'liabilities',
  REPORT = 'report',
  MANAGE_ASSOCIATIONS = 'MANAGE_ASSOCIATIONS'
}

export class AppNavRouteLink {

  static MODULE = {
    PORTAL: RouterEnum.PORTAL,
    REGISTER: RouterEnum.REGISTER,
    PARTNERS: RouterEnum.PARTNERS
  }

  static PAGE = {
    HOME: RouterEnum.HOME,
    LOGIN: RouterEnum.LOGIN,
    FORGOT_PASSWORD: RouterEnum.FORGOT_PASSWORD,
    SIGNUP: RouterEnum.SIGNUP,
    CONTACT: RouterEnum.CONTACT,
    OPEN_ACCOUNT: RouterEnum.OPEN_ACCOUNT,
    PERSONAL: RouterEnum.PERSONAL,
    SUMMARY: RouterEnum.SUMMARY,
    CONFIRM: RouterEnum.CONFIRM_INFORMATION,
    ACCESS_PASSWORD: RouterEnum.ACCESS_PASSWORD,
    CHANGE_PASSWORD: `${RouterEnum.CLIENT}/${RouterEnum.CHANGE_PASSWORD}`,
    SUITABILITY: RouterEnum.SUITABILITY,
    FINISH_SUITABILITY: RouterEnum.FINISH_SUITABILITY,
    FINISH_REGISTER: RouterEnum.FINISH_REGISTER,
    PROFILE: RouterEnum.PROFILE,
    CHOICE_RISK_PROFILE: RouterEnum.CHOICE_RISK_PROFILE,
    DASHBOARD: RouterEnum.DASHBOARD,
    MY_DATA: RouterEnum.MY_DATA,
    MY_INVESTMENTS: RouterEnum.MY_INVESTMENTS,
    MY_ACCOUNTS: RouterEnum.MY_ACCOUNTS,
    FINANCIAL_PLANNING: RouterEnum.FINANCIAL_PLANNING,
    NEW_GOAL: `${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.GOALS}`,
    NEW_PATRIMONY: `${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.PATRIMONY}`,
    CHANGE_PORTAL_PASSWORD: RouterEnum.CHANGE_PORTAL_PASSWORD,
    NOTIFICATIONS: RouterEnum.NOTIFICATIONS,
    MY_CARDS: RouterEnum.MY_CARDS,
    INVOICE_CARD: RouterEnum.INVOICE_CARD,
    EMAIL_VERIFY: RouterEnum.EMAIL_VERIFY,
    DEPOSIT_BY_BANK_SLIP: RouterEnum.DEPOSIT_BY_BANK_SLIP,
    EXCHANGE: RouterEnum.EXCHANGE,
    MY_LOANS: RouterEnum.MY_LOANS,
    OPTIONS: RouterEnum.OPTIONS,
    PLANS_AND_PAYMENTS: RouterEnum.PLANS_AND_PAYMENTS,
    CHECKOUT: RouterEnum.CHECKOUT,
    DETAILS: RouterEnum.DETAILS,
    COMPANY: RouterEnum.COMPANY,
  }

  static NAV = {
    OPEN_ACCOUNT: `${RouterEnum.REGISTER}/${RouterEnum.OPEN_ACCOUNT}`,
    CONTACT: `${RouterEnum.REGISTER}/${RouterEnum.CONTACT}`,
    PERSONAL: `${RouterEnum.REGISTER}/${RouterEnum.PERSONAL}`,
    JURIDICAL: `${RouterEnum.REGISTER}/${RouterEnum.JURIDICAL}`,
    ADDRESS: `${RouterEnum.REGISTER}/${RouterEnum.ADDRESS}`,
    FINANCIAL_DATA: `${RouterEnum.REGISTER}/${RouterEnum.FINANCIAL_DATA}`,
    UPLOAD_DOCUMENTS: `${RouterEnum.REGISTER}/${RouterEnum.UPLOAD_DOCUMENTS}`,
    SELFIE: `${RouterEnum.REGISTER}/${RouterEnum.SELFIE}`,
    PERSONAL_TERMS: `${RouterEnum.REGISTER}/${RouterEnum.PERSONAL}/true`,
    SUMMARY: `${RouterEnum.REGISTER}/${RouterEnum.SUMMARY}`,
    CONFIRM_INFORMATION: `${RouterEnum.REGISTER}/${RouterEnum.CONFIRM_INFORMATION}`,
    SUITABILITY: `${RouterEnum.REGISTER}/${RouterEnum.SUITABILITY}`,
    FINISH_SUITABILITY: `${RouterEnum.REGISTER}/${RouterEnum.FINISH_SUITABILITY}`,
    DASHBOARD: `${RouterEnum.PORTAL}/${RouterEnum.DASHBOARD}`,
    MY_DATA: `${RouterEnum.PORTAL}/${RouterEnum.MY_DATA}`,
    PORTAL_PASSWORD: `${RouterEnum.PORTAL}/${RouterEnum.CHANGE_PORTAL_PASSWORD}`,
    MY_INVESTMENTS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_INVESTMENTS}`,
    NEW_GOAL: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.GOALS}`,
    NOTIFICATIONS: `${RouterEnum.PORTAL}/${RouterEnum.NOTIFICATIONS}`,
    MY_CARDS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_CARDS}`,
    NEW_CARD: `/${RouterEnum.PORTAL}/${RouterEnum.MY_CARDS}/${RouterEnum.NEW}`,
    ACTIVE_CARD: `/${RouterEnum.PORTAL}/${RouterEnum.MY_CARDS}/${RouterEnum.ACTIVE_CARD}`,
    INVOICE_CARD: `${RouterEnum.PORTAL}/${RouterEnum.MY_CARDS}/${RouterEnum.INVOICE_CARD}`,
    MARRIAGE: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.GOALS}/${RouterEnum.EVENTS}`,
    DEPOSIT_BY_BANK_SLIP: `/${RouterEnum.PORTAL}/${RouterEnum.DEPOSIT_BY_BANK_SLIP}`,
    EXCHANGE: `/${RouterEnum.PORTAL}/${RouterEnum.EXCHANGE}`,
    MY_LOANS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_LOANS}`,
    CHECKOUT: `/${RouterEnum.PORTAL}/${RouterEnum.PLANS_AND_PAYMENTS}/${RouterEnum.CHECKOUT}`,
    DETAILS: `/${RouterEnum.PORTAL}/${RouterEnum.PLANS_AND_PAYMENTS}/${RouterEnum.DETAILS}`
  }

  static NAV_PARTNERS = {
    CONTACT: `/${RouterEnum.PARTNERS}/${RouterEnum.CONTACT}`,
    MY_DATA: `/${RouterEnum.PARTNERS}/${RouterEnum.MY_DATA}`,
    PERSONAL: `/${RouterEnum.PARTNERS}/${RouterEnum.MY_DATA}/${RouterEnum.PERSONAL}`,
    BUSINESS_DATA: `/${RouterEnum.PARTNERS}/${RouterEnum.MY_DATA}/${RouterEnum.BUSINESS_DATA}`,
    OCCUPATION_AREA: `/${RouterEnum.PARTNERS}/${RouterEnum.MY_DATA}/${RouterEnum.OCCUPATION_AREA}`,
    DISCLOSURE_DATA: `/${RouterEnum.PARTNERS}/${RouterEnum.MY_DATA}/${RouterEnum.DISCLOSURE_DATA}`,
    MY_PAGE: `/${RouterEnum.PARTNERS}/${RouterEnum.MY_PAGE}`,
    COMPANY: `/${RouterEnum.PARTNERS}/${RouterEnum.MY_PAGE}/${RouterEnum.COMPANY}`,
    VIDEOS: `/${RouterEnum.PARTNERS}/${RouterEnum.MY_PAGE}/${RouterEnum.VIDEOS}`,
    CONTACT_US: `/${RouterEnum.PARTNERS}/${RouterEnum.MY_PAGE}/${RouterEnum.CONTACT_US}`,
    OPINIONS: `/${RouterEnum.PARTNERS}/${RouterEnum.OPINIONS}`,
    PLANS: `/${RouterEnum.PARTNERS}/${RouterEnum.PLANS}`,
    FINISH: `/${RouterEnum.PARTNERS}/${RouterEnum.FINISH}`,
  }

  static TABS_MY_DATA = {
    ACCOUNT_DATA: `/${RouterEnum.PORTAL}/${RouterEnum.MY_DATA}/${RouterEnum.ACCOUNT_DATA}`,
    PERSONAL_DATA: `/${RouterEnum.PORTAL}/${RouterEnum.MY_DATA}/${RouterEnum.PERSONAL_DATA}`,
    ACCOUNT_PLAN: `/${RouterEnum.PORTAL}/${RouterEnum.MY_DATA}/${RouterEnum.ACCOUNT_PLAN}`,
    INVESTOR_PROFILE: `/${RouterEnum.PORTAL}/${RouterEnum.MY_DATA}/${RouterEnum.INVESTOR_PROFILE}`,
    AUTOMATIC_PILOT: `/${RouterEnum.PORTAL}/${RouterEnum.MY_DATA}/${RouterEnum.AUTOMATIC_PILOT}`,
    CONTACT_US: `/${RouterEnum.PORTAL}/${RouterEnum.MY_DATA}/${RouterEnum.CONTACT_US}`,
    MANAGE_ACCOUNTS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_DATA}/${RouterEnum.MANAGE_ACCOUNTS}`
  }

  static TABS_PLANS_AND_PAYMENTS = {
    DETAILS: `/${RouterEnum.PORTAL}/${RouterEnum.PLANS_AND_PAYMENTS}/${RouterEnum.DETAILS}`,
    CHECKOUT: `/${RouterEnum.PORTAL}/${RouterEnum.PLANS_AND_PAYMENTS}/${RouterEnum.CHECKOUT}`,
  }

  static TABS_INVESTMENT = {
    SUMMARY: `/${RouterEnum.PORTAL}/${RouterEnum.MY_INVESTMENTS}/${RouterEnum.SUMMARY}`,
    CURRENT_PORTFOLIO: `/${RouterEnum.PORTAL}/${RouterEnum.MY_INVESTMENTS}/${RouterEnum.CURRENT_PORTFOLIO}`,
    IDEAL_PORTFOLIO: `/${RouterEnum.PORTAL}/${RouterEnum.MY_INVESTMENTS}/${RouterEnum.IDEAL_PORTFOLIO}`,
    MY_ORDERS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_INVESTMENTS}/${RouterEnum.MY_ORDERS}`,
    NEW_ORDER: `/${RouterEnum.PORTAL}/${RouterEnum.MY_INVESTMENTS}/${RouterEnum.MY_ORDERS}/${RouterEnum.NEW}`,
    EDIT_ORDER: `/${RouterEnum.PORTAL}/${RouterEnum.MY_INVESTMENTS}/${RouterEnum.MY_ORDERS}/${RouterEnum.EDIT}`,
    MY_EXPECTATIONS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_INVESTMENTS}/${RouterEnum.MY_EXPECTATIONS}`,
    SCENARIOS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_INVESTMENTS}/${RouterEnum.MY_EXPECTATIONS}/${RouterEnum.SCENARIOS}`,
    ALLOCATIONS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_INVESTMENTS}/${RouterEnum.MY_EXPECTATIONS}/${RouterEnum.ALLOCATIONS}`,
    MY_THEMES: `/${RouterEnum.PORTAL}/${RouterEnum.MY_INVESTMENTS}/${RouterEnum.MY_THEMES}`,
    PERFORMANCE: `/${RouterEnum.PORTAL}/${RouterEnum.MY_INVESTMENTS}/${RouterEnum.PERFORMANCE}`,
    MY_WALLETS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_INVESTMENTS}/${RouterEnum.MY_WALLETS}`,
    EXTRACT: `/${RouterEnum.PORTAL}/${RouterEnum.MY_INVESTMENTS}/${RouterEnum.EXTRACT}`,
  }

  static TABS_ACCOUNT = {
    EXTRACT: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.EXTRACT}`,
    AREA: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.ACCOUNT_AREA}`,
    INSIGHT: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.INSIGHT}`,
    PAYMENTS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.PAYMENTS}`,
    TRANSFERS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.TRANSFERS}`,
    MANAGE_ACCOUNTS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.MANAGE_ACCOUNTS}`,
    MANAGE_ACCOUNTS_BUDGET: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.EXTRACT}`,
    PIX: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.PIX}`,
  }

  static TABS_FINANCIAL_PLANNING = {
    HOW_TO_PLAN: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.HOW_TO_PLAN}`,
    LIFE_SITUATION: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.LIFE_SITUATION}`,
    GOALS: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.GOALS}`,
    NEW_GOAL: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.GOALS}`,
    BUDGET: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.BUDGET}`,
    INSIGHT: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.INSIGHT}`,
    INCOME: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.INSIGHT}/${RouterEnum.INCOME}`,
    EXPENSE: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.INSIGHT}/${RouterEnum.EXPENSE}`,
    PATRIMONY: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.PATRIMONY}`,
    NEW_PATRIMONY: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.PATRIMONY}/${RouterEnum.NEW}`,
    EDIT_PATRIMONY: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.PATRIMONY}/${RouterEnum.EDIT}`,
    ASSETS_REPORT: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.PATRIMONY}/${RouterEnum.REPORT}/${RouterEnum.ASSETS}`,
    LIABILITIES_REPORT: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.PATRIMONY}/${RouterEnum.REPORT}/${RouterEnum.LIABILITIES}`,
    MY_RECIPES: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.MY_RECIPES}`,
    FUTURE_PLANS: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.FUTURE_PLANS}`,
    MANAGE_ACCOUNTS: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.MANAGE_ACCOUNTS}`,
    ACCOUNT_OVERVIEW: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.MANAGE_ACCOUNTS}/${RouterEnum.ACCOUNT_OVERVIEW}`,
    BROKERS: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.MANAGE_ACCOUNTS}/${RouterEnum.BROKERS}`,
    MONEY_IN_HAND: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.MANAGE_ACCOUNTS}/${RouterEnum.MONEY_IN_HAND}`,
    MANAGE_ASSOCIATIONS: `/${RouterEnum.PORTAL}/${RouterEnum.FINANCIAL_PLANNING}/${RouterEnum.MANAGE_ACCOUNTS}/${RouterEnum.MANAGE_ASSOCIATIONS}`
  }

  static TABS_TRANSFERS = {
    NEW: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.TRANSFERS}/${RouterEnum.NEW}`,
    SCHEDULES: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.TRANSFERS}/${RouterEnum.SCHEDULES}`,
    MADES: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.TRANSFERS}/${RouterEnum.MADES}`,
    FAVORITES: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.FAVORITES}`,
  }

  static TABS_SUMMARY = {
    CURRENT_POSITION: `/${RouterEnum.PORTAL}/${RouterEnum.MY_INVESTMENTS}/${RouterEnum.SUMMARY}/${RouterEnum.CURRENT_POSITION}`,
    EXTRACT: `/${RouterEnum.PORTAL}/${RouterEnum.MY_INVESTMENTS}/${RouterEnum.SUMMARY}/${RouterEnum.EXTRACT}`,
    MY_ORDERS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_INVESTMENTS}/${RouterEnum.SUMMARY}/${RouterEnum.MY_ORDERS}`,
  }

  static TABS_PIX = {
    AREA: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.PIX}/${RouterEnum.PIX_AREA}`,
    TRANSFERS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.PIX}/${RouterEnum.TRANSFERS}`,
    COPY_PASTE: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.PIX}/${RouterEnum.COPY_PASTE}`,
    RECEIVE: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.PIX}/${RouterEnum.RECEIVE}`,
    PAY_QRCODE: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.PIX}/${RouterEnum.PAY_QRCODE}`,
    EXTRACT: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.PIX}/${RouterEnum.EXTRACT}`,
    MY_KEYS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.PIX}/${RouterEnum.MY_KEYS}`,
    LIMITS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.PIX}/${RouterEnum.LIMITS}`,
    NOTIFICATIONS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.PIX}/${RouterEnum.NOTIFICATIONS}`,
    PORTABILITY: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.PIX}/${RouterEnum.PORTABILITY}`,
  }

  static TABS_PAYMENT = {
    MAKE_PAYMENT: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.PAYMENTS}/${RouterEnum.MAKE_PAYMENT}`,
    SCHEDULED_PAYMENTS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.PAYMENTS}/${RouterEnum.SCHEDULED_PAYMENTS}`,
    RECEIPTS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_ACCOUNTS}/${RouterEnum.PAYMENTS}/${RouterEnum.RECEIPTS}`
  }

  static TABS_EXCHANGE = {
    PAPER_MONEY: `/${RouterEnum.PORTAL}/${RouterEnum.EXCHANGE}/${RouterEnum.PAPER_MONEY}`,
    SHIPPING: `/${RouterEnum.PORTAL}/${RouterEnum.EXCHANGE}/${RouterEnum.SHIPPING}`
  }

  static TABS_BANK_SLIP = {
    NEW: `/${RouterEnum.PORTAL}/${RouterEnum.DEPOSIT_BY_BANK_SLIP}/${RouterEnum.NEW}`,
    TO_MANAGER: `/${RouterEnum.PORTAL}/${RouterEnum.DEPOSIT_BY_BANK_SLIP}/${RouterEnum.TO_MANAGER}`
  }

  static TABS_LOANS = {
    P2P: `/${RouterEnum.PORTAL}/${RouterEnum.MY_LOANS}/${RouterEnum.P2P}`,
    ALL_OFFERS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_LOANS}/${RouterEnum.ALL_OFFERS}`,
    MY_CONTRACTS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_LOANS}/${RouterEnum.MY_CONTRACTS}`,
    IN_PROGRESS: `/${RouterEnum.PORTAL}/${RouterEnum.MY_LOANS}/${RouterEnum.IN_PROGRESS}`,
    NEW_CONTRACT: `/${RouterEnum.PORTAL}/${RouterEnum.MY_LOANS}/${RouterEnum.NEW_CONTRACT}`,
    OFFER_AVAILABLE: `/${RouterEnum.PORTAL}/${RouterEnum.MY_LOANS}/${RouterEnum.OFFER_AVAILABLE}`,
    SIMULATE: `/${RouterEnum.PORTAL}/${RouterEnum.MY_LOANS}/${RouterEnum.SIMULATE}`,
    PERSONAL_CREDIT: `/${RouterEnum.PORTAL}/${RouterEnum.MY_LOANS}/${RouterEnum.PERSONAL_CREDIT}`,
    PERSONAL_CREDIT_TWO: `/${RouterEnum.PORTAL}/${RouterEnum.MY_LOANS}/${RouterEnum.PERSONAL_CREDIT_TWO}`,
    REAL_STATE_CREDIT: `/${RouterEnum.PORTAL}/${RouterEnum.MY_LOANS}/${RouterEnum.REAL_STATE_CREDIT}`,
    CONSIGNED_CREDIT: `/${RouterEnum.PORTAL}/${RouterEnum.MY_LOANS}/${RouterEnum.CONSIGNED_CREDIT}`,
    MOBILE_CREDIT: `/${RouterEnum.PORTAL}/${RouterEnum.MY_LOANS}/${RouterEnum.MOBILE_CREDIT}`,
    CAR_CREDIT: `/${RouterEnum.PORTAL}/${RouterEnum.MY_LOANS}/${RouterEnum.CAR_CREDIT}`,
    SOLAR_PANEL_CREDIT: `/${RouterEnum.PORTAL}/${RouterEnum.MY_LOANS}/${RouterEnum.SOLAR_PANEL_CREDIT}`,
    SECURED_LOAN: `/${RouterEnum.PORTAL}/${RouterEnum.MY_LOANS}/${RouterEnum.SECURED_LOAN}`
  }
}
