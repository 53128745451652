import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AppNavRouteLink } from 'src/app/shared/commons/AppNavRouteLink';
import { Validations } from 'src/app/shared/commons/Validations';
import { RecoveryPasswordModel } from 'src/app/shared/interfaces/recovery-password-model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ForgotPasswordService } from 'src/app/shared/services/forgot-password.service';
import { ConfirmDialogViewModel } from './../confirm-dialog/confirm-dialog.view-model';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  loginData = {} as RecoveryPasswordModel;
  formGroup: FormGroup;
  template = true

  confirmResult: Subject<boolean | string>;

  constructor(
    private dialogService: DialogService,
    public modalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder,
    private forgotPasswordService: ForgotPasswordService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      document_number: [this.loginData.document_number, Validators.compose([
        Validators.required,
        Validations.CpfOrCnpj
      ])]
    })
  }

  login() {
    if (this.template)
      this.router.navigate([AppNavRouteLink.PAGE.LOGIN])
    else
      this.modalRef.hide()
  }

  onClick() {
    if (this.formGroup.valid) {
      let inBody = this.formGroup.getRawValue()
      this.generateToken(inBody)
    } else {
      this.formGroup.markAllAsTouched()
    }
  }

  generateToken(inBody) {
    this.forgotPasswordService.generateToken(inBody).then(res => {
      if (res.status === 400) {
        this.dialogService.showDialogError('Cliente não encontrado')
      } else {
        let model = {} as ConfirmDialogViewModel
        model.subTitle = 'Sucesso!'
        model.msg = `Enviamos um link de redefinição da senha para o e-mail cadastrado. <p class="fw-bold mt-4">Caso não receba o e-mail em alguns minutos, 
        verifique se a mensagem foi enviada para a caixa de SPAM.</p>`
        model.txtOk = 'OK'
        model.tryAgain = true
        this.dialogService.showDialogSuccess(model).subscribe(res => {
          if (res) {
            this.login()
          }
        })
      }
    })
  }
  // convenience getter for easy access to form fields
  get f() { return this.formGroup.controls; }
}
