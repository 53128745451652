import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ClientService } from 'src/app/shared/services/client.service';
import { Utils } from 'src/app/shared/commons/Utils';
import { KeyPixDescriptionEnum, KeyPixTypeEnum } from 'src/app/shared/enums/key-pix-type.enum';
import * as moment from 'moment';
import { ConfirmDialogViewModel } from "../confirm-dialog/confirm-dialog.view-model";
import { DialogService } from "../../shared/services/dialog.service";
import { UtilService } from 'src/app/shared/services/util.service';
import { Router } from "@angular/router";
import { AppNavRouteLink } from "../../shared/commons/AppNavRouteLink";

@Component({
  selector: 'app-pix-dialog',
  templateUrl: './pix-dialog.component.html',
  styleUrls: ['./pix-dialog.component.scss']
})
export class PixDialogComponent implements OnInit {

  confirmResult: Subject<any>
  selectOption: any = {}
  model: any = {}
  account: any = {}
  pixKeys: any = {}
  nextRegisterTime: any;
  dialogViewModel = {} as ConfirmDialogViewModel;
  resultDialog: any;
  resClient: any;
  resPortability: any = {}

  constructor(
    public modalRef: BsModalRef,
    private clientService: ClientService,
    private dialogService: DialogService,
    private utilService: UtilService,
    public router: Router,
  ) { }

  ngOnInit() {
    this.confirmResult = new Subject()

    this.clientService.clientData.subscribe(d => {
      if (!d) { return }
      this.model = {
        document_number: Utils.maskCpfCnpj(d.document_number),
        email: d.email,
        uuid: d.uuid
      }
    })
    this.clientService.phoneData.subscribe(d => {
      if (!d) { return; }
      this.model.phone = Utils.maskMobile(`${d.code_area}${d.number}`);
      this.model.real_phone = `${d.code_country}${d.code_area}${d.number}`;
    })
    this.clientService.accountData.subscribe(d => {
      if (!d) { return; }
      this.account = d;
    });

    this.clientService.getClientAccountPIXs(this.model['uuid'], this.account['uuid']).then(d => {
      if (!d) { return; }
      this.pixKeys = d;
    });

    if (moment().hour() > 20) {
      this.nextRegisterTime = "amanhã após as 8:00 da manhã";
    } else if (moment().hour() < 8) {
      this.nextRegisterTime = "hoje após as 8:00 da manhã";
    }
  }

  onSelectKey(value, option) {
    this.selectOption = {
      key: this.getKeyValue(value, option),
      key_type: option
    }

    if (value == 'random-key')
      delete this.selectOption.key
  }

  getKeyValue(value, option) {
    switch (option) {
      case KeyPixTypeEnum.mobile:
        return Utils.unMask(value)
      case KeyPixTypeEnum.document:
        return Utils.unMask(value)
      default:
        return value;
    }
  }

  save() {
    if (!this.isDisable("evp")) {
      if (this.selectOption.key_type == "email" || this.selectOption.key_type == "mobile") {
        this.verify(this.selectOption);
      } else {
        this.create_pix();
      }
    }
  }

  create_pix() {
    this.clientService.createClientAccountPIXKey(this.model.uuid, this.account.uuid, this.selectOption).then(result => {
      if (result.error) {
        this.confirmResult.next({ success: false, msg: result.error.errors.msg })
      } else {
        const msg = this.selectOption.key_type == KeyPixTypeEnum.evp ? `Pronto! A sua chave aleatória foi cadastrada no Pix` :
          `Pronto! O seu ${KeyPixDescriptionEnum[this.selectOption.key_type]} foi cadastrado no Pix`
        this.confirmResult.next({ success: true, msg: msg })
      }

      this.modalRef.hide()
    }).catch(err => {
      if (err.error.errors.code == 4051) {
        this.dialogService.showDialog('Aviso', 'Essa chave Pix já está cadastrada por outro usuário na plataforma Pix!', 'modal-lg');
        this.model.claim_type = 'OWNERSHIP'
      } else if (err.error.errors.code == 4050) {
        this.model.claim_type = 'PORTABILITY'
        this.router.navigate(this.routerLink.TABS_PIX.PORTABILITY.split('/'));
        this.dialogService.showDialog('Aviso', 'Essa chave já está cadastrada em outra instituição, por favor faça a portabilidade dela.', 'modal-lg');
        this.modalRef.hide();
        window.scrollTo(0, 0);
      } else {
        this.dialogService.showDialogError(err.error.errors.msg)
        return
      }

      // TODO código temporário para testes reinvindicação
      if (this.model.claim_type == 'OWNERSHIP' && !this.utilService.check_allowed_pix_users(this.model.uuid)) {
        this.dialogService.showDialogError('Processo de Reivindicação de posse temporariamente indisponível.')
      }
    })
  }

  isDisable(type) {
    if (this.pixKeys.length > 4) {
      return true;
    } else if (this.pixKeys && this.pixKeys.length && type != "evp") {
      switch (type) {
        case 'document': {
          return this.pixKeys.find(x => Utils.unMask(x.key) == Utils.unMask(this.model.document_number));
        }
        case 'email': {
          return this.pixKeys.find(x => x.key == this.model.email);
        }
        case 'mobile': {
          return this.pixKeys.find(x => Utils.unMask(x.key) == Utils.unMask(this.model.real_phone) || Utils.unMask(x.key) == Utils.unMask(this.model.phone));
        }
        default: {
          return false
        }
      }
    }
  }

  get KeyPixTypeEnum() {
    return KeyPixTypeEnum
  }

  verify(option: any) {
    this.clientService.verifyPixKey(this.model['uuid'], this.account['uuid'], option.key, option.key_type).then(res => {
      if (res.status == 200) {
        if (option.key_type == "mobile") {
          this.dialogService.showTokenDialog().subscribe(result => {
            if (result == 'resend') {
              this.clientService.verifyPixKey(this.model['uuid'], this.account['uuid'], option.key, option.key_type);
            } else {
              this.checkVerify(option.key, result);
            }
          });
        } else {
          this.dialogService.showEmailDialog().subscribe(result => {
            if (result == 'resend') {
              this.clientService.verifyPixKey(this.model['uuid'], this.account['uuid'], option.key, option.key_type);
            } else {
              this.checkVerify(option.key, result);
            }
          });
        }
      } else {
        this.dialogService.showDialogError('Ocorreu um erro ao tentar confirmar posse de sua chave, por favor tente novamente.');
      }
    });
  }

  checkVerify(key: any, data: any) {
    data = { key: key, code: data };
    this.clientService.checkPixVerify(this.model['uuid'], this.account['uuid'], data).then(res => {
      this.dialogViewModel = {} as ConfirmDialogViewModel;
      if (res.status === 400) {
        this.dialogViewModel.subTitle = 'Código incorreto';
        this.dialogViewModel.msg = 'Algo deu errado com o código inserido.<br> Por favor, verifique e tente novamente.';
        this.dialogViewModel.txtOk = 'TENTAR OUTRA VEZ';
        this.dialogService.showDialogTryAgain(this.dialogViewModel).subscribe(result => {
        })
      } else if (res.status === 404) {
        this.dialogViewModel.msg = 'Seu código de confirmação está expirado. Você deve gerar um novo código de confirmação e tentar novamente.';
        this.dialogViewModel.tryAgain = true;
        this.dialogViewModel.txtOk = 'TENTAR OUTRA VEZ';
        this.dialogService.showDialogTryAgain(this.dialogViewModel).subscribe(result => {
        })
      } else if (res.status === 'cancel') {
        this.clientService.redirectLandingPage();
      } else if (res.status === 200) {
        this.modalRef.hide();
        this.create_pix();
      }
    })
  }

  get routerLink() { return AppNavRouteLink; }
}
