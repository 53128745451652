<div class="modal-header border-0">
  <span class="fs-3 text-center fw-bold w-100 m-auto"> Objetivos em Revisão</span>
  <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<p class="px-5">Os seguintes objetivos estão vencidos, ou sem valores especificados.<br>Remova-os ou edite-os
  para continuar.</p>
<div class="modal-body">
  <div class="row">
    <ul class="px-5">
      <li class="row my-3 py-4" *ngFor="let item of listGoals" style="border-top: 1px solid black;">
        <div class="row col-12">
          <p class="col-6 text-start fs-4 bold">Tipo: {{ item.name }}</p>
          <p class="col-6 text-start fs-4 bold" *ngIf="item.days < 0">Campo a ser revisado: <span
              class="card-title">Data</span></p>
          <p class="col-6 text-start fs-4 bold" *ngIf="item.days >= 0">Campo a ser revisado: <span
              class="card-title">Valor</span></p>
          <p class="col-6 text-start">Descrição: <br>{{ item.description }}</p>
          <p class="col-6 text-start" *ngIf="item.days < 0">{{ item.date | date: 'dd/MM/yyyy' }} <br>
            (Data atual: {{ today | date: 'dd/MM/yyyy' }})</p>
          <p class="col-6 text-start" *ngIf="item.days >= 0">{{ item.value | currency: 'BRL' }}</p>
        </div>
        <div class="row col-12 justify-content-end">
          <div class="col-auto striped m-2 text-end" (click)="editGoal(item)" style="cursor: pointer">
            <icon-svg svgName="pencil" class="pe-2"></icon-svg> Editar
          </div>
          <div class="col-auto striped m-2 text-end" (click)="removeGoal(item)" style="cursor: pointer">
            <icon-svg svgName="trash-fill" class="pe-2"></icon-svg> Remover
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
<!--<div class="modal-footer text-center border-0 m-auto">
        <button type="button" class="btn btn-danger" (click)="deleteAll()" [disabled]="!goalActive">APAGAR TODOS</button>
    </div>-->