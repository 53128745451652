<div class="bg-modal">
  <div class="modal-header border-0 d-flex justify-content-center">
    <div class="col-10 mx-auto py-1 text-center position-absolute">
      <p class="fs-4 fw-bold">Fale conosco sempre que precisar!</p>
    </div>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
  </div>
  <div class="modal-body">
    <div class="row ps-lg-4">
      <div class="col-xl-6 col-lg-5 col-md-9 col-12 mb-4">
        <p class="fs-4 fw-bold mb-4">Canal de Atendimento</p>
        <p class="mb-0">Envie sugestões, elogios e reclamações.</p>
        <a  href="mailto:atendimento@ladybank.com.br">atendimento@ladybank.com.br</a>
        <p class="mb-0"><a href="tel:+551147371888">+55 (11) 4737-1888</a></p>
        <p class="fs-4 fw-bold my-4">Ouvidoria</p>
        <p class="mb-0">Faça uma contestação de uma resposta fornecida pelo Canal de Atendimento.</p>
        <a  href="mailto:ouvidoria@ladybank.com.br">ouvidoria@ladybank.com.br</a>
        <p class="fs-4 fw-bold my-4">Canal de Atendimento do Banco Central</p>
        <p class="mb-0">Se você fez uma solicitação no Canal de Atendimento da LadyBank e na Ouvidoria, não ficou
          satisfeito com a resposta.</p>
        <a  href="https://www.bcb.gov.br/acessoinformacao/registrar_reclamacao" target="_blank" rel="noopener noreferrer">Registrar
          Reclamação</a>
      </div>
      <div class="col-12 mb-5">
        <button type="button" class="btn btn-primary mx-0" (click)="modalRef.hide()">Fechar</button>
      </div>
    </div>
  </div>
</div>