<div class="row text-center">
  <span class="fs-2 fw-bolder text-gold pt-3">{{title}}</span>
  <div class="col col-11 mx-auto text-center mt-4">
    <img src="assets/images/png/success.png" class="w-50" alt="">
    <p class="fs-4 fw-bold my-3">{{subTitle}}</p>
    <div *ngIf="msg" class="text-black" [innerHTML]="msg"></div>
    <div *ngIf="pixLimits">
      <button type="button" class="btn btn-link" (click)="openLimits()">MEUS LIMITES PIX</button>
    </div>
  </div>
</div>
<div class="col my-3 text-center mt-3">
  <button *ngIf="tryAgain" type="button" class="btn btn-outline-gold" (click)="onClose(true)">Reenviar</button>
  <button type="button" class="btn btn-gold" (click)="onClose(true)">{{txtOk}}</button>
</div>
<div class="d-flex justify-content-center align-items-center pb-3">
  <a href="https://blog.ladybank.com.br/" target="_blank" rel="noopener noreferrer">
    <icon-svg svgName="icon-blog"></icon-svg>
  </a>
  <a href="https://t.me/LadyBank_Bot" target="_blank" rel="noopener noreferrer">
    <icon-svg svgName="social_media/telegram"></icon-svg>
  </a>
</div>