<div class="modal-header border-0 pb-0">
  <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="justify-content-md-center position-relative">
  <div class="col col-12">
    <span class="fs-3 h1">{{title}}</span>
    <p class="mb-4">{{subTitle}}</p>
  </div>
</div>
<form [formGroup]="formGroup" class="col-12" autocomplete="off" novalidate>
  <label>Escolha a categoria:</label>
  <div class="form-group dropdown">
    <div id="field_1" class="custom-drop-down auto-complete d-flex" type="button" data-toggle="dropdown">
      <input type="text" (click)="search = ''" placeholder="Selecione uma categoria" [(ngModel)]="search"
        class="form-control" formControlName="field_1">
      <span class="chevron float-right"></span>
    </div>
    <ul class="dropdown-menu pre-scrollable">
      <nav *ngFor="let category of results">
        <li class="dropdown-header">{{category.group}}</li>
        <li *ngFor="let item of category.options | filter: search; let i = index"
          class="d-flex align-items-center striped p-2" (click)="onSelectCategory(item)">
          <icon-svg *ngIf="item.icon" [svgName]="item.icon"></icon-svg>
          <span class="poppins fw-bold ms-1">{{item.name}}</span>
        </li>
      </nav>
    </ul>
  </div>
  <!-- TODO comentar código temporariamente -->
  <!-- <div class="form-group">
      <input type="checkbox" id="field_2" [(ngModel)]="defaultCategory" formControlName="field_2">
      <label class="ms-2" for="field_2">Sempre usar essa categoria</label>
      <p class="fs-6">Sempre que tiver uma transação com esse nome, essa categoria já vai ser escolhida</p>
    </div> -->
</form>
<div class="row justify-content-md-center mb-3">
  <button type="button" class="btn btn-outline-primary" (click)="close()">CANCELAR</button>
  <button type="button" class="btn btn-primary" (click)="onConfirm()" [disabled]="formGroup.invalid">APLICAR</button>
</div>