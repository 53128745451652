<div class="modal-header border-0">
  <span class="fs-3 text-center fw-bold w-100 m-auto"> Escolha a classe do ativo </span>
  <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<p class="text-center">Selecione uma classe de ativo para adicionar um produto na carteira.</p>
<div class="modal-body">
  <div class="row">
    <ul *ngFor="let item of listAsset" class="col-4">
      <li class="border card pointer" [class.disabled]="!item.enabled">
        <div class="card-body left rounded-left p-4" [style.border-left-color]="AssetColor[item.value]"
          (click)="confirmAndClose(item)">
          <strong class="poppins">{{item.name}}</strong>
        </div>
      </li>
    </ul>
  </div>
</div>