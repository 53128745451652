import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-new-alert-dialog-error',
  templateUrl: './new-alert-dialog-error.component.html',
  styleUrls: ['./new-alert-dialog-error.component.scss']
})
export class NewAlertDialogErrorComponent implements OnInit {

  @Input() msg: string = null;
  confirmResult: Subject<boolean | string>;
  
  constructor(
    public modalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();
  }

  onClose() {
    this.confirmResult.next(false);
    this.modalRef.hide();
  }
}
