<div id="comprovante" class="m-0 m-md-2 m-lg-3">
  <div class="d-flex justify-content-center">
    <strong class="poppins fs-4">Termos da transação</strong>
  </div>
  <div class="col-12 fs-4">
    Pagamentos e recebimentos do exterior, de forma segura e fácil. Aceite os termos para habilitar as transações de
    moedas estrangeiras.
  </div>
  <div class="col-12 col-md-10 col-lg-8 mx-auto d-flex justify-content-around my-4">
    <a class="d-flex align-items-center" href="https://elasbank-public.s3-sa-east-1.amazonaws.com/documents/ladybank_termos_e_condicoes_cambio.pdf" target="_blank" rel="noopener noreferrer">
      <icon-svg svgName="clickable/pdf"></icon-svg>
      <strong class="poppins ms-2">Termos</strong>
    </a>
    <div class="form-group d-flex align-items-center my-4">
      <input type="checkbox" id="check-term" [checked]="accept" [value]="accept" (change)="accept = !accept">
      <label for="check-term" class="poppins font-weight_bold ms-2 my-0">Eu li e aceito os termos</label>
    </div>
  </div>
  <div class="row mx-auto col col-12 justify-content-center my-4">
    <button type="button" class="col col-12 col-md-5 btn btn-outline-dark" (click)="onClose()">Cancelar</button>
    <button type="button" class="col col-12 col-md-5 my-3 my-md-0 btn btn-primary" [disabled]="!accept"
      (click)="onClose()">
      Aceitar</button>
  </div>
</div>