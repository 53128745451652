import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ConfirmDialogViewModel } from 'src/app/components/confirm-dialog/confirm-dialog.view-model';
import { Validations } from 'src/app/shared/commons/Validations';
import { ApisEnum } from 'src/app/shared/enums/apis.enum';
import { ClientModel } from 'src/app/shared/interfaces/client-model';
import { PhoneModel } from 'src/app/shared/interfaces/phone-model';
import { ClientService } from 'src/app/shared/services/client.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { PhoneService } from 'src/app/shared/services/phone.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  confirmResult: Subject<boolean | string>
  formGroup: UntypedFormGroup
  confirmPassword = ''
  inputOptions = [
    { type: 'password', icon: 'bi-eye' },
    { type: 'password', icon: 'bi-eye' },
    { type: 'password', icon: 'bi-eye' },
  ]
  client = {} as ClientModel
  phone = {} as PhoneModel
  phoneVerifyRetry = 0

  constructor(
    private clientService: ClientService,
    private commonService: CommonService,
    private dialogService: DialogService,
    private formBuilder: UntypedFormBuilder,
    public modalRef: BsModalRef,
    private phoneService: PhoneService,
  ) {
    this.clientService.clientData.subscribe(d => {
      if (!d) { return }
      this.client = d
    })
    this.clientService.phoneData.subscribe(d => {
      if (!d) { return }
      this.phone = d
    })
  }

  ngOnInit(): void {
    this.confirmResult = new Subject()

    this.formGroup = this.formBuilder.group({
      password: ['', [Validators.required, Validations.Password]],
      new_password: ['', [Validators.required, Validations.Password]],
      confirm_password: ['', Validators.required]
    }, {
      validator: Validations.NewPasswordMatch
    })
  }

  previewPassword(index) {
    if (this.inputOptions[index].type === 'password') {
      this.inputOptions[index].type = 'text';
      this.inputOptions[index].icon = 'bi-eye-slash';
    } else {
      this.inputOptions[index].type = 'password';
      this.inputOptions[index].icon = 'bi-eye';
    }
  }

  onForgotPassword() {
    this.dialogService.showForgotPassword()
    this.modalRef.hide()
  }

  showModalToken() {
    this.phoneService.sendVerify(ApisEnum.Client, this.client.uuid, this.phone, this.phoneVerifyRetry);
    this.dialogService.showTokenDialog().subscribe(result => {
      if (result == 'resend') {
        this.phoneVerifyRetry += 1;
        this.phoneService.sendVerify(ApisEnum.Client, this.client.uuid, this.phone, this.phoneVerifyRetry);
      } else {
        this.checkVerify({ sms_code: result });
      }
    });
  }

  checkVerify(data: any) {
    this.phoneService.checkVerify(ApisEnum.Client, this.client.uuid, this.phone.id, data).then(res => {
      let dialogViewModel = {} as ConfirmDialogViewModel;
      if (res.status === 400) {
        dialogViewModel.subTitle = 'Código incorreto';
        dialogViewModel.msg = 'Algo deu errado com o código inserido.<br> Por favor, verifique e tente novamente.';
        dialogViewModel.txtOk = 'TENTAR OUTRA VEZ';
        this.showDialogTryAgain(dialogViewModel);
      } else if (res.status === 404) {
        dialogViewModel.msg = 'Seu código de confirmação está expirado. Você deve gerar um novo código de confirmação e tentar novamente.';
        dialogViewModel.tryAgain = true;
        dialogViewModel.txtOk = 'TENTAR OUTRA VEZ';
        this.showDialogTryAgain(dialogViewModel);
      } else if (res.status === 200) {
        this.onChangePassword()
      }
    })
  }

  showDialogTryAgain(model) {
    this.dialogService.showDialogTryAgain(model).subscribe(x => {
      if (x === 'try-again') {
        this.showModalToken();
      }
    })
  }

  onChangePassword() {
    let data = {}
    if (this.formGroup.valid) {
      data = this.formGroup.value

      delete data['confirm_password']
      this.clientService.updatePassword(data).then(res => {
        const viewModel = new ConfirmDialogViewModel()
        viewModel.subTitle = 'Sucesso!'
        viewModel.msg = 'Sua senha foi alterada com sucesso.\nPor favor, use a nova senha para efetuar login'
        this.dialogService.showDialogSuccess(viewModel).subscribe(redirect => {
          if (redirect) {
            this.commonService.revokeJWT().then(() => {
              this.clientService.resetData()
              this.modalRef.hide()
            }).catch(err => {
              console.error(err)
            })
          }
        })
      }).catch(err => {
        this.dialogService.showDialogError(err)
      })

    } else {
      this.formGroup.markAllAsTouched()
    }
  }

  get f() {
    return this.formGroup.controls
  }
}
