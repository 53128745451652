<div class="row justify-content-center">
  <div class="col col-12 text-center">
    <p class="fs-5 fw-bold my-4">{{title}}</p>
  </div>
  <div class="col col-12 text-center mb-4" [innerHTML]="msg"></div>
</div>
<div class="row justify-content-center">
  <button type="button" *ngIf="!hiddenCancel" class="col col-12 col-md-5 btn btn-outline-dark" data-dismiss="modal"
    (click)="onClose()">{{txtCancel}}</button>
  <button type="button" class="col col-12 col-md-5 my-3 my-md-0 btn btn-primary"
    (click)="onConfirm()">{{txtOk}}</button>
</div>
<div class="row justify-content-center my-3">
  <span *ngIf="txtLink" class="btn" (click)="onLink()">{{txtLink}}</span>
</div>