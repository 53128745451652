<div class="modal-header border-0">
  <span class="fs-3 text-center fw-bold w-100 m-auto"> Otimizar sua Carteira ideal </span>
  <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 mt-4">
      <div class="d-flex justify-content-between mb-2">
        <div (click)="changeStep(1, 33)" class="pointer">
          <span class="badge" [ngClass]="{'active' : step == 1}">1</span>
          <span class="badge-description ms-2" [ngClass]="{'active' : step == 1}">Objetivos</span>
        </div>
        <div (click)="changeStep(2, 66)" class="pointer">
          <span class="badge" [ngClass]="{'active' : step == 2}">2</span>
          <span class="badge-description ms-2" [ngClass]="{'active' : step == 2}">Opcionais</span>
        </div>
        <div (click)="changeStep(3, 100)" class="pointer">
          <span class="badge" [ngClass]="{'active' : step == 3}">3</span>
          <span class="badge-description ms-2" [ngClass]="{'active' : step == 3}">Receitas</span>
        </div>
      </div>
      <div class="progress w-100">
        <div class="progress-bar" [style.width.%]="progress" role="progressbar" aria-valuenow="20" aria-valuemin="0"
          aria-valuemax="100">
        </div>
      </div>
    </div>
  </div>
  <form autocomplete="off" novalidate>
    <div class="row" *ngIf="step == 1">
      <div class="col-12">
        <p class="my-4">
          Para otimizar uma carteira de investimentos, é essencial que você defina seus objetivos financeiros.
        </p>
        <!-- SEM OBJETIVO -->
        <section *ngIf="!hasGoals">
          <div class="alert bg-danger text-danger d-flex align-items-center" style="--bs-bg-opacity: 5%" role="alert">
            <icon-svg svgName="danger" class="w-20"></icon-svg>
            <span class="poppins fs-8">
              Você ainda não registrou nenhum objetivo. Para otimizar sua carteira, é fundamental entender quais são
              seus
              objetivos financeiros.
            </span>
          </div>
          <div class="text-center mb-3 mt-5">
            <button type="button" class="btn btn-primary" (click)="adjustGoals()">QUERO AJUSTAR OS OBJETIVOS</button>
          </div>
        </section>
        <section *ngIf="hasGoals">
          <div class="alert alert-primary d-flex align-items-center" role="alert">
            <icon-svg svgName="check-success" class="w-10 me-3"></icon-svg>
            <div>
              <span class="poppins fs-8 text-dark">Usando seus objetivos de vida</span>
              <p class="link fs-8 mb-0 fs-8" (click)="adjustGoals()">Quero ajustar os objetivos</p>
            </div>
          </div>
          <div class="text-center mb-3 mt-5">
            <button type="button" class="btn btn-primary" (click)="changeStep(2, 66)"
              [disabled]="!hasGoals">CONTINUAR</button>
          </div>
        </section>
      </div>
    </div>
    <div class="row" *ngIf="step == 2">
      <div class="col-12">
        <p class="fs-6 mt-4 mb-2">
          Você pode incluir seus temas e expectativas sobre os mercados.
        </p>
        <p class="fs-6 fw-semibold">
          Verifique se deseja usar suas visões do mercado e otimizar impostos
          <span class="text-danger">(em breve)</span>
        </p>
        <div class="card border flex-row align-items-center px-4 py-2 my-2" [ngClass]="{'bg-active': optional.themes}"
          [class.disabled]="true">
          <input type="checkbox" id="themes" [checked]="optional.themes" (change)="optional.themes = !optional.themes">
          <div class="ms-2">
            <label for="themes" class="poppins text-dark fs-8 m-0">Usando temas definidos</label>
            <p class="link fs-8 mb-0" (click)="adjustThemes()">Quero ajustar os temas
              escolhidos <span class="text-danger">(em breve)</span></p>
          </div>
        </div>
        <div class="card border flex-row align-items-center px-4 py-2 my-2"
          [ngClass]="{'bg-active': optional.expectations}" [class.disabled]="true">
          <input type="checkbox" id="expectations" [checked]="optional.expectations"
            (change)="optional.expectations = !optional.expectations">
          <div class="ms-2">
            <label for="expectations" class="poppins text-dark fs-8 m-0">Usando minhas expectativas futuras</label>
            <p class="link fs-8 mb-0" (click)="adjustExpectations()">Quero ajustar as
              expectativas <span class="text-danger">(em breve)</span></p>
          </div>
        </div>
        <div class="card border flex-row align-items-center px-4 py-2 my-2"
          [ngClass]="{'bg-active': optional.incomeTax}" [class.disabled]="true">
          <input type="checkbox" id="income-tax" [checked]="optional.incomeTax"
            (change)="optional.incomeTax = !optional.incomeTax">
          <div class="ms-2">
            <label for="income-tax" class="poppins text-dark fs-8 m-0">Usando imposto de renda</label>
            <p class="link fs-8 mb-0" (click)="adjustIncomeTax()">
              (Essa opção estará disponível em breve)</p>
          </div>
        </div>
        <div class="d-flex justify-content-between mb-3 mt-5">
          <button type="button" class="btn  btn-outline-dark ms-0" (click)="changeStep(1, 33)">VOLTAR</button>
          <button type="button" class="btn btn-primary me-0" (click)="changeStep(3, 100)">CONTINUAR</button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="step == 3">
      <div class="col-12">
        <p class="fs-6 mt-4 mb-2">
          Como último passo, defina qual valor você deseja usar para sua carteira de investimentos.
        </p>
        <div class="input-group align-items-center rounded border my-2" [class.disabled]="total_positions <= 0"
          [ngClass]="{'bg-active': optimizeOption == OptimizeOptions.rebalance}">
          <div class="col-11 d-flex align-items-center">
            <div class="form-check form-check-inline py-2">
              <input type="radio" class="d-none" id="rebalance" [(ngModel)]="optimizeOption" name="type-value"
                [value]="OptimizeOptions.rebalance">
              <label class="form-check-label fs-7" for="rebalance">Quero rebalancear minha carteira</label>
            </div>
          </div>
        </div>
        <div class="input-group align-items-center rounded border my-2"
          [ngClass]="{'bg-active': optimizeOption == OptimizeOptions.contribute}">
          <div class="form-check form-check-inline py-2">
            <input type="radio" class="d-none" id="add" [(ngModel)]="optimizeOption" name="type-value"
              [value]="OptimizeOptions.contribute">
            <label class="form-check-label fs-7" for="add">Quero apontar um valor extra</label>
          </div>
          <div class="form-group col" *ngIf="optimizeOption == OptimizeOptions.contribute">
            <input type="text" currencyMask [options]="currencyOption" class="form-control" [style.height.px]="45"
              placeholder="Valor extra" [(ngModel)]="extra_value" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="input-group align-items-center rounded border my-2" [class.disabled]="total_positions <= 0"
          [ngClass]="{'bg-active': optimizeOption == OptimizeOptions.redeem}">
          <div class="form-check form-check-inline py-2">
            <input type="radio" class="d-none" id="redeem" [(ngModel)]="optimizeOption" name="type-value"
              [value]="OptimizeOptions.redeem">
            <label class="form-check-label fs-7" for="redeem">Quero resgatar um valor</label>
          </div>
          <div class="form-group col" *ngIf="optimizeOption == OptimizeOptions.redeem">
            <input type="text" currencyMask [options]="currencyOption" class="form-control" [style.height.px]="45"
              placeholder="Valor a resgatar" [(ngModel)]="amount_to_redeem" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div class="d-flex justify-content-between mb-3 mt-5">
          <button type="button" class="btn  btn-outline-dark ms-0" (click)="changeStep(2, 66)">VOLTAR</button>
          <button type="button" class="btn btn-primary me-0" [disabled]="!optimizeOption"
            (click)="finish()">OTIMIZAR</button>
        </div>
      </div>
    </div>
  </form>
</div>