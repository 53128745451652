import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { LoginComponent } from './home/login/login.component';
import { AccessPasswordComponent } from './register/access-password/access-password.component';
import { FinishRegisterComponent } from './register/finish-register/finish-register.component';
import { AppNavRouteLink } from './shared/commons/AppNavRouteLink';
import { ContactGuard } from './shared/guards/contact-guard';
import { NoAuthGuard } from './shared/guards/no-auth.guard';
import { ProtectedRoutersGuard } from './shared/guards/protected-routers.guard';



const routes: Routes = [
  { path: '', redirectTo: AppNavRouteLink.PAGE.LOGIN, pathMatch: 'full' },
  {
    path: AppNavRouteLink.PAGE.LOGIN,
    component: LoginComponent,
    canActivate: [NoAuthGuard, ProtectedRoutersGuard],
    data: { title: 'Login' }
  },
  {
    path: AppNavRouteLink.PAGE.FORGOT_PASSWORD,
    component: ForgotPasswordComponent,
    canActivate: [NoAuthGuard, ProtectedRoutersGuard],
    data: { title: 'Esqueci minha senha' }
  },
  {
    path: AppNavRouteLink.PAGE.ACCESS_PASSWORD,
    component: AccessPasswordComponent,
    canActivate: [ContactGuard],
    data: { title: 'Definição de senha' }
  },
  {
    path: AppNavRouteLink.PAGE.FINISH_REGISTER,
    component: FinishRegisterComponent,
    // canActivate: [ContactGuard],
    data: { title: 'Vamos começar' }
  },
  {
    path: AppNavRouteLink.PAGE.CHANGE_PASSWORD,
    component: ChangePasswordComponent,
    data: { title: 'Alteração de senha' }
  },
  {
    path: AppNavRouteLink.PAGE.EMAIL_VERIFY,
    component: EmailVerifyComponent,
    data: { title: 'Verificação de e-mail' }
  },
  { path: AppNavRouteLink.MODULE.PORTAL, loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule) },
  { path: AppNavRouteLink.MODULE.REGISTER, loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
  { path: AppNavRouteLink.MODULE.PARTNERS, loadChildren: () => import('./partners/partners.module').then(m => m.PartnersModule) },
  // redirect page error
  { path: '**', redirectTo: AppNavRouteLink.PAGE.LOGIN }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })]
})

export class AppRoutingModule { }
