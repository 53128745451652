<!-- Chamado como página do cadastro -->
<ng-container *ngIf="template; else elseTemplate">
  <div class="content-login">
    <div class="content-image">
      <div class="col-11 col-md-7 col-lg-5 col-xl-4 mx-auto">
        <div class="shadow bg-white rounded pt-5" id="teste_pode_remover">
          <div class="p-5 poppins">
            <a href="/" class="d-flex justify-content-center">
              <img src="assets/images/png/logo_ladybank_lg.png" alt="">
            </a>
            <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Chamado como modal -->
<ng-template #elseTemplate>
  <div class="modal-header mx-3">
    <strong class="modal-title fs-4 pb-0">Esqueci minha senha</strong>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
  </div>
  <div class="modal-body mx-3">
    <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
  </div>
</ng-template>

<!-- template -->
<ng-template #formTemplate>
  <label class="text-black my-3">Digite seu documento, por favor. Enviaremos um link para redefinição de
    senha ao e-mail cadastrado.</label>
  <label class="text-black-50 my-3">Verifique também se a mensagem foi encaminhada para a pasta de
    SPAM</label>
  <form [formGroup]="formGroup" class="row gap-3 justify-content-center" autocomplete="off" novalidate
    (submit)="onClick()">
    <div class="form-group pb-4">
      <label for="document_number" class="poppins-bold">CPF ou CNPJ</label>
      <input type="text" OnlyNumber class="form-control" id="document_number" name="document_number"
        formControlName="document_number" PreventCopyPast>
      <invalid-feedback [field]="f.document_number" label="CPF ou CNPJ"></invalid-feedback>
    </div>
    <div class="row px-0">
      <button type="button" class="btn btn-outline-primary col my-1" (click)="login()">VOLTAR</button>
      <button type="submit" class="btn btn-primary col my-1">ENVIAR</button>
    </div>
  </form>
</ng-template>