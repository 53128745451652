<div class="modal-content p-4">
  <div class="modal-header border-0">
    <span class="fs-3 h1 w-100 m-0">Solicitar 2ª via do cartão</span>
    <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
  </div>
  <div class="modal-body pt-0">
    <div class="text-muted mb-3">Preencha as informações para solicitação de 2ª via do seu cartão pré-pago.</div>
    <strong class="fs-6 poppins">Cartão:</strong>
    <div class="col-12 my-2">
      <div class="row py-3 px-4 rounded bg-light">
        <div class="col-4">
          <img src="assets/images/png/cards/card-ladybank.png" alt="..." class="px-2">
        </div>
        <div class="col-6">
          <strong class="poppins fs-5">LadyBank</strong>
        </div>
      </div>
    </div>
    <form [formGroup]="formGroup" class="row" autocomplete="off" novalidate>
      <div class="form-group col-12 my-4">
        <label for="reason">Motivo da solicitação</label>
        <div class="dropdown">
          <select class="form-control" [(ngModel)]="reason" formControlName="reason">
            <option selected value="">Selecione...</option>
            <option *ngFor="let item of reasonList" [ngValue]="item.value">{{item.description}}</option>
          </select>
          <invalid-feedback [field]="f.reason"></invalid-feedback>
        </div>
      </div>
      <div class="col-12">
        <button type="submit" class="btn btn-primary btn-block mx-0" (click)="send()" [disabled]="formGroup.invalid">ENVIAR
          SOLICITAÇÃO</button>
      </div>
      <div class="col-12">
        <p class="color-brand-3 fs-7 poppins mt-3">Será cobrada tarifa para emissão da 2ª via do cartão LadyBank pré-pago.</p>
      </div>
      <div class="col-12">
        <button type="button" class="btn btn-outline-primary btn-block mx-0" (click)="modalRef.hide()">CANCELAR</button>
      </div>
    </form>
  </div>
</div>
