<div class="modal-header border-0">
  <span class="fs-3 h1 w-100 m-0">{{ requestCard ? 'Solicitação de cartão' : 'Editar logradouro'}}</span>
  <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body pt-0">
  <div class="mb-3" *ngIf="requestCard">Por favor, informe aqui para onde devemos enviar seu cartão.
    Verifique se todos os detalhes, como o complemento, estão corretos.</div>
  <form [formGroup]="formGroup" class="row gap-0 row-gap-3" autocomplete="off" novalidate>
    <div class="form-group col-4">
      <label for="zip_code">CEP</label>
      <input type="text" class="form-control" id="zip_code" name="zip_code" Mask="#####-###" formControlName="zip_code"
        (keyup)="getZipCode($event)">
      <invalid-feedback [field]="f.zip_code" label="CEP"></invalid-feedback>
    </div>
    <div class="form-group col-8">
      <label for="street">Endereço</label>
      <input type="text" class="form-control" id="street" name="street" formControlName="street" maxlength="128">
      <invalid-feedback [field]="f.street"></invalid-feedback>
    </div>
    <div class="form-group col-4">
      <label for="street_number">Número</label>
      <input type="text" OnlyNumber class="form-control" id="street_number" name="street_number"
        formControlName="street_number" maxlength="128">
      <invalid-feedback [field]="f.street_number"></invalid-feedback>
    </div>
    <div class="form-group col-8">
      <label for="complement">Complemento</label>
      <input type="text" class="form-control" id="complement" name="complement" formControlName="complement"
        maxlength="128">
      <invalid-feedback [field]="f.complement"></invalid-feedback>
    </div>
    <div class="form-group col-12">
      <label for="district">Bairro</label>
      <input type="text" class="form-control" id="district" name="district" formControlName="district" maxlength="128">
      <invalid-feedback [field]="f.district"></invalid-feedback>
    </div>
    <div class="form-group col-9">
      <label for="city">Cidade</label>
      <!-- <input type="text" id="city" name="city" class="form-control" formControlName="city" maxlength="128"> -->
      <select class="form-select" aria-placeholder="Cidade" formControlName="city">
        <option *ngFor="let item of cities" [value]="item.nome">
          {{item.nome}}
        </option>
      </select>
      <invalid-feedback [field]="f.city"></invalid-feedback>
    </div>
    <div class="form-group col-3">
      <label for="state">Estado</label>
      <!-- <input type="text" class="form-control" id="state" name="state" formControlName="state" maxlength="200"> -->
      <select class="form-select" aria-placeholder="Estado" formControlName="state" (change)="onGetCities($event)">
        <option *ngFor="let item of listUFs" [value]="item.sigla">
          {{item.sigla}}
        </option>
      </select>
      <invalid-feedback [field]="f.state"></invalid-feedback>
    </div>
    <div class="col-12">
      <p class="poppins mb-0" *ngIf="requestCard">*A primeira emissão do cartão não possui <span
          class="highlight normal">nenhum custo</span>.
      </p>
    </div>
  </form>
  <div class="d-flex justify-content-between mt-4">
    <button type="button" class="btn btn-outline-primary px-5 mx-0" (click)="close(false)">CANCELAR</button>
    <button type="submit" class="btn btn-primary px-5 mx-0" (click)="next()">CONTINUAR</button>
  </div>
</div>