<div class="content-register bg-register d-flex align-items-center justify-content-center">
  <div class="col-11 col-md-8 col-lg-6 col-xl-5">
    <div class="card">
      <div class="card-body py-4 px-3">
        <div class="card-title text-center">
          <a href="/" class="d-flex justify-content-center">
            <img src="assets/images/png/logo_ladybank_lg.png" alt="">
          </a>
          <p class="my-4 poppins-bold text-center fs-4">
            Cadastro Concluído!
          </p>
          <p>
            Seu cadastro foi realizado com sucesso. Acesse a plataforma LadyBank e conheça as nossas funcionalidades.
            Clique em "OK" para fazer login.
          </p>
          <button type="button" class="btn btn-primary mt-2" (click)="go()">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>