import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TagInputModule } from "ngx-chips";
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChoiceRiskProfileComponent } from './choice-risk-profile/choice-risk-profile.component';
import { AcceptTermDialogComponent } from './components/accept-term-dialog/accept-term-dialog.component';
import { AddressCardDialogComponent } from './components/address-card-dialog/address-card-dialog.component';
import { AlertDialogErrorComponent } from './components/alert-dialog-error/alert-dialog-error.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { AssetChoiceDialogComponent } from './components/asset-choice-dialog/asset-choice-dialog.component';
import { AssetDialogComponent } from './components/asset-dialog/asset-dialog.component';
import { BrokerDialogComponent } from './components/broker-dialog/broker-dialog.component';
import { CategoryDialogComponent } from './components/category-dialog/category-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ConfirmPaymentDialogComponent } from './components/confirm-payment-dialog/confirm-payment-dialog.component';
import { ConfirmTransferDialogComponent } from './components/confirm-transfer-dialog/confirm-transfer-dialog.component';
import { ContactDialogComponent } from './components/contact-dialog/contact-dialog.component';
import { CustomLoansDialogComponent } from './components/custom-loans-dialog/custom-loans-dialog.component';
import { DialogWithoutAccountsComponent } from './components/dialog-without-accounts/dialog-without-accounts.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { FilterDialogComponent } from './components/dialogs/filter-dialog/filter-dialog.component';
import { LinkDialogComponent } from './components/dialogs/link-dialog/link-dialog.component';
import { ReceiptDialogComponent } from './components/dialogs/receipt-dialog/receipt-dialog.component';
import { DuplicateCardDialogComponent } from './components/duplicate-card-dialog/duplicate-card-dialog.component';
import { EditFavoriteDialogComponent } from './components/edit-favorite-dialog/edit-favorite-dialog.component';
import { EditPatrimonyDialogComponent } from './components/edit-patrimony-dialog/edit-patrimony-dialog.component';
import { EditScheduledTransfersComponent } from './components/edit-scheduled-transfers/edit-scheduled-transfers.component';
import { EmailDialogComponent } from './components/email-dialog/email-dialog.component';
import { FavoriteDialogComponent } from './components/favorite-dialog/favorite-dialog.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { FormDialogComponent } from './components/form-dialog/form-dialog.component';
import { GoalDialogComponent } from './components/goal-dialog/goal-dialog.component';
import { GoalInsightDialogComponent } from './components/goal-insight-dialog/goal-insight-dialog.component';
import { InvalidGoalDialogComponent } from './components/invalid-goal-dialog/invalid-goal-dialog.component';
import { ManageCardLimitComponent } from './components/manage-card-limit/manage-card-limit.component';
import { MyFilesDialogComponent } from './components/my-files-dialog/my-files-dialog.component';
import { NewAlertDialogErrorComponent } from './components/new-alert-dialog-error/new-alert-dialog-error.component';
import { NewAlertDialogSuccessComponent } from './components/new-alert-dialog-success/new-alert-dialog-success.component';
import { OptimizeIdealPortfolioComponent } from './components/optimize-ideal-portfolio/optimize-ideal-portfolio.component';
import { OptionsCardDialogComponent } from './components/options-card-dialog/options-card-dialog.component';
import { PasswordCardDialogComponent } from './components/password-card-dialog/password-card-dialog.component';
import { PersonalDataDialogComponent } from './components/personal-data-dialog/personal-data-dialog.component';
import { PixChannelDialogComponent } from './components/pix-channel-dialog/pix-channel-dialog.component';
import { PixDialogComponent } from './components/pix-dialog/pix-dialog.component';
import { PixReverseDialogComponent } from './components/pix-reverse-dialog/pix-reverse-dialog.component';
import { PlansDialogComponent } from './components/plans-dialog/plans-dialog.component';
import { QrcodeDialogComponent } from './components/qrcode-dialog/qrcode-dialog.component';
import { ReceiptPdfComponent } from './components/receipt-pdf/receipt-pdf.component';
import { SelfieInfoDialogComponent } from './components/selfie-info-dialog/selfie-info-dialog.component';
import { SessionExpiredComponent } from './components/session-expired/session-expired.component';
import { SimulateValueDialogComponent } from './components/simulate-value-dialog/simulate-value-dialog.component';
import { SuitabilityMainFeaturesComponent } from './components/suitability-main-features/suitability-main-features.component';
import { TokenDialogComponent } from './components/token-dialog/token-dialog.component';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { LoginComponent } from './home/login/login.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { ContactGuard } from './shared/guards/contact-guard';
import { ProtectedRoutersGuard } from './shared/guards/protected-routers.guard';
import { AppConfigService } from './shared/services/app-config.service';
import { AuthenticationService } from './shared/services/authentication.service';
import { LoaderInterceptorService } from './shared/services/loader-interceptor.service';
import { LoaderService } from './shared/services/loader.service';
import { SharedModule } from './shared/shared.module';
import { SuitabilityFinishComponent } from './suitability-finish/suitability-finish.component';
import { ChangePasswordDialogComponent } from './components/change-password-dialog/change-password-dialog.component';

registerLocaleData(localePt);

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ConfirmDialogComponent,
        AlertDialogComponent,
        TokenDialogComponent,
        ForgotPasswordComponent,
        ChoiceRiskProfileComponent,
        DialogComponent,
        PersonalDataDialogComponent,
        SuitabilityFinishComponent,
        ChangePasswordComponent,
        CategoryDialogComponent,
        GoalDialogComponent,
        FormDialogComponent,
        MyFilesDialogComponent,
        ContactDialogComponent,
        EditFavoriteDialogComponent,
        EditScheduledTransfersComponent,
        PlansDialogComponent,
        NotificationsComponent,
        AddressCardDialogComponent,
        DuplicateCardDialogComponent,
        ManageCardLimitComponent,
        AssetDialogComponent,
        AssetChoiceDialogComponent,
        PixDialogComponent,
        OptimizeIdealPortfolioComponent,
        SuitabilityMainFeaturesComponent,
        PasswordCardDialogComponent,
        EditPatrimonyDialogComponent,
        EmailVerifyComponent,
        BrokerDialogComponent,
        OptionsCardDialogComponent,
        InvalidGoalDialogComponent,
        FavoriteDialogComponent,
        ConfirmTransferDialogComponent,
        PixChannelDialogComponent,
        SelfieInfoDialogComponent,
        EmailDialogComponent,
        PixReverseDialogComponent,
        QrcodeDialogComponent,
        ReceiptDialogComponent,
        FilterDialogComponent,
        CustomLoansDialogComponent,
        SimulateValueDialogComponent,
        AcceptTermDialogComponent,
        AlertDialogErrorComponent,
        NewAlertDialogErrorComponent,
        GoalInsightDialogComponent,
        NewAlertDialogSuccessComponent,
        SessionExpiredComponent,
        ReceiptPdfComponent,
        ConfirmPaymentDialogComponent,
        LinkDialogComponent,
        DialogWithoutAccountsComponent,
        ChangePasswordDialogComponent
    ],
    imports: [
        BrowserModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        AppRoutingModule,
        BrowserAnimationsModule,
        RouterModule,
        HttpClientModule,
        ModalModule.forRoot(),
        SharedModule,
        TagInputModule
    ],
    exports: [],
    providers: [
        AuthenticationService,
        AuthGuard,
        ContactGuard,
        ProtectedRoutersGuard,
        BsModalRef,
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: (appConfig: AppConfigService) => {
                return () => {
                    return appConfig.loadAppConfig();
                };
            },
            multi: true,
            deps: [AppConfigService]
        },
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        },
        LoaderService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptorService,
            multi: true
        },
        {
            provide: 'googleTagManagerId',
            useValue: 'GTM-N4WCS45'
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
