import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ClientModel } from 'src/app/shared/interfaces/client-model';
import { GoalModel } from 'src/app/shared/interfaces/goal.model';
import { ClientService } from 'src/app/shared/services/client.service';
import { FinancialService } from 'src/app/shared/services/financial.service';

@Component({
  selector: 'app-plans-dialog',
  templateUrl: './plans-dialog.component.html',
  styleUrls: ['./plans-dialog.component.scss']
})
export class PlansDialogComponent implements OnInit {

  client: ClientModel = null
  rb_account: any = {}
  confirmResult: Subject<string>
  listGoal: any[] = []
  listGoalsSaved: any[] = []

  constructor(
    public modalRef: BsModalRef,
    private clientService: ClientService,
    public financial: FinancialService
  ) {
    this.clientService.clientData.subscribe(d => {
      if (!d) { return }
      this.client = d
    })
    this.clientService.rbAccountData.subscribe(d => {
      if (!d) { return }
      this.rb_account = d
    })
  }

  ngOnInit(): void {
    this.confirmResult = new Subject()
    this.listGoal = this.financial.goals.filter(value => value.contem != false)
    this.getGoals()
  }

  getGoals() {
    this.financial.getPlansBack(this.client.uuid, { rb_account_uuid: this.rb_account.account_uuid }).then(result => {
      this.listGoalsSaved = result?.goals
    })
  }

  deleteItens(arr) {
    arr.forEach(element => {
      this.financial.deleteGoal(this.client.uuid, element.id, { rb_account_uuid: this.rb_account.account_uuid }).then(
        res => {
          console.log("Excluído: ", element.id)
        }
      )
    })
  }

  onClose(): void {
    this.confirmAndClose('objectives')
  }

  onConfirm(): void {
    // if (this.goalActive) {
    //   alert(`TODO: O sistema frontend deverá apresentar direcionar para a página Criar Objetivo - ${this.goalActive.name}. 
    //   E  em seguida, o novo objetivo criado deve ser incluso no final da lista, ou seja, com a menor prioridade e deve ter o flag “garantido” desativado. 
    //   A barra de tempo até atingimento deve estar em 0% e as chances de atingimento devem estar em 0%.`)
    // }
    this.onClose()
  }

  private confirmAndClose(value: string) {
    let listArr = this.prepareToGoal()
    let countPost = 0
    if (listArr.length > 0) {
      listArr.forEach(key => {
        countPost++
        this.financial.createFinancial(this.client.uuid, key, { rb_account_uuid: this.rb_account.account_uuid }).then(
          result => {
            console.log("result: ", result)
          },
          err => {
            console.log("result: ", err)
          })

        //Fecha o modal quando fizer todos os posts
        if (countPost == listArr.length) {
          this.confirmResult.next(value)
          this.modalRef.hide()
        }
      })
    } else {
      this.confirmResult.next(value)
      this.modalRef.hide()
    }
  }

  prepareToGoal() {
    let arrPost = []
    this.listGoal.forEach(key => {
      //Só adiciona itens que não estejam salvos
      if (key.selected && !this.searchItem(this.listGoalsSaved, 'type', key.value)) {
        let objGoal = {} as GoalModel
        objGoal.currency = 'BRL'
        objGoal.frequency = 30
        objGoal.numeraire_asset_index_uuid = this.clientService.getNumeraires(this.financial.getCurrency(key.value))
        objGoal.name = key.name
        objGoal.type = key.value
        objGoal.priority = key.priority
        objGoal.value = 0
        objGoal.date_first_payment = Math.floor(+new Date().getTime() / 1000)
        objGoal.repetitions = 0
        objGoal.description = 'Criado a partir de situação de vida em ' + moment().format('DD/MM/YYYY, HH:mm')
        objGoal.tenor = 0
        objGoal.is_regular = true
        objGoal.must_be_guaranteed = false
        objGoal.probability_attain = 0
        objGoal.tenor_type = 'actual_actual'
        objGoal.extra = JSON.stringify({ life_situation: true, })
        arrPost.push(objGoal)
      }
    })

    return arrPost
  }

  searchItem(arr, prop, item) {
    let foundItem = false
    for (let key of arr) {
      if (key[prop] == item) {
        foundItem = true
      }
    }
    return foundItem
  }
}
