<div class="content-register bg-register d-flex align-items-center justify-content-center">
  <div class="col-10 col-md-7  col-lg-5">
    <div class="shadow bg-white rounded pt-3">
      <a href="/" class="navbar-brand ms-3">
        <img src="assets/images/png/logo_ladybank.png" alt="">
      </a>
      <p class="fs-3 ms-2 mt-5 fw-bold px-3">Verificação de E-mail</p>
      <div class="px-2">
        <form [formGroup]="formGroup" class="justify-content-center px-3 pb-4" autocomplete="off" novalidate
          (submit)="verify()">
          <div class="form-group">
            <label>Número do documento</label>
            <input type="text" OnlyNumber id="document_number" name="document_number" class="form-control"
              formControlName="document_number" placeholder="CPF ou CNPJ" PreventCopyPast maxlength="18">
            <div *ngIf="(!formGroup.controls.document_number.pristine || formGroup.controls.document_number.touched) 
                && formGroup.controls.document_number.errors" class="invalid-feedback fs-8">
              <div *ngIf="formGroup.controls.document_number.errors.required"> Campo obrigatório. </div>
              <div *ngIf="formGroup.controls.document_number.errors.invalid"> Documento inválido. </div>
            </div>
          </div>
          <div class="text-center mt-4">
            <button class="btn btn-primary" type="submit">ENTRAR</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>