import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[autoFocus]'
})
export class AutoFocusDirective {

  constructor(private element: ElementRef) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.element.nativeElement.focus()
    }, 100)
  }
}
