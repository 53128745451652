import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.scss']
})
export class EmailDialogComponent implements OnInit {

  confirmResult: Subject<boolean | string>;
  sms_code = '';
  formToken: UntypedFormGroup;

  constructor(
      public modalRef: BsModalRef,
      private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();
    this.formToken = this.formBuilder.group({
      sms_code: [{
        value: this.sms_code
      }, Validators.compose([
        Validators.required,
        Validators.minLength(6)
      ])]
    })
  }

  onResend() {
    this.confirmResult.next('resend');
  }

  onConfirm() {
    if (this.formToken.valid) {
      this.confirmAndClose(this.sms_code);
    }
  }

  padLeft(e) {
    this.sms_code = e.target.value.padStart(6, '0').slice(-6)
  }

  private confirmAndClose(value: string) {
    this.confirmResult.next(value);
    this.modalRef.hide();
  }

  // convenience getter for easy access to form fields
  get f() { return this.formToken.controls; }
}

