<app-custom-loader></app-custom-loader>
<router-outlet (deactivate)="onDeactivate()"></router-outlet>

<!-- The actual snackbar -->
<div id="snackbar">
  <span></span>
  <icon-svg svgName="check-circle" class="float-left" size="1.5"></icon-svg>
</div>

<div class="d-flex justify-content-center align-items-center">
  <p class="fw-bold fs-9 m-0">
    LadyBank{{ appVersion ? ' - ' + appVersion : '' }} - API {{ apiVersion.version }}
  </p>
</div>