<div class="modal-header border-0">
  <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="text-center">
  <p class="fw-bold fs-5 mb-4">
    Você não possui contas conectadas
  </p>
  <icon-svg svgName="without-accounts"></icon-svg>
  <p class="fw-semibold m-4">
    Conecte as suas contas de outras instituições financeiras e acesse todas as informações em um só lugar.
  </p>
</div>
<div class="col mb-4 text-center">
  <button type="button" class="btn btn-primary" (click)="close()">INCLUIR NOVA CONTA</button>
</div>