import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { FormTypeEnum } from 'src/app/shared/enums/form-type.enum'
import * as moment from 'moment'
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss']
})
export class FormDialogComponent implements OnInit, AfterViewInit {

  @Input() type: string;
  @Input() modalType: string;
  @Input() title: string;
  @Input() subTitle: string;

  confirmResult: Subject<boolean | string>;
  formGroup: UntypedFormGroup;
  model: any = {};
  showSelectOptions?: boolean;
  selectOptions: any[] = [];
  selectFonts: any[] = [];
  selectPibs: any[] = [];

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();
    this.selectOptions = ['USD', 'BRL']
    this.selectFonts = ['FONT 1', 'FONT 2']
    this.selectPibs = ['PIB 1', 'PIB 2']

    this.formGroup = this.formBuilder.group({
      field_1: [{
        value: ''
      }, Validators.required],
      field_2: [{
        value: ''
      }, Validators.required],
      field_3: [{
        value: ''
      }, Validators.required],
      field_4: [{
        value: ''
      }, Validators.required],
      field_5: [{
        value: ''
      }, Validators.required]
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // if (this.type && this.type == FormTypeEnum.USD) {
      //   this.formGroup.addControl('field_3', new FormControl('', Validators.required))
      // }
      // if (this.type && this.type == FormTypeEnum.PIB) {
      //   this.model.field_2 = new Date()
      // }
    }, 0);
  }

  getSelectOptions(option) {
    this.showSelectOptions = option === 'absolute' ? true : false;
  }

  close() {
    this.confirmResult.next(false);
    this.modalRef.hide();
  }

  add() {
    this.confirmResult.next(this.model);
    this.modalRef.hide();
  }

  // convenience getter for easy access to form fields
  get f() { return this.formGroup.controls; }
  get FormTypeEnum() { return FormTypeEnum }
}
