import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AppNavRouteLink } from 'src/app/shared/commons/AppNavRouteLink';
import { Banks } from 'src/app/shared/commons/Banks';
import { INCOME_TYPE_ITEMS } from 'src/app/shared/commons/incomeTypes';
import { Utils } from 'src/app/shared/commons/Utils';
import { AccountTypeEnum } from 'src/app/shared/enums/account-type.enum';
import { TransferTypeEnum } from 'src/app/shared/enums/transfer-type.enum';
import { IncomeModel } from 'src/app/shared/interfaces/income.model';
import { ClientService } from 'src/app/shared/services/client.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { IncomeService } from 'src/app/shared/services/income.service';
import { StorageService as storage } from 'src/app/shared/services/storage.service';
@Component({
  selector: 'app-edit-patrimony-dialog',
  templateUrl: './edit-patrimony-dialog.component.html',
  styleUrls: ['./edit-patrimony-dialog.component.scss']
})
export class EditPatrimonyDialogComponent implements OnInit {
  formGroup: UntypedFormGroup;
  bankList: any = Banks.get();
  bankId: number;
  confirmResult: Subject<any>;
  editModel: any = {};
  currencyPipe: CurrencyPipe = new CurrencyPipe('pt-BR');

  @Input() item: any;
  types = _.orderBy(INCOME_TYPE_ITEMS(), ['label'], ['asc'])

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private incomeService: IncomeService,
    private client: ClientService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();
    this.loadForm();

  }

  loadForm() {
    this.formGroup = this.formBuilder.group({
      type: ['',
        Validators.compose([
          Validators.required,
        ])],
      title: [''],
      description: ['', Validators.required],
      value: ['', Validators.required]
    });

    setTimeout(() => {
      let extra = JSON.parse(this.item.extra);
      this.formGroup.patchValue({
        type: extra.subtype,
        title: this.item.name,
        description: this.item.description,
        value: this.currencyPipe.transform(this.item.value, 'BRL'),
      })
    }, 100);

  }

  onConfirm() {
    let incomeObj = {} as IncomeModel;
    incomeObj.id = this.item.id;
    incomeObj.type = 'patrimony';
    incomeObj.description = this.formGroup.get('description').value;
    incomeObj.value = Utils.moneyTofloat(this.formGroup.get('value').value);
    incomeObj.currency = 'BRL';
    incomeObj.numeraire_asset_index_uuid = this.client.getNumeraires('BRL');
    incomeObj.date_first_payment = Math.floor(+new Date().getTime() / 1000);
    incomeObj.frequency = 0;
    incomeObj.repetitions = 0;
    incomeObj.name = 'Patrimonio';
    incomeObj.extra = JSON.stringify({
      subtype: this.formGroup.get('type').value
    })

    if (this.formGroup.valid) {
      this.incomeService.updateIncome(storage.getClientUuid(), incomeObj.id, incomeObj, storage.getClientJwt()).then(
        res => {
          this.dialogService.showSnackBar('Patrimônio editado com sucesso', 'success');
          this.confirmResult.next(incomeObj);
          this.modalRef.hide();
        },
        err => {
          this.dialogService.showDialogError('Houve um problema para criar o Patrimônio');
        }
      )
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  get TransferType() {
    return TransferTypeEnum
  }

  get AccountType() {
    return Object.keys(AccountTypeEnum).map(key => {
      return ({ id: key, name: AccountTypeEnum[key] })
    })
  }

  goBack() {
    this.router.navigate([AppNavRouteLink.TABS_FINANCIAL_PLANNING.PATRIMONY]);
  }

  create() {
    if (this.formGroup.valid) {
      this.router.navigate([AppNavRouteLink.TABS_FINANCIAL_PLANNING.PATRIMONY]);
      setTimeout(() => {
        this.dialogService.showSnackBar('Patrimônio criado com sucesso', 'success');
      }, 300);
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  get f() { return this.formGroup.controls; }
}