<div class="row text-center">
  <span class="fw-bolder text-gold pt-3 fs-1">Tempo Expirado</span>
  <div class="col col-9 mx-auto text-center mt-4">
    <img src="assets/images/png/session-expired.png" class="w-100" alt="">
    <p class="fw-bold my-3">{{title}}</p>
    <div *ngIf="msg" class="msg" [innerHTML]="msg"></div>
  </div>
</div>
<div class="col my-3 text-center mt-3">
  <button type="button" class="btn btn-gold text-white" (click)="onClose()">Login</button>
</div>
<div class="d-flex justify-content-center align-items-center pb-3">
  <a href="https://blog.ladybank.com.br/" target="_blank" rel="noopener noreferrer">
    <icon-svg svgName="icon-blog"></icon-svg>
  </a>
  <a href="https://t.me/LadyBank_Bot" target="_blank" rel="noopener noreferrer">
    <icon-svg svgName="social_media/telegram"></icon-svg>
  </a>
</div>