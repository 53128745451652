import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-token-dialog',
  templateUrl: './token-dialog.component.html',
  styleUrls: ['./token-dialog.component.scss']
})
export class TokenDialogComponent implements OnInit {

  confirmResult: Subject<boolean | string>;
  sms_code = '';
  formToken: UntypedFormGroup;

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();
    this.formToken = this.formBuilder.group({
      sms_code_1: ['', Validators.required],
      sms_code_2: ['', Validators.required],
      sms_code_3: ['', Validators.required],
      sms_code_4: ['', Validators.required],
      sms_code_5: ['', Validators.required],
      sms_code_6: ['', Validators.required]
    })
  }

  onResend() {
    this.confirmResult.next('resend');
  }
  
  onConfirm() {
    if (this.formToken.valid) {
      this.sms_code = ''
      const form = this.formToken.value
      Object.keys(form).forEach(key => {
        this.sms_code += form[key]
      })
      this.confirmAndClose(this.sms_code);
    } else {
      this.formToken.markAllAsTouched()
    }
  }

  padLeft(e) {
    this.sms_code = e.target.value.padStart(6, '0').slice(-6)
  }

  private confirmAndClose(value: string) {
    this.confirmResult.next(value);
    this.modalRef.hide();
  }
  
  // convenience getter for easy access to form fields
  get f() { return this.formToken.controls; }
}
