export enum EnumFinancial {
  FormingCouple          	            =	1,	//	Formando um Casal
  RaisingFamily          	            =	2,	//	Aumentando a família
  EducationChildren	                  =	3,	//	Educação pessoal ou filhos
  ReturningToStudies	                =	4,	//	Retornando aos estudos
  FamilyMemberLeavingHome    	        =	5,	//	Membro familiar deixando a casa
  FamilyMemberComingHome	            =	6,	//	Membro familiar retornando à casa
  ExpensesWithRelatives	              =	7,	//	Despesas com parentes
  PlanningTripOrExchange	            =	8,	//	Planejando viagem ou intercâmbio
  DeathOrPassingAway	                =	9,	//	Morte ou falecimento
  SeparationCouple        	          =	10,	//	Separação ou divórcio
  WorkingFullTime	                    =	11,	//	Trabalhando em tempo integral
  WorkingPartTime        	            =	12,	//	Trabalhando tempo parcial
  QuitYourJob           	            =	13,	//	Saiu do emprego
  StartingABusiness      	            =	14,	//	Empreendendo
  ExpandingAbusiness	                =	15,	//	Expandindo um negócio
  SellingABusiness       	            =	16,	//	Encerrando um negócio
  CreatingAfoundation	                =	17,	//	Criando uma fundação
  CreatingNGOOrInstitution	          =	18,	//	Criando uma ONG ou instituição
  LongTermUnemployed	                =	19,	//	Desempregado de longo prazo
  Retire                 	            =	20,	//	Aposentadoria
  TakingOutInsurance	                =	21,	//	Contratando um seguro
  ReproductionAndFertility	          =	22,	//	Reprodução e fertilidade
  SpecializedTreatment	              =	23,	//	Tratamento especializado
  AestheticProcedures	                =	24,	//	Procedimentos estéticos
  ExtraordinaryHealthcareExpenses	    =	25,	//	Despesas de saúde extraordinárias
  BestAge	                            =	26,	//	Melhor idade
  MedicalEmergencyAndHospitalization	=	27,	//	Urgência médica e internação
  AccidentsAndDamages	                =	28,	//	Acidentes e danos
  CaringLovedOneFamily   	            =	29,	//	Cuidados com entes queridos
  HealthHousing	                      =	30,	//	Moradia de saúde
  BuyingACar             	            =	31,	//	Comprando ou trocando veículo
  BuyingHouse            	            =	32,	//	Comprando uma propriedade
  HomeReform             	            =	33,	//	Reformando a casa 
  ChangingTheHouse	                  =	34,	//	Trocando a casa
  ReceivedAnInheritance  	            =	35,	//	Recebendo uma herança
  ReceivingADonation	                =	36,	//	Recebendo uma doação
  LossNaturalDisaster    	            =	37,	//	Perda de patrimônio por desastre natural
  AcquiringAMovableAsset	            =	38,	//	Adquirindo um bem móvel
  AcquiringRealEstate	                =	39,	//	Adquirindo um bem imóvel
  HiringLegalAdvice	                  =	40,	//	Contratando acessoria jurídica
  PayingOffDebts	                    =	41,	//	Quitando dívidas
  HiringAconsortium	                  =	42,	//	Contratando um consórcio
  TakingOutACredit	                  =	43,	//	Contratando um crédito
  TakingOutFinancing	                =	44,	//	Contratando um financiamento
  PurchasingAnInvestment	            =	45,	//	Adquirindo um investimento
}