import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApisEnum } from '../enums/apis.enum';
import { GoalEnum, GoalIconEnum, GoalNameEnum, GoalNumeraireIndexEnum } from '../enums/goals.enum';
import { EnumFinancial } from '../enums/situation-financial.enum';
import { AppUpdateService } from './app-update.service';
import { StorageService as storage } from './storage.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class FinancialService {

  // LISTA DE ACORDO COM O GOALENUM
  goals = [
    {
      value: GoalEnum.car,
      name: GoalNameEnum.car,
      icon: GoalIconEnum.car,
      priority: 1,
      numeraire: GoalNumeraireIndexEnum.car,
      contem: false
    },
    {
      value: GoalEnum.house,
      name: GoalNameEnum.house,
      icon: GoalIconEnum.house,
      priority: 1,
      numeraire: GoalNumeraireIndexEnum.house,
      contem: false
    },
    {
      value: GoalEnum.events,
      name: GoalNameEnum.events,
      icon: GoalIconEnum.events,
      priority: 1,
      numeraire: GoalNumeraireIndexEnum.events,
      contem: false
    },
    {
      value: GoalEnum.travel,
      name: GoalNameEnum.travel,
      icon: GoalIconEnum.travel,
      priority: 1,
      numeraire: GoalNumeraireIndexEnum.travel,
      contem: false
    },
    {
      value: GoalEnum.emergency,
      name: GoalNameEnum.emergency,
      icon: GoalIconEnum.emergency,
      priority: 1,
      numeraire: GoalNumeraireIndexEnum.emergency,
      contem: false
    },
    {
      value: GoalEnum.retirement,
      name: GoalNameEnum.retirement,
      icon: GoalIconEnum.retirement,
      priority: 1,
      numeraire: GoalNumeraireIndexEnum.retirement,
      contem: false
    },
    {
      value: GoalEnum.wealth,
      name: GoalNameEnum.wealth,
      icon: GoalIconEnum.wealth,
      priority: 1,
      numeraire: GoalNumeraireIndexEnum.wealth,
      contem: false
    },
    {
      value: GoalEnum.health,
      name: GoalNameEnum.health,
      icon: GoalIconEnum.health,
      priority: 1,
      numeraire: GoalNumeraireIndexEnum.health,
      contem: false
    },
    {
      value: GoalEnum.education,
      name: GoalNameEnum.education,
      icon: GoalIconEnum.education,
      priority: 1,
      numeraire: GoalNumeraireIndexEnum.education,
      contem: false
    },
    {
      value: GoalEnum.growth,
      name: GoalNameEnum.growth,
      icon: GoalIconEnum.growth,
      priority: 1,
      numeraire: GoalNumeraireIndexEnum.growth,
      contem: false
    },
    {
      value: GoalEnum.acquisition,
      name: GoalNameEnum.acquisition,
      icon: GoalIconEnum.acquisition,
      priority: 1,
      numeraire: GoalNumeraireIndexEnum.acquisition,
      contem: false
    },
    {
      value: GoalEnum.payments,
      name: GoalNameEnum.payments,
      icon: GoalIconEnum.payments,
      priority: 1,
      numeraire: GoalNumeraireIndexEnum.payments,
      contem: false
    },
    {
      value: GoalEnum.justice,
      name: GoalNameEnum.justice,
      icon: GoalIconEnum.justice,
      priority: 1,
      numeraire: GoalNumeraireIndexEnum.justice,
      contem: false
    },
    {
      value: GoalEnum.custom,
      name: GoalNameEnum.new,
      icon: GoalIconEnum.new,
      priority: 1,
      numeraire: GoalNumeraireIndexEnum.new,
      contem: false
    },
  ]

  constructor(
    private http: HttpClient,
    private router: Router,
    private utilService: UtilService,
    private appUpdateService: AppUpdateService
  ) { }


  /**
   * Check App Update
   * @param res Response
   */
  private checkAppUpdate(res: Response | any): Response {
    if (res.headers.has('x-appversion')) {
      this.appUpdateService.appVersion.emit(res.headers.get('x-appversion'));
    }
    return res;
  }

  getPlans() {
    return [
      { id: 1, title: 'Formando um casal', group_id: 0, selected: false },
      { id: 2, title: 'Aumentando a família', group_id: 0, selected: false },
      { id: 3, title: 'Educação pessoal ou filhos', group_id: 0, selected: false },
      { id: 4, title: 'Retornando aos estudos', group_id: 0, selected: false },
      { id: 5, title: 'Membro familiar deixando a casa', group_id: 0, selected: false },
      { id: 6, title: 'Membro familiar retornando à casa', group_id: 0, selected: false },
      { id: 7, title: 'Despesas com parentes', group_id: 0, selected: false },
      { id: 8, title: 'Planejando viagem ou intercâmbio', group_id: 0, selected: false },
      { id: 9, title: 'Morte ou falecimento', group_id: 0, selected: false },
      { id: 10, title: 'Separação ou divórcio', group_id: 0, selected: false },
      { id: 11, title: 'Tragalhando em tempo integral', group_id: 1, selected: false },
      { id: 12, title: 'Trabalhando tempo parcial', group_id: 1, selected: false },
      { id: 13, title: 'Saiu do emprego', group_id: 1, selected: false },
      { id: 14, title: 'Empreendendo', group_id: 1, selected: false },
      { id: 15, title: 'Expandindo um negócio', group_id: 1, selected: false },
      { id: 16, title: 'Encerrando um negócio', group_id: 1, selected: false },
      { id: 17, title: 'Criando uma fundação', group_id: 1, selected: false },
      { id: 18, title: 'Criando uma ONG ou instituição', group_id: 1, selected: false },
      { id: 19, title: 'Desempregado de longo prazo', group_id: 1, selected: false },
      { id: 20, title: 'Aposentadoria', group_id: 1, selected: false },
      { id: 21, title: 'Contratando um seguro', group_id: 2, selected: false },
      { id: 22, title: 'Reprodução e fertilidade', group_id: 2, selected: false },
      { id: 23, title: 'Tratamento especializado', group_id: 2, selected: false },
      { id: 24, title: 'Procedimentos estéticos', group_id: 2, selected: false },
      { id: 25, title: 'Despesas de saúde extraordinárias', group_id: 2, selected: false },
      { id: 26, title: 'Melhor idade', group_id: 2, selected: false },
      { id: 27, title: 'Urgência médica e internação', group_id: 2, selected: false },
      { id: 28, title: 'Acidentes e danos', group_id: 2, selected: false },
      { id: 29, title: 'Cuidados com entes queridos', group_id: 2, selected: false },
      { id: 30, title: 'Moradia de saúde', group_id: 2, selected: false },
      { id: 31, title: 'Comprando ou trocando veículo', group_id: 3, selected: false },
      { id: 32, title: 'Comprando uma propriedade', group_id: 3, selected: false },
      { id: 33, title: 'Reformando a casa ', group_id: 3, selected: false },
      { id: 34, title: 'Trocando a casa', group_id: 3, selected: false },
      { id: 35, title: 'Recebendo uma herança', group_id: 3, selected: false },
      { id: 36, title: 'Recebendo uma doação', group_id: 3, selected: false },
      { id: 37, title: 'Perda de patrimônio por desastre natural', group_id: 3, selected: false },
      { id: 38, title: 'Adquirindo um bem móvel', group_id: 3, selected: false },
      { id: 39, title: 'Adquirindo um bem imóvel', group_id: 3, selected: false },
      { id: 40, title: 'Contratando acessoria jurídica', group_id: 3, selected: false },
      { id: 41, title: 'Quitando dívidas', group_id: 4, selected: false },
      { id: 42, title: 'Contratando um consórcio', group_id: 4, selected: false },
      { id: 43, title: 'Contratando um crédito', group_id: 4, selected: false },
      { id: 44, title: 'Contratando um financiamento', group_id: 4, selected: false },
      { id: 45, title: 'Adquirindo um investimento', group_id: 4, selected: false },
    ]
  }

  addPriority(situation) {
    this.clearGoals();
    //Passar pela lista de situações selecionadas
    for (let j of situation) {

      let arrPriority = this.searchPrioritySituation(j.id);
      let order = 1;
      //Pegar a ordem de prioridades
      for (let index = 0; index < arrPriority?.length; index++) {
        const i = arrPriority[index]
        //Setar a order de prioridade e true para exibir o item
        for (let h of this.goals) {
          if (i == h.value) {
            h.priority = order;
            h.contem = true;
          }
        }
        order++
      }
    }

    this.goals.sort(function (x, y) {
      return x.priority - y.priority;
    })
  }

  clearGoals() {
    this.goals.forEach(element => {
      element.contem = false;
    });
  }

  searchPrioritySituation(situation) {
    switch (situation) {
      case EnumFinancial.FormingCouple: //Formando um Casal
        return ['house', 'education', 'emergency', 'travel', 'events', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.RaisingFamily: //Aumentando a família
        return ['car', 'house', 'education', 'emergency', 'travel', 'events', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.EducationChildren: //Educação pessoal ou filhos
        return ['car', 'house', 'education', 'emergency', 'travel', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.ReturningToStudies: //Retornando aos estudos
        return ['house', 'education', 'emergency', 'travel', 'events', 'acquisition', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.FamilyMemberLeavingHome: //Membro familiar deixando a casa
        return ['emergency', 'travel', 'acquisition', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.FamilyMemberComingHome: //Membro familiar retornando à casa
        return ['car', 'emergency', 'acquisition', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.ExpensesWithRelatives: //Despesas com parentes
        return ['car', 'house', 'emergency', 'travel', 'events', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.PlanningTripOrExchange: //Planejando viagem ou intercâmbio
        return ['car', 'education', 'emergency', 'travel', 'events', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.DeathOrPassingAway: //Morte ou falecimento
        return ['emergency', 'travel', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.SeparationCouple: //Separação ou divórcio
        return ['car', 'house', 'education', 'emergency', 'travel', 'acquisition', 'retirement', 'health', 'growth', 'payments', 'wealth', 'justice', 'new']
      case EnumFinancial.WorkingFullTime: //Trabalhando em tempo integral
        return ['car', 'house', 'education', 'emergency', 'travel', 'events', 'acquisition', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.WorkingPartTime: //Trabalhando tempo parcial
        return ['car', 'house', 'education', 'emergency', 'acquisition', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.QuitYourJob: //Saiu do emprego
        return ['education', 'retirement', 'health', 'payments', 'justice', 'new']
      case EnumFinancial.StartingABusiness: //Começando um negócio
        return ['wealth', 'justice', 'new']
      case EnumFinancial.StartingABusiness: //Expandindo um negócio
        return ['wealth', 'justice', 'new']
      case EnumFinancial.SellingABusiness: //Encerrando um negócio
        return ['emergency', 'retirement', 'health', 'payments', 'justice', 'new']
      case EnumFinancial.CreatingAfoundation: //Criando uma fundação
        return ['education', 'emergency', 'events', 'acquisition', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.CreatingNGOOrInstitution: //Criando uma ONG ou instituição
        return ['education', 'emergency', 'events', 'acquisition', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.LongTermUnemployed: //Desempregado de longo prazo
        return ['payments', 'justice', 'new']
      case EnumFinancial.Retire: //Aposentadoria
        return ['house', 'emergency', 'travel', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.TakingOutInsurance: //Contratando um seguro
        return ['emergency', 'acquisition', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.ReproductionAndFertility: //Reprodução e fertilidade
        return ['car', 'emergency', 'travel', 'acquisition', 'retirement', 'health', 'payments', 'justice', 'new']
      case EnumFinancial.SpecializedTreatment: //Tratamento especializado
        return ['emergency', 'travel', 'acquisition', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.AestheticProcedures: //Procedimentos estéticos
        return ['emergency', 'travel', 'acquisition', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.ExtraordinaryHealthcareExpenses: //Despesas de saúde extraordinárias
        return ['emergency', 'travel', 'acquisition', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.BestAge: //Melhor idade
        return ['education', 'emergency', 'travel', 'events', 'acquisition', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.MedicalEmergencyAndHospitalization: //Urgência médica e internação
        return ['retirement', 'health', 'payments', 'justice', 'new']
      case EnumFinancial.AccidentsAndDamages: //Acidentes e danos
        return ['car', 'emergency', 'acquisition', 'retirement', 'health', 'payments', 'justice', 'new']
      case EnumFinancial.CaringLovedOneFamily: //Cuidados com entes queridos
        return ['car', 'emergency', 'acquisition', 'retirement', 'health', 'payments', 'justice', 'new']
      case EnumFinancial.HealthHousing: //Moradia de saúde
        return ['emergency', 'acquisition', 'retirement', 'health', 'payments', 'justice', 'new']
      case EnumFinancial.BuyingACar: //Comprando ou trocando veículo
        return ['car', 'emergency', 'acquisition', 'retirement', 'health', 'payments', 'justice', 'new']
      case EnumFinancial.BuyingHouse: //Comprando uma propriedade
        return ['house', 'emergency', 'acquisition', 'retirement', 'health', 'payments', 'justice', 'new']
      case EnumFinancial.HomeReform: //Reformando a casa 
        return ['house', 'emergency', 'acquisition', 'retirement', 'health', 'payments', 'justice', 'new']
      case EnumFinancial.ChangingTheHouse: //Trocando a casa
        return ['house', 'emergency', 'acquisition', 'retirement', 'health', 'payments', 'justice', 'new']
      case EnumFinancial.ReceivedAnInheritance: //Recebeu uma herança
        return ['car', 'house', 'emergency', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.ReceivingADonation: //Recebendo uma doação
        return ['car', 'house', 'emergency', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.LossNaturalDisaster: //Perda de patrimônio por desastre natural
        return ['car', 'house', 'travel', 'acquisition', 'retirement', 'health', 'payments', 'justice', 'new']
      case EnumFinancial.AcquiringAMovableAsset: //Adquirindo um bem móvel
        return ['emergency', 'acquisition', 'retirement', 'health', 'payments', 'justice', 'new']
      case EnumFinancial.AcquiringRealEstate: //Adquirindo um bem imóvel
        return ['emergency', 'acquisition', 'retirement', 'health', 'payments', 'justice', 'new']
      case EnumFinancial.HiringLegalAdvice: //Contratando acessoria jurídica
        return ['emergency', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.PayingOffDebts: //Quitando dívidas
        return ['retirement', 'health', 'payments', 'justice', 'new']
      case EnumFinancial.HiringAconsortium: //Contratando um consórcio
        return ['car', 'house', 'emergency', 'acquisition', 'retirement', 'health', 'growth', 'payments', 'justice', 'new']
      case EnumFinancial.TakingOutACredit: //Contratando um crédito
        return ['car', 'house', 'emergency', 'travel', 'events', 'acquisition', 'retirement', 'health', 'payments', 'justice', 'new']
      case EnumFinancial.TakingOutFinancing: //Contratando um financiamento
        return ['car', 'house', 'emergency', 'travel', 'events', 'acquisition', 'retirement', 'health', 'payments', 'justice', 'new']
      case EnumFinancial.PurchasingAnInvestment: //Adquirindo um investimento
        return ['emergency', 'acquisition', 'retirement', 'health', 'payments', 'justice', 'new']
      default:
        break;
    }
  }

  /**
   * Create a Client Goal
   * @param data Data Goals
   * @param jwt Client JWT
   */
  createFinancial(client_uuid: string, data: Object, filter?: any, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri(['/client', client_uuid, 'pms/goal', filter, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.post(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Update a Client Goal
   * @param data Data Client Goal
   * @param jwt Client JWT
   */
  updateGoal(client_uuid: string, goal_id: number, data: Object, filter?: any, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri(['/client', client_uuid, 'pms/goal', goal_id, filter, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const dataPost = this.utilService.buildPostData(data);

    return this.http.put(url, dataPost, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Update a Client Goal
   * @param data Data Client Goal
   * @param jwt Client JWT
   */
  deleteGoal(client_uuid: string, goal_id: number, filter?: any, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri(['/client', client_uuid, 'pms/goal', goal_id, filter, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    return this.http.delete(url, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get Client Goals
   * @param client_uuid Client UUID 
   * @param jwt Client JWT
   */
  getPlansBack(client_uuid: string, filter?: any, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri(['/client', client_uuid, 'pms/goals', filter, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  /**
   * Get Client Goal
   * @param client_uuid Client UUID 
   * @param jwt Client JWT
   */
  getPlan(client_uuid: string, goal_id: number, filter: any, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri(['/client', client_uuid, 'pms/goal', goal_id, filter, { jwt: jwt || storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => this.utilService.handleError(err, this.router));
  }

  orderList(list) {
    return list?.sort(function (x, y) {
      return x.priority - y.priority;
    });
  }

  getImgGoal(type) {
    switch (type) {
      case 'car':
        return 'bg-mini-car';
      case 'house':
        return 'bg-mini-house';
      case 'events' || 'marriage':
        return 'bg-mini-marriage';
      case 'travel':
        return 'bg-mini-travels';
      case 'emergency':
        return 'bg-mini-emergency';
      case 'retirement':
        return 'bg-mini-retirement';
      case 'wealth':
        return 'bg-mini-wealth';
      case 'health':
        return 'bg-mini-health';
      case 'education' || 'studies':
        return 'bg-mini-studies';
      case 'growth':
        return 'bg-mini-growth';
      case 'acquisition':
        return 'bg-mini-acquisition';
      case 'payments':
        return 'bg-mini-payments';
      case 'justice':
        return 'bg-mini-justice';
      case 'other' || 'new':
        return 'bg-mini-custom';

      default:
        break;
    }
  }

  getColorGoal(type) {
    switch (type) {
      case 'car':
        return '#05c4a3';
      case 'education':
        return '#8939ad';
      case 'travel':
        return '#fe2865';
      case 'house':
        return '#3546ab';
      case 'emergency':
        return '#267a48';
      case 'events':
        return '#f17048';
      case 'retirement':
        return '#0075fc';
      case 'other':
        return '#F0A174';
      case 'wealth':
        return '#ff829d';
      case 'health':
        return '#26a69a';
      default:
        break;
    }
  }

  getTitleGoal(type) {
    switch (type) {
      case 'car':
        return '#05c4a3';
      case 'education':
        return '#8939ad';
      case 'travel':
        return '#fe2865';
      case 'house':
        return '#3546ab';
      case 'emergency':
        return '#267a48';
      case 'events':
        return '#f17048';
      case 'retirement':
        return '#0075fc';
      case 'other':
        return '#F0A174';
      case 'wealth':
        return '#ff829d';
      case 'health':
        return '#26a69a';
      default:
        break;
    }
  }

  getCurrency(type) {
    return GoalNumeraireIndexEnum[type]
  }


  /**
   * Create a Client Goal
   * @param data Data Goals
   * @param jwt Client JWT
   */
  optimizePortfolio(client_uuid: string, data: any, filter?: any, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri(['/client', client_uuid, 'pms/optimize', filter, { jwt: jwt || storage.getClientJwt() }]);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(url, data, { headers, observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => {
        this.utilService.handleError(err, this.router)
        return err
      });
  }

  /**
   * Apply Allocation Simulated to Model
   * @param client_uuid Client UUID
   * @param allocation_id Allocation Simulated ID
   * @param jwt Client JWT
   * @returns 
   */
  applyAllocationSimulatedToModel(client_uuid: string, allocation_id: number, filter?: any, jwt?: string): Promise<any> {
    const url = this.utilService.makeUri(['/client', client_uuid, 'pms', 'allocation', allocation_id, filter, { jwt: jwt || storage.getClientJwt() }]);

    const dataPost = {};

    return this.http.put(url, dataPost, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => {
        this.utilService.handleError(err, this.router)
        return err
      });
  }

  getThemes() {
    const url = this.utilService.makeUri([ApisEnum.Partner, 'pms', 'investment-themes', { jwt: storage.getClientJwt() }]);
    return this.http.get(url, { observe: 'response' })
      .toPromise()
      .then(res => {
        this.checkAppUpdate(res);
        return res.body;
      })
      .catch(err => { return this.utilService.handleError(err, this.router) });
  }

}
