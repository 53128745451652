import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AppNavRouteLink } from 'src/app/shared/commons/AppNavRouteLink';
import { Banks } from 'src/app/shared/commons/Banks';
import { Utils } from 'src/app/shared/commons/Utils';
import { AccountModel } from 'src/app/shared/interfaces/account-model';
import { ClientModel } from 'src/app/shared/interfaces/client-model';
import { ClientService } from 'src/app/shared/services/client.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ConfirmDialogViewModel } from '../confirm-dialog/confirm-dialog.view-model';

@Component({
  selector: 'app-favorite-dialog',
  templateUrl: './favorite-dialog.component.html',
  styleUrls: ['./favorite-dialog.component.scss'],
  animations: [
    trigger('slideLeft', [
      transition(':enter', [
        style({ transform: 'translateX(0%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(100%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ])
    ]),
    trigger('slideRight', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class FavoriteDialogComponent implements OnInit {

  confirmResult: Subject<any>;
  favoritesList: any = [];
  showSearch = false;
  filterPost: string;
  client: ClientModel = null;
  account: AccountModel = null;
  banks = Banks.get();
  edit = false;

  constructor(
    private clientService: ClientService,
    private dialogService: DialogService,
    public modalRef: BsModalRef,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();

    this.clientService.clientData.subscribe(d => {
      if (!d) { return; }
      this.client = d;
    });

    this.clientService.accountData.subscribe(d => {
      if (!d) { return; }
      this.account = d;
    });

    this.getFavoritesList()
  }

  getFavoritesList(page: number = 1) {
    const data = {
      type: 'pix'
    };

    if (page === 1) {
      this.favoritesList = [];
    }

    // get favorites
    this.clientService.getAccountFavorites(this.client['uuid'], this.account['uuid'], data)
      .then(res => {
        for (const f of res['favorites']) {
          f['bank'] = (this.banks.filter(b => b['code'] === f['bank_code']))[0];
          this.favoritesList.push(f)
        }

        // pagination
        if (res['statistics']['total_pages'] > page) {
          return this.getFavoritesList(page + 1);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  makeTransfer(item) {
    this.confirmResult.next({ favorite: item});
    this.modalRef.hide();
  }
  
  onEdit(item) {
    item.bankCode = item.bank?.code;
    this.dialogService.editFavoriteDialog(item).subscribe(model => {
      if (model) {
        this.saveFavorite(model);
      }
    });
  }

  saveFavorite(data) {
    let data_fav: any = {
      id: data['id'],
      bank_code: data['bank_code'],
      branch: data['branch'],
      description: '',
      extra_data: data['extra_data'] ? data['extra_data'] : { internal: false },
      document_number: Utils.unMask(data['document_number']),
      name: data['name'],
      number: `${data['account']}${data['digit']}`,
      type: 'pix'
    };

    this.clientService.updateAccountFavorite(this.client['uuid'], this.account['uuid'], data_fav.id, data_fav).then(() => {
      this.getFavoritesList();
    })
  }

  remove(item) {
    const model = new ConfirmDialogViewModel();
    model.subTitle = 'Deseja deletar o favorito?';
    model.msg = `<span >O favorito será excluído definitivamente.</span>`
    model.txtOk = 'CONFIRMAR';
    model.txtCancel = 'CANCELAR';
    this.dialogService.showConfirm(model).subscribe(confirm => {
      if (confirm) {
        this.removeFavorite(item['id'])
      }
    });
  }

  removeFavorite(id) {
    this.clientService.removeAccountFavorite(this.client['uuid'], this.account['uuid'], id).then(() => {
      this.getFavoritesList();
    })
  }

  // add() {
  //   this.confirmResult.next(this.model);
  //   this.modalRef.hide();
  // }

  // convenience getter for easy access to form fields
  // get FormTypeEnum() { return FormTypeEnum }

}
