<div class="justify-content-center">
  <div class="col col-12 text-center">
    <p class="fs-3 h1 my-4">Confirmação de transferência</p>
  </div>
  <div class="col col-12 mb-4">
    <form class="row">
      <div class="col-6 text-end">
        <label>Valor</label>
      </div>
      <div class="col-6 text-start">
        <p class="text-muted">{{loaded_data?.amount}}</p>
      </div>
      <div class="col-6 text-end">
        <label>Tipo de transferência</label>
      </div>
      <div class="col-6 text-start">
        <p class="text-muted">{{loaded_data?.transferType}}</p>
      </div>
      <div class="col-6 text-end">
        <label>Favorecido</label>
      </div>
      <div class="col-6 text-start">
        <p class="text-muted">{{loaded_data?.name || loaded_pix?.name}}</p>
      </div>
      <div class="col-6 text-end" *ngIf="loaded_data?.transferType != TransferType.INTERNAL">
        <label>CPF/CNPJ</label>
      </div>
      <div class="col-6 text-start" *ngIf="loaded_data?.transferType == TransferType.PIX">
        <p class="text-muted">{{loaded_pix?.extra_data?.formattedDocument || maskDocument(loaded_data?.keyPix)}}
        </p>
      </div>
      <div class="col-6 text-start" *ngIf="loaded_data?.transferType == TransferType.TED">
        <p class="text-muted">{{maskDocument(loaded_data?.document_number)}}</p>
      </div>
      <div class="col-6 text-end">
        <label>Banco</label>
      </div>
      <div class="col-6 text-start" *ngIf="loaded_data?.transferType == TransferType.INTERNAL">
        <p class="text-muted">LadyBank</p>
      </div>
      <div class="col-6 text-start" *ngIf="loaded_data?.transferType != TransferType.INTERNAL">
        <p class="text-muted">{{bankName(loaded_data?.bank_code, loaded_pix?.extra_data?.ispbName)}}</p>
      </div>
      <div class="col-6 text-end"
        *ngIf="loaded_data?.transferType == TransferType.INTERNAL || loaded_data?.transferType == TransferType.TED">
        <label>Agência</label>
      </div>
      <div class="col-6 text-start" *ngIf="loaded_data?.transferType == TransferType.INTERNAL">
        <p class="text-muted">0001</p>
      </div>
      <div class="col-6 text-start" *ngIf="loaded_data?.transferType == TransferType.TED">
        <p class="text-muted">{{loaded_data?.branch || loaded_pix?.extra_data.branch}}</p>
      </div>
      <div class="col-6 text-end"
        *ngIf="loaded_data?.transferType == TransferType.INTERNAL || loaded_data?.transferType == TransferType.TED">
        <label>Conta</label>
      </div>
      <div class="col-6 text-start"
        *ngIf="loaded_data?.transferType == TransferType.INTERNAL || loaded_data?.transferType == TransferType.TED">
        <p class="text-muted">
          {{loaded_data?.account_number || loaded_pix?.number}}-{{loaded_data?.account_digit || loaded_pix?.digit}}
        </p>
      </div>
      <div class="col-6 text-end" *ngIf="loaded_data?.transferType != TransferType.PIX">
        <label>Tipo de conta</label>
      </div>
      <div class="col-6 text-start" *ngIf="loaded_data?.transferType == TransferType.INTERNAL">
        <p class="text-muted">Corrente</p>
      </div>
      <div class="col-6 text-start" *ngIf="loaded_data?.transferType == TransferType.TED">
        <p class="text-muted">{{AccountType[loaded_data?.accountType]}}</p>
      </div>
      <div class="col-6 text-end">
        <label>Data</label>
      </div>
      <div class="col-6 text-start">
        <p class="text-muted">{{loaded_data?.dateTransfer | date: 'dd/MM/yyyy'}}</p>
      </div>
      <div class="col-6 text-end">
        <label>Descrição</label>
      </div>
      <div class="col-6 text-start">
        <p class="text-muted">{{loaded_data?.description}}</p>
      </div>
    </form>
  </div>
  <!-- {{loaded_data | json}}
    {{loaded_pix | json}} -->
  <div class="col col-12 text-center my-2" [innerHTML]="msg"></div>
  <div class="col col-12 text-center mb-4 text-danger fw-bold">
    As transações PIX estão limitadas no período noturno das 20hs às 06hs e aos finais de semana e nos
    feriados ao valor máximo de R$ 1.000,00. Verifique sua tabela de limites.
  </div>
</div>
<div class="btn-group my-3 mx-lg-3">
  <button type="button" class="btn btn-outline-dark w-100" loaded_data-dismiss="modal"
    (click)="modalRef.hide()">VOLTAR</button>
  <button type="button" class="btn btn-primary w-100" (click)="onConfirm()">CONFIRMAR</button>
</div>