<div class="col-12 px-0 mx-0">
  <div class="position-relative">
    <em class="material-icons search left pointer bg-primary text-white p-2"
      style="width: 40px; margin-left: 6px; top: 8px;" (click)="show_filter()">search</em>
    <tag-input placeholder="Filtros ativos" class="x-icon" style="padding-left: 3rem" name="filterPost"
      (onFocus)="show_filter()" secondaryPlaceholder="Filtrar" [ngModel]="formatted_tags" [identifyBy]="'value'"
      [displayBy]="'label'">
      <ng-template let-item="item">
        <span (click)="show_filter()">{{ item.value }}: {{ item.label }}</span>
      </ng-template>
    </tag-input>
  </div>
</div>