<div class="wrap">
    <div class="col-12">
        <div class="row py-4 px-2 p-md-5">
            <div class="col-12">
                <p class="title-medium bold">Filtrar</p>
            </div>
            <div class="col-12 field my-3" *ngFor="let filter of getOptions();let i = index">
                <div class="col-12 form-group" *ngIf="filter.format == 'currency'">
                    <span>{{ filter.title }}</span>
                    <input [Currency]="{symbol: ''}" class="form-control" type="text" [(ngModel)]="filter.model">
                </div>
                <div class="col-12 form-group" *ngIf="filter.format == 'numeric'">
                    <span>{{ filter.title }}</span>
                    <input OnlyNumber class="form-control" type="text" [(ngModel)]="filter.model">
                </div>
                <div class="col-12 form-group" *ngIf="filter.format == 'text'">
                    <span>{{ filter.title }}</span>
                    <input class="form-control" type="text" [(ngModel)]="filter.model">
                </div>
                <div class="col-12" *ngIf="filter.format == 'single'">
                    <span>{{ filter.title }}</span>
                    <select class="form-select" [ngModel]="filter.model.value" (change)="onChange(filter, $event)">
                        <option *ngFor="let item of filter.items" [value]="item.value">
                            {{item.label}}
                        </option>
                    </select>
                </div>
                <div class="col-12" *ngIf="filter.format == 'tags'">
                    <span>{{ filter.title }}</span>
                    <tag-input [(ngModel)]="filter.model" [identifyBy]="'value'" [displayBy]="'label'"
                        [onlyFromAutocomplete]="true" [placeholder]="filter.secondary_placeholder"
                        [secondaryPlaceholder]="filter.placeholder" [onAdding]="verifyExclusive">
                        <tag-input-dropdown [showDropdownIfEmpty]="true" [appendToBody]="false" [displayBy]="'label'"
                            [identifyBy]="'value'" [autocompleteItems]="filter.items" zIndex="10000"
                            [dynamicUpdate]="false">
                            <ng-template let-item="item" let-index="index">
                                {{ item.label }}
                            </ng-template>
                        </tag-input-dropdown>
                    </tag-input>
                </div>
                <div class="col-12" *ngIf="filter.format === 'date'">
                    <span>{{ filter.title }}</span>
                    <input type="text" Mask="##/##/####" [minDate]="getMinDate(filter)"
                        [bsConfig]="{containerClass:'theme-default', adaptivePosition: true}" bsDatepicker
                        class="form-control" [(ngModel)]="filter.model" [ngModelOptions]="{standalone: true}">
                </div>
                <div class="col-12" *ngIf="filter.format === 'date-range'">
                    <span>{{ filter.title }}</span>
                    <input type="text" Mask="##/##/#### - ##/##/####" placeholder="DD/MM/YYYY - DD/MM/YYYY"
                        class="form-control" [(ngModel)]="filter.model"
                        [bsConfig]="{containerClass:'theme-default', adaptivePosition: true}" bsDaterangepicker
                        readonly>
                </div>
            </div>
        </div>
        <div class="row col-12 justify-content-around p-3 mb-4 mx-auto mx-md-3">
            <button class="col-12 col-md btn btn-outline-primary col-auto expand" (click)="close()">CANCELAR</button>
            <button class="col-12 col-md mt-3 mt-md-0 btn btn-primary col-auto expand"
                (click)="search()">PESQUISAR</button>
        </div>
    </div>
</div>