<div class="modal-header border-0">
  <span class="fs-3 h1 w-100 m-0">Editar dados pessoais</span>
  <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="formGroup" class="row gap-0 row-gap-3" autocomplete="off" novalidate>
    <div class="form-group col-12" *ngIf="client.juridicial">
      <label for="friendly_name">Nome social</label>
      <input type="text" id="friendly_name" name="friendly_name" class="form-control" formControlName="friendly_name"
        maxlength="200">
    </div>
    <div class="form-group col-12" *ngIf="!client.juridicial">
      <label for="name">Nome completo</label>
      <input type="text" id="name" name="name" class="form-control" formControlName="name" maxlength="200">
      <invalid-feedback [field]="f.name"></invalid-feedback>
    </div>
    <ng-container *ngIf="!client.juridicial">
      <div class="form-group col-12">
        <label for="mother_name">Nome completo da mãe</label>
        <input type="text" class="form-control" id="mother_name" name="mother_name" formControlName="mother_name"
          maxlength="128">
        <invalid-feedback [field]="f.mother_name"></invalid-feedback>
      </div>
      <div class="form-group col-12">
        <label for="birth_date">Data de Nascimento</label>
        <input type="text" id="birth_date" name="birth_date" class="form-control" formControlName="birth_date"
          Mask="##/##/####">
        <invalid-feedback [field]="f.birth_date"></invalid-feedback>
      </div>
      <div class="form-group col-12">
        <label class="col-12">Gênero</label>
        <div class="form-check-inline" *ngFor="let item of listGender; let i = index">
          <input class="form-check-input" type="radio" id="gender{{i}}" [value]="item.value" formControlName="gender">
          <label class="form-check-label" for="gender{{i}}">{{item.name}}</label>
          <invalid-feedback *ngIf="i == 0" [field]="f.gender"></invalid-feedback>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="marital_status">Estado civil</label>
        <div class="dropdown">
          <select class="form-select" formControlName="marital_status">
            <option selected value="">Selecione...</option>
            <option *ngFor="let item of listMaritalStatus" [value]="item.value">{{item.name}}</option>
          </select>
          <invalid-feedback [field]="f.marital_status"></invalid-feedback>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="secondary_document_number">Número do RG</label>
        <input type="text" OnlyNumber class="form-control" id="secondary_document_number" name="secondary_document_number"
          formControlName="secondary_document_number">
        <invalid-feedback [field]="f.secondary_document_number"></invalid-feedback>
      </div>
    </ng-container>
    <div class="form-group col-12">
      <label for="document_number">Número do {{client.juridicial ? 'CNPJ' : 'CPF'}}</label>
      <input type="tel" class="form-control" id="document_number" name="document_number" Mask="###.###.###-##"
        formControlName="document_number">
      <invalid-feedback [field]="f.document_number"></invalid-feedback>
    </div>
    <div class="form-group col-12">
      <label for="income">{{client.juridicial ? 'Faturamento mensal aproximado' : 'Renda mensal aproximada'}}</label>
      <input type="tel" currencyMask [options]="currencyOption" class="form-control" id="income" name="income" formControlName="income">
      <invalid-feedback [field]="f.income"></invalid-feedback>
    </div>
    <div class="form-group col-12" *ngIf="!client.juridicial">
      <label for="occupation">Ocupação</label>
      <input type="text" class="form-control" id="occupation" formControlName="occupation" name="occupation"
        (ngModelChange)="setOccupation($event)">
      <invalid-feedback [field]="f.occupation"></invalid-feedback>
    </div>
    <div class="d-flex justify-content-between mt-4">
      <button type="submit" class="btn btn-outline-dark px-5 mx-0" (click)="close()">CANCELAR</button>
      <button type="submit" class="btn btn-primary px-5 mx-0" (click)="save()">SALVAR</button>
    </div>
  </form>
</div>