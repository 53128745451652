<div *ngIf="required(field)"> Campo obrigatório. </div>
<div *ngIf="invalid(field)"> {{label}} inválida/o. {{complement}} </div>
<div *ngIf="pattern(field)"> {{label}} inválida/o. {{complement}} </div>
<div *ngIf="date(field)"> Data inválida. </div>
<div *ngIf="isMinor(field)"> Idade mínima 18 anos. </div>
<div *ngIf="minDate(field)"></div>
<div *ngIf="minValue(field)"> Valor mínimo permitido  {{isPercent ? (value | percent : '1.2-2') : (minCurrency || value | currency: 'BRL')}} </div>
<div *ngIf="maxValue(field)"> Valor máximo permitido {{isPercent ? (value | percent : '1.2-2') : (maxCurrency || value | currency: 'BRL')}} </div>
<div *ngIf="minLength(field)"> Mínimo {{field.errors.minlength.requiredLength}} dígitos.</div>
<div *ngIf="confirmPassword(field)"> {{label}} </div>
<div *ngIf="invalidPassword(field)"> A senha deve conter no mínimo 6 caracteres, 1 número e 1 caractere especial. </div>