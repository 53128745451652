<ng-container *ngIf="assetOption && assetOption.value">
  <div class="modal-header border-0" [style.background-color]="AssetColor[assetOption.value]">
    <div class="d-flex justify-content-arround col-12">
      <span class="text-center text-truncate title-medium w-100"
        [ngClass]="{'text-body': assetOption.value == 'exchange'}"> Adicionar
        {{assetOption.name}} </span>
      <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
    </div>
    <div class="d-flex justify-content-center col-12">
      <span class="poppins fs-7"
        [ngClass]="{'text-body': assetOption.value == 'exchange'}">{{assetOption.description}}</span>
    </div>
  </div>
  <div class="row col col-12 mx-0 mx-md-2 px-0 px-md-3">
    <app-filter [input_options]="getFilters()" (ChangeFilter)="filterAssets($event)"> </app-filter>
  </div>
  <div class="modal-body" style="margin-top: 0 !important;">
    <div class="col-12 fs-8 my-3"
      *ngIf="assetOption.value == 'exchange' || assetOption.value == 'investment-fund' || assetOption.value == 'retirement'">
      <span class="me-5">Risco
        <button class="btn risk-low" (click)="filterRiskLevel(['1,2'])" tooltip="Conservador"></button>
        <button class="btn risk-medium" (click)="filterRiskLevel(['3,4'])" tooltip="Arrojado"></button>
        <button class="btn risk-high" (click)="filterRiskLevel(['5,6,7,8'])" tooltip="Agressivo"></button>
      </span>
    </div>
    <div class="col-12 fs-7 my-3" *ngIf="assetOption.value == 'variable-income'">
      <span class="float-right">Atualizado às {{timeUpdate | date: 'HH:mm'}}. Delay 15 min <span class="text-danger">(em
          breve)</span></span>
    </div>
    <div class="overflow-auto">
      <table class="table table-hover table-borderless align-middle">
        <thead class="table-dark">
          <tr
            *ngIf="assetOption.value == 'exchange' || assetOption.value == 'investment-fund' || assetOption.value == 'retirement'">
            <th colspan="5" class=""></th>
            <th colspan="3" class="fs-7 text-center text-truncate text-white align-middle" tooltip="(em breve)">
              Rentabilidade</th>
          </tr>
          <tr>
            <th *ngFor="let column of columns; let i = index"
              class="{{column.class}} text-truncate text-white align-middle">
              {{column.name}}
            </th>
          </tr>
        </thead>
        <tbody *ngIf="assetOption.value == 'direct-treasure'">
          <tr
            *ngFor="let item of filtered_assets | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }"
            class="" (click)="selectAsset(item)" [ngClass]="{'table-active': isSelected(item)}">
            <td class="text-start text-truncate">{{item.name}}</td>
            <td class="text-center text-truncate">{{getAssetDueDate(item) | date: 'dd/MM/yyyy'}}</td>
            <td class="text-start text-truncate">{{item?.bond?.is_yield_quote_mult ? item.bond.coupon_mult_rate :
              item.bond.coupon_add_rate | percent: '1.1-1' }}</td>
            <td class="text-center text-truncate">
              {{item?.bond.last_yield ? (item?.bond.last_yield | currency: 'BRL'): 'N/A'}}
            </td>
            <td class="text-end text-truncate">{{item?.ask_price | currency: 'BRL'}}</td>
            <td class="text-end text-truncate">{{item?.bid_price | currency: 'BRL'}}</td>
            <td class="text-center text-truncate">
              {{item.partner_assets[0]?.trading_detail?.min_invest_amount_multiplier ?
              (item.partner_assets[0]?.trading_detail?.min_invest_amount_multiplier * item?.last_price | currency: 'BRL') : 0}}</td>
          </tr>
        </tbody>
        <tbody *ngIf="assetOption.value == 'fixed-income'">
          <tr
            *ngFor="let item of filtered_assets | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }"
            class="" (click)="selectAsset(item)" [ngClass]="{'table-active': isSelected(item)}">
            <td class="text-center text-truncate">{{item.sub_sectype | uppercase}}</td>
            <td class="text-start text-truncate">{{item.issuer.name}}</td>
            <td class="text-center text-truncate">{{getAssetRentability(item)}}</td>
            <td class="text-center text-truncate">{{getAssetDueDate(item) | date: 'dd/MM/yyyy'}}</td>
            <td class="text-center text-truncate">{{getAssetLiquidity(item)}}</td>
            <td class="text-end text-truncate">{{getAssetMinAmount(item) | currency: 'BRL'}}</td>
          </tr>
        </tbody>
        <tbody class="border-0" *ngIf="assetOption.value == 'investment-fund' || assetOption.value == 'retirement'">
          <tr
            *ngFor="let item of filtered_assets | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }"
            class=" border-risk risk-{{getAssetRiskLevel(item)}}" (click)="selectAsset(item)"
            [ngClass]="{'table-active': isSelected(item)}">
            <td class="text-start text-truncate" tooltip="Quer saber mais? Clique aqui (em breve)">
              {{item.partner_assets[0].name || item.name}}
            </td>
            <td class="text-start text-truncate">{{item.partner_assets[0].classification?.class_2 ||
              item.partner_assets[0].classification?.class_2}}</td>
            <td class="text-start text-truncate">{{item.issuer.name}}</td>
            <td class="text-start text-truncate">D + {{item.partner_assets[0].liquidity_tenor_min || 1}}</td>
            <td class="text-center text-truncate">{{item.partner_assets[0].min_invest_amount | currency: 'BRL'}}</td>
            <td class="text-center text-truncate"
              [ngClass]="item.monthlyRentability > 0 ? 'text-success' : 'text-danger'">
              {{item.monthlyRentability | percent: '1.1-1'}}</td>
            <td class="text-center text-truncate"
              [ngClass]="item.rentabilidadeAnual > 0 ? 'text-success' : 'text-danger'">
              {{item.annualRentability | percent: '1.1-1'}}</td>
            <td class="text-center text-truncate"
              [ngClass]="item.twelveMonthRentability > 0 ? 'text-success' : 'text-danger'">
              {{item.twelveMonthRentability | percent: '1.1-1'}}</td>
          </tr>
        </tbody>
        <tbody class="border-0" *ngIf="assetOption.value == 'exchange'">
          <tr
            *ngFor="let item of exchangeList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }"
            class=" border-risk risk-{{getAssetRiskLevel(item)}}" (click)="selectAsset(item)"
            [ngClass]="{'table-active': isSelected(item)}">
            <td class="text-start text-truncate col">
              {{item.name}}
            </td>
            <td class="text-center text-truncate col">{{item.type}}</td>
            <td class="text-center text-truncate col">{{item.corretora}}</td>
            <td class="text-center text-truncate col">{{item.valorMinimo | currency: 'BRL'}}</td>
            <td class="text-center text-truncate col"
              [ngClass]="item.rentabilidadeMensal > 1 ? 'text-success' : 'text-danger'">
              {{item.rentabilidadeMensal | percent: '1.1-1'}}</td>
            <td class="text-center text-truncate col"
              [ngClass]="item.rentabilidadeAnual > 1 ? 'text-success' : 'text-danger'">
              {{item.rentabilidadeAnual | percent: '1.1-1'}}</td>
            <td class="text-center text-truncate col"
              [ngClass]="item.rentabilidade12Meses > 1 ? 'text-success' : 'text-danger'">
              {{item.rentabilidade12Meses | percent: '1.1-1'}}</td>
          </tr>
        </tbody>
        <tbody class="border-0" *ngIf="assetOption.value == 'variable-income'">
          <tr
            *ngFor="let item of filtered_assets | filter: filterPost | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalItems }"
            class="" (click)="selectAsset(item)" [ngClass]="{'table-active': isSelected(item)}">
            <td class="text-start text-truncate">{{item.partner_assets[0].name || item.name}}</td>
            <td class="text-center text-truncate">{{item.partner_assets[0].classification.asset_codigo}}</td>
            <td class="text-end text-truncate">{{item.last_price | currency: 'BRL'}}</td>
            <td class="text-center text-truncate"
              [ngClass]="item.last_price / item.close_price_dminus1 -1 > 0 ? 'text-success' : 'text-danger'">
              {{item.last_price / item.close_price_dminus1 -1
              | percent: '1.1-1'}}</td>
            <td class="text-center text-truncate">Em breve</td>
          </tr>
        </tbody>
      </table>
      <div class="col-12 mt-3 d-flex justify-content-end align-items-center">
        <pagination-controls class="custom-pagination" (pageChange)="pageChanged($event)" [maxSize]="itemsPerPage"
          responsive="true" autoHide="true" (pageBoundsCorrection)="pageChanged($event)" directionLinks="true"
          previousLabel="" nextLabel="">
        </pagination-controls>
        <div class="form-group m-0 ms-5">
          <select [(ngModel)]="itemsPerPage" (change)="pageChanged(1)"
            class="form-control form-control-sm bg-light border-0">
            <option [ngValue]="5">5</option>
            <option [ngValue]="10">10</option>
            <option [ngValue]="15">15</option>
            <option [ngValue]="20">20</option>
          </select>
        </div>
        <div class="poppins fs-8 my-0 ms-2 text-secondary">Itens por página</div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0 d-flex justify-content-center">
    <span class="highlight">Você decide exclusivamente quais ativos e valores. A porcentagens (%) representa a alocação
      na sua carteira.</span>
    <div class="col-12 d-flex justify-content-center">
      <button type="button" class="btn btn-outline-primary" (click)="modalRef.hide()">CANCELAR</button>
      <button type="button" class="btn btn-primary" (click)="confirmAndClose()">ADICIONAR</button>
    </div>
  </div>
</ng-container>