<div class="modal-header border-0">
	<button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="row justify-content-md-center pb-5">
	<div class="col col-12 text-center">
		<p class="h1">Confirmação</p>
	</div>
	<div class="col col-12 mx-5 text-center">
		<span>Enviamos um código de confirmação ao seu </span><strong class="fs-5">email</strong>
	</div>
	<div class="col col-12 mx-5">
		<p class="text-center">Verifique também se a sua mensagem não foi encaminhada para a pasta de SPAM.</p>
	</div>
	<form [formGroup]="formToken" class="row col col-12 mx-auto px-3 justify-content-center" autocomplete="off"
		novalidate>
		<div class="col col-12 px-0 col-md-8 text-center justify-content-center">
			<input type="text" id="sms_code" name="sms_code" OnlyNumber placeholder="000000" class="form-control"
				[(ngModel)]="sms_code" formControlName="sms_code" (keyup)="padLeft($event)">
			<div *ngIf="(!f.sms_code.pristine || f.sms_code.touched) && f.sms_code.errors" class="invalid-feedback fs-8">
				<div *ngIf="f.sms_code.errors.required"> Campo obrigatório. </div>
				<div *ngIf="f.sms_code.errors.minlength"> No mínimo {{f.sms_code.errors.minlength.requiredLength}}
					caracteres. </div>
			</div>
		</div>
	</form>
	<div class="col col-12 text-center my-3">
		<button class="btn link" (click)="onResend()">
			<icon-svg svgName="reload" class="float-left me-2"></icon-svg>REENVIAR
		</button>
	</div>
	<div class="row col-12 mx-auto text-center justify-content-center">
		<button type="button" class="col col-12 col-md-5 btn btn-outline-dark" data-dismiss="modal"
			(click)="modalRef.hide()">CANCELAR</button>
		<button type="button" type="submit" class="col col-12 col-md-5 my-3 my-md-0 btn btn-primary"
			[disabled]="!formToken.valid" (click)="onConfirm()">VALIDAR</button>
	</div>
</div>