<div class="modal-header border-0">
  <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="row justify-content-center">
  <div class="col col-12">
    <p class="col-10 mx-auto fs-4 h1 mb-1">Copie ou Compartilhe o QR Code</p>
  </div>
  <div class="row col-10 mx-auto mb-2">
    <span class="col-12 fw-bolder">Para receber o PIX, compartilhe ou copie o QR Code
      abaixo.</span>
    <span class="col-12">Banco: <span class="fw-bolder">LadyBank</span></span>
    <span class="col-12">Nome: <span class="fw-bolder">{{ client.name }}</span></span>
    <span class="col-12">Valor: <span class="fw-bolder">{{ transfer_amount | currency: 'BRL'
        }}</span></span>
    <span class="col-12">Chave PIX: <span class="fw-bolder">{{ key_value }}</span></span>
  </div>
  <qr-code id="qrcode" class="col-auto mx-auto" [value]="qr_code" size="200" errorCorrectionLevel="M"
    centerImageSrc="undefined" centerImageSize="undefined">
  </qr-code>
</div>
<div class="row mx-auto col col-10 justify-content-center my-3">
  <button type="button" class="col col-12 col-md-5 btn btn-outline-dark" data-dismiss="modal" (click)="share()"
    [disabled]="disableSharedButton"
    [tooltip]="disableSharedButton ? 'Seu browser não permite o compartilhamento. Por favor, use um outro browser e entre em contato com nosso suporte. Pedimos desculpas pela inconveniência.' : '' ">
    Compartilhar
  </button>
  <button type="button" class="col col-12 col-md-5 my-3 my-md-0 btn btn-primary" (click)="copy()">Copiar</button>
</div>