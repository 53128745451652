import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { UploadFileService } from '../upload/upload-file.service';
import { Validations } from 'src/app/shared/commons/Validations';
import { FileSubType, FileType } from 'src/app/shared/enums/file-type.enum';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-my-files-dialog',
  templateUrl: './my-files-dialog.component.html',
  styleUrls: ['./my-files-dialog.component.scss']
})
export class MyFilesDialogComponent implements OnInit {

  labelFilePhoto = 'Enviar selfie'
  labelFileFront = 'Enviar documento com foto frente (RG ou CNH)'
  labelFileBack = 'Enviar documento com foto verso (RG ou CNH)'
  labelFileDocument = 'Enviar documento de identificação (RG ou CNH)'
  labelFileAddress = 'Enviar comprovante de endereço'
  fileAccept = ['image/png', 'image/jpeg', 'application/pdf']
  textTooltipSelf = `Estamos comprometidos em combater a lavagem de dinheiro. As entidades de regulamento dos mercados financeiros exigem uma análise de vericidade das informações. A selfie é um item importante para isso.`;
  fileSizeMB = 5;
  formGroup: UntypedFormGroup
  changeFiles = false;
  docFrontBack = false;
  confirmResult: Subject<boolean | string>;

  //TODO: Desabilitar os campos por enquanto
  disableFields = true

  constructor(
    public modalRef: BsModalRef,
    private fileService: UploadFileService,
    private formBuilder: UntypedFormBuilder,

  ) { }

  ngOnInit(): void {
    this.confirmResult = new Subject();
    this.buildForm();
    this.getFiles();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      selfie: [{
        value: ''
      }, Validators.compose([
        Validators.required,
        Validations.File(this.fileAccept, this.fileSizeMB, true)
      ])],
      address_path: [{
        value: ''
      }, Validators.compose([
        Validators.required,
        Validations.File(this.fileAccept, this.fileSizeMB, true)
      ])],
      document_path: [{
        value: ''
      }, Validators.compose([
        Validators.required,
        Validations.File(this.fileAccept, this.fileSizeMB, true)
      ])],
      document_front: [{
        value: ''
      }, Validators.compose([
        Validators.required,
        Validations.File(this.fileAccept, this.fileSizeMB, true)
      ])],
      document_back: [{
        value: ''
      }, Validators.compose([
        Validators.required,
        Validations.File(this.fileAccept, this.fileSizeMB, true)
      ])],
    })
  }

  getFiles() {
    this.fileService.read().then(result => {
      if (result.files.length > 0) {
        result.files.forEach(res => {
          if (res.type === FileType.document && res.sub_type === FileSubType.selfie) {
            this.labelFilePhoto = res.name;
            this.formGroup.controls.selfie.setValue(res);
            this.formGroup.controls.selfie.markAsDirty();
          } else if (res.type === FileType.document && res.sub_type === FileSubType.rg) {
            this.labelFileDocument = res.name;
            this.formGroup.controls.document_path.setValue(res);
            this.formGroup.controls.document_path.markAsDirty();
          } else if (res.type === FileType.document && res.sub_type === FileSubType.rg_front) {
            this.docFrontBack  = true
            this.labelFileFront = res.name;
            this.formGroup.controls.document_front.setValue(res);
            this.formGroup.controls.document_front.markAsDirty();
          } else if (res.type === FileType.document && res.sub_type === FileSubType.rg_back) {
            this.docFrontBack = true
            this.labelFileBack = res.name;
            this.formGroup.controls.document_back.setValue(res);
            this.formGroup.controls.document_back.markAsDirty();
          } else if (res.type === FileType.document && res.sub_type === FileSubType.address) {
            this.labelFileAddress = res.name;
            this.formGroup.controls.address_path.setValue(res);
            this.formGroup.controls.address_path.markAsDirty();
          }
        });
      }
    })
  }

  onFileChange(file, type, subType) {
    if (file !== null) {
      this.changeFiles = true;
      if (type == FileType.document && subType == FileSubType.selfie) {
        this.formGroup.controls.selfie.setValue(file);
        this.formGroup.controls.selfie.markAsDirty();
      } else if (type == FileType.document && subType == FileSubType.rg) {
        this.formGroup.controls.document_path.setValue(file);
        this.formGroup.controls.document_path.markAsDirty();
      } else if (type == FileType.document && subType == FileSubType.rg_front) {
        this.formGroup.controls.document_front.setValue(file);
        this.formGroup.controls.document_front.markAsDirty();
      } else if (type == FileType.document && subType == FileSubType.rg_back) {
        this.formGroup.controls.document_back.setValue(file);
        this.formGroup.controls.document_back.markAsDirty();
      } else if (type == FileType.document && subType == FileSubType.address) {
        this.formGroup.controls.address_path.setValue(file);
        this.formGroup.controls.address_path.markAsDirty();
      }
    }
  }

  close() {
    window.location.reload();
  }

  // convenience getter for easy access to form fields
  get fileType() { return FileType };
  get fileSubType() { return FileSubType };
}
