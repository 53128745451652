import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { QrCodeModule } from 'ng-qrcode';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskModule } from 'ng2-currency-mask';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TagInputModule } from "ngx-chips";
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxPaginationModule } from 'ngx-pagination';
import { AlertErrorComponent } from '../components/alert-error/alert-error.component';
import { AutoCompleteComponent } from '../components/auto-complete/auto-complete.component';
import { NewQuickAccessComponent } from '../components/cards/new-quick-access/new-quick-access.component';
import { QuickAccessComponent } from "../components/cards/quick-access/quick-access.component";
import { CustomChartComponent } from '../components/custom-chart/custom-chart.component';
import { CustomLoaderComponent } from '../components/custom-loader/custom-loader.component';
import { CloseAccountComponent } from '../components/dialogs/close-account/close-account.component';
import { FilterComponent } from "../components/filter/filter.component";
import { IconSvgComponent } from '../components/icon-svg/icon-svg.component';
import { InlineEditComponent } from '../components/inline-edit/inline-edit.component';
import { InputEditComponent } from '../components/input-edit/input-edit.component';
import { InvalidFeedbackComponent } from '../components/invalid-feedback/invalid-feedback.component';
import { ProfessionsComponent } from '../components/professions/professions.component';
import { TableGroupByComponent } from '../components/table-group-by/table-group-by.component';
import { UploadCroppedComponent } from '../components/upload-cropped/upload-cropped.component';
import { UploadComponent } from '../components/upload/upload.component';
import { FooterComponent } from "../template/footer/footer.component";
import { HeaderMenuComponent } from '../template/header-menu/header-menu.component';
import { WalletResumeChartComponent } from './../components/wallet-resume-chart/wallet-resume-chart.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { AutoTabDirective } from './directives/auto-tab.directive';
import { BlockCopyPastDirective } from './directives/block-copy-past.directive';
import { CpfCnpjMaskDirective } from './directives/cpf-cnpj-mask.directive';
import { CurrencyDirective } from './directives/currency.directive';
import { LettersNumbersDirective } from './directives/letters-numbers.directive';
import { MaskDirective } from './directives/mask.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { PasswordStrengthDirective } from './directives/password-strength.directive';
import { SortDirective } from './directives/sort.directive';
import { Pipes } from './pipes/_pipes';
import { FilterPipe } from './pipes/filter.pipe';
import { OrderByPipe } from './pipes/orderby.pipe';
import { SortDatePipe } from './pipes/sortdate.pipe';
import { SumPipe } from './pipes/sum.pipe';
import { GoalTaxLotsComponent } from '../portal/financial-planning/new-goal/goal-tax-lots/goal-tax-lots.component';


export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
    align: "right",
    allowNegative: true,
    decimal: ",",
    precision: 2,
    prefix: "R$ ",
    suffix: "",
    thousands: "."
}

@NgModule({
    declarations: [
        IconSvgComponent,
        CustomChartComponent,
        Pipes,
        FilterPipe,
        OrderByPipe,
        SortDatePipe,
        CurrencyDirective,
        MaskDirective,
        CpfCnpjMaskDirective,
        BlockCopyPastDirective,
        OnlyNumberDirective,
        PasswordStrengthDirective,
        SortDirective,
        InlineEditComponent,
        WalletResumeChartComponent,
        HeaderMenuComponent,
        CustomLoaderComponent,
        AlertErrorComponent,
        CloseAccountComponent,
        UploadComponent,
        UploadCroppedComponent,
        InvalidFeedbackComponent,
        AutoCompleteComponent,
        ProfessionsComponent,
        LettersNumbersDirective,
        FooterComponent,
        QuickAccessComponent,
        NewQuickAccessComponent,
        FilterComponent,
        AutoTabDirective,
        InputEditComponent,
        SumPipe,
        AutoFocusDirective,
        TableGroupByComponent,
        GoalTaxLotsComponent
    ],
    imports: [
        AccordionModule.forRoot(),
        CommonModule,
        BsDatepickerModule.forRoot(),
        CarouselModule.forRoot(),
        TabsModule.forRoot(),
        ImageCropperModule,
        NgxPaginationModule,
        PdfViewerModule,
        QrCodeModule,
        SortableModule.forRoot(),
        RouterModule,
        TooltipModule.forRoot(),
        TagInputModule,
        FormsModule,
        CurrencyMaskModule,
        NgMultiSelectDropDownModule.forRoot()
    ],
    exports: [
        AccordionModule,
        Pipes,
        FilterPipe,
        OrderByPipe,
        SortDatePipe,
        IconSvgComponent,
        ImageCropperModule,
        CustomChartComponent,
        BsDatepickerModule,
        CarouselModule,
        SortableModule,
        CurrencyDirective,
        MaskDirective,
        SortDirective,
        NgxPaginationModule,
        CpfCnpjMaskDirective,
        BlockCopyPastDirective,
        OnlyNumberDirective,
        PasswordStrengthDirective,
        InlineEditComponent,
        WalletResumeChartComponent,
        HeaderMenuComponent,
        CustomLoaderComponent,
        AlertErrorComponent,
        CloseAccountComponent,
        UploadComponent,
        UploadCroppedComponent,
        InvalidFeedbackComponent,
        AutoCompleteComponent,
        ProfessionsComponent,
        QrCodeModule,
        TabsModule,
        PdfViewerModule,
        LettersNumbersDirective,
        FooterComponent,
        QuickAccessComponent,
        NewQuickAccessComponent,
        TooltipModule,
        FilterComponent,
        FormsModule,
        ReactiveFormsModule,
        AutoTabDirective,
        InputEditComponent,
        CurrencyMaskModule,
        NgMultiSelectDropDownModule,
        SumPipe,
        AutoFocusDirective,
        TableGroupByComponent,
        GoalTaxLotsComponent
    ],
    providers: [
        Pipes,
        { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
        { provide: BsDatepickerConfig, useFactory: getDatepickerConfig }
    ]
})
export class SharedModule {

    constructor(
        private bsLocaleService: BsLocaleService
    ) {
        ptBrLocale.invalidDate = '';
        defineLocale('pt-br', ptBrLocale);
        this.bsLocaleService.use('pt-br');
    }
}

export function getDatepickerConfig(): BsDatepickerConfig {
    return Object.assign(new BsDatepickerConfig(), {
        containerClass: 'theme-blue',
        adaptivePosition: true,
        showWeekNumbers: false,
        selectFromOtherMonth: true,
        isAnimated: true
    });
}
