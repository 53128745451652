<div class="modal-header border-0">
  <p class="fw-bolder text-gold fs-1 mt-2">Encerramento de conta</p>
  <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="row justify-content-center">
  <div class="col col-11 mx-auto text-justify mt-4">
    <p class="title">A LadyBank vai encerrar as contas digitais</p>
    <div class="my-4">
      Até o dia 20 de agosto de 2022, você deverá:
      <ol>
        <li>Transferir o saldo em conta para outro banco <strong>ou</strong> sacar o valor nos caixas da Rede
          Banco24h.</li>
        <li>Encerrar a sua conta.</li>
      </ol>
    </div>
    <div class="msg">
      <p>A conta será encerrada automaticamente a partir do dia 20 de agosto de 2022. Após esta data, se você ainda
        tiver saldo na conta, entre em contato com nossa Central de Atendimento:
        <a href="mailto:atendimento@ladybank.com.br" target="_blank" rel="noopener noreferrer">atendimento@ladybank.com.br</a>
      </p>
    </div>
  </div>
  <!-- <div class="col col-11 mx-auto text-center my-4">
      <strong class="fs-5" [ngClass]="{'text-success': balance > 0}">
        Saldo em conta {{balance | currency: 'BRL'}}</strong>
    </div> -->
</div>
<div class="col my-3 text-center mt-3">
  <button type="button" class="btn btn-primary" (click)="transfer()">TRANSFERIR DINHEIRO</button>
  <button type="button" class="btn btn-primary" (click)="onClose()">ENCERRAR A CONTA</button>
</div>