import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';
import { StorageService as storage } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard  {
  constructor(
    private router: Router,
    private commonService: CommonService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const jwt = storage.getClientJwt();

    if (!jwt) {
      return true;
    }

    return this.commonService.getJWTInfo(jwt)
      .then(res => {
        const is_client = res['type'] === 'client';
        if (is_client) {
          this.router.navigate(['/portal', 'dashboard']);
          return false;
        }
        return true;
      })
      .catch(err => {
        storage.clear();
        this.router.navigate(['/login']);
        return false;
      });
  }
}
