import { valueOrDefault } from './../../shared/commons/Utils';
import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Utils } from 'src/app/shared/commons/Utils';
import { AssetColorsEnum } from 'src/app/shared/enums/asset-color.enum';
import { SuitabilityEnum } from 'src/app/shared/enums/suitability-type.enum';
import { CommonService } from 'src/app/shared/services/common.service';
import { Asset as PartnerAsset } from '../../shared/interfaces/assets';
import { Asset } from '../asset-dialog/asset-dialog.component';
import { InlineEditComponent } from '../inline-edit/inline-edit.component';
import { AssetSubSecType } from './../../shared/interfaces/assets';
import { PartnerService } from './../../shared/services/partner.service';

@Component({
  selector: 'app-asset-choice-dialog',
  templateUrl: './asset-choice-dialog.component.html',
  styleUrls: ['./asset-choice-dialog.component.scss'],
  providers: [
    InlineEditComponent
  ],
  animations: [
    trigger('slideLeft', [
      transition(':enter', [
        style({ transform: 'translateX(0%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(100%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ])
    ]),
    trigger('slideRight', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class AssetChoiceDialogComponent implements OnInit, AfterViewInit {

  @Output() confirmResult = new EventEmitter();
  assetOption: Asset;
  actualWalletDataset: {
    datasets: []
  };

  assetsToAdd: Array<{
    weights: number,
    partner_asset_uuid: string
  }> = [];
  model: any = {};
  assetList: PartnerAsset[] = [];
  selectedAssets = {};
  exchangeList = [];
  filter: any = {};
  listFilter: any = {};
  columns: any = [];
  showSearch = false;
  filterPost: string;
  multipleChoice: boolean

  filtered_assets = [];
  filtered: any = {}
  timeUpdate = new Date(moment().add(-15, 'minute').toString());
  currentPage = 1
  itemsPerPage = 10
  totalItems: any

  constructor(
    private commonService: CommonService,
    public modalRef: BsModalRef,
    private partnerService: PartnerService,
  ) { }

  async ngOnInit() { }

  ngAfterViewInit(): void { }

  pageChanged(event) {
    this.currentPage = event;
    switch (this.assetOption.value) {
      case 'direct-treasure':
        this.getDirectTreasures();
        break;
      case 'fixed-income':
        this.getFixedIncome();
        break;
      case 'exchange':
        this.getExchange();
        break;
      case 'variable-income':
        this.getVariableIncome();
        break;
      case 'investment-fund':
        this.getInvestmentFund();
        break;
      case 'retirement':
        this.getInvestmentFund();
        break;
    }
  }

  getTypeOfRetirement() {
    this.listFilter.first = [
      {
        value: '',
        description: 'Todos',
        selected: true
      },
      {
        value: '',
        description: 'Internacional',
        selected: false
      },
      {
        value: '',
        description: 'Multiestratégia',
        selected: false
      },
      {
        value: '',
        description: 'Renda Variável',
        selected: false
      },
      {
        value: '',
        description: 'Renda Fixa',
        selected: false
      }
    ]

    this.filter.first = this.listFilter.first.find(x => x.selected);
  }

  getTypeOfRentabilitys() {
    this.listFilter.second = [
      {
        value: '',
        type: '',
        description: 'Todos',
        selected: true
      },
      {
        value: '',
        description: 'CDI',
        selected: false
      },
      {
        value: '',
        description: 'PREFIXADO',
        selected: false
      },
      {
        value: '',
        description: 'IPCA',
        selected: false
      }
    ]

    this.filter.second = this.listFilter.second.find(x => x.selected);
  }


  getProducts() {
    this.listFilter.second = [
      {
        value: '',
        description: 'Todos',
        selected: true
      },
      {
        value: '',
        description: 'LC',
        selected: false
      },
      {
        value: '',
        description: 'LCI',
        selected: false
      },
      {
        value: '',
        description: 'LCA',
        selected: false
      },
      {
        value: '',
        description: 'CRI',
        selected: false
      },
      {
        value: '',
        description: 'CRA',
        selected: false
      },
      {
        value: '',
        description: 'CDB-PRÉ',
        selected: false
      },
      {
        value: '',
        description: 'CDB-PÓS',
        selected: false
      },
      {
        value: '',
        description: 'Debêntures',
        selected: false
      }
    ]

    this.filter.second = this.listFilter.second.find(x => x.selected);
  }

  getEmitters() {
    this.listFilter.third = [
      {
        value: '',
        description: 'Todos',
        selected: true
      },
      {
        value: '',
        description: 'Exemplo 01',
        selected: false
      },
      {
        value: '',
        description: 'Exemplo 02',
        selected: false
      },
      {
        value: '',
        description: 'Exemplo 03',
        selected: false
      },
      {
        value: '',
        description: 'Exemplo 04',
        selected: false
      }
    ]

    this.filter.third = this.listFilter.third.find(x => x.selected);
  }

  getIndexeres() {
    this.listFilter.fourth = [
      {
        value: '',
        type: '',
        description: 'Todos',
        selected: true
      },
      {
        value: '',
        type: 'direct-treasure',
        description: 'Selic',
        selected: false
      },
      {
        value: '',
        type: 'direct-treasure',
        description: 'Pré-fixado',
        selected: false
      },
      {
        value: '',
        type: 'direct-treasure',
        description: 'Inflação',
        selected: false
      }
    ]

    this.filter.fourth = this.listFilter.fourth.find(x => x.selected);
  }

  getPeriods() {
    this.listFilter.fifth = [
      {
        value: '',
        description: 'Todos',
        selected: true
      },
      {
        value: '',
        description: 'No vencimento',
        selected: false
      },
      {
        value: '',
        description: 'Diária',
        selected: false
      }
      // {
      //   id: 0,
      //   selected: true,
      //   description: 'No vencimento',
      //   value: moment().format()
      // },
      // {
      //   id: 1,
      //   selected: false,
      //   description: 'Hoje',
      //   value: moment().format()
      // },
      // {
      //   id: 2,
      //   selected: false,
      //   description: 'Amanhã',
      //   value: moment().add(1, 'day')
      // },
      // {
      //   id: 3,
      //   selected: false,
      //   description: 'Próximos 7 dias',
      //   value: moment().add(7, 'day')
      // },
      // {
      //   id: 4,
      //   selected: false,
      //   description: 'Próximos 15 dias',
      //   value: moment().add(15, 'day')
      // },
      // {
      //   id: 5,
      //   selected: false,
      //   description: 'Próximos 30 dias',
      //   value: moment().add(30, 'day')
      // },
      // {
      //   id: 6,
      //   selected: false,
      //   description: 'Próximos 90 dias',
      //   value: moment().add(90, 'day')
      // },
      // {
      //   id: 7,
      //   selected: false,
      //   description: 'Daqui a 1 ano',
      //   value: moment().add(1, 'year')
      // },
      // {
      //   id: 8,
      //   selected: false,
      //   description: 'Desde o início',
      //   value: ''
      // },
      // {
      //   id: 9,
      //   selected: false,
      //   description: 'Personalizado',
      //   value: ''
      // }
    ]
    this.filter.fifth = this.listFilter.fifth.find(x => x.selected);
  }

  currencyTypes() {
    this.listFilter.first = [
      {
        value: '',
        description: 'Dólar',
        selected: true
      },
      {
        value: '',
        description: 'Euro',
        selected: false
      }
    ]

    this.filter.first = this.listFilter.first.find(x => x.selected);
  }

  getActions() {
    this.listFilter.first = [
      {
        value: '',
        description: 'Ações',
        selected: true
      },
      {
        value: '',
        description: 'BDRs',
        selected: false
      },
      {
        value: '',
        description: 'Fundo imobiliários',
        selected: false
      }
    ]

    this.filter.first = this.listFilter.first.find(x => x.selected);
  }

  getProfiles() {
    this.listFilter.first = this.getSuitabilityProfile()
    this.listFilter.first.push({
      value: '',
      description: 'Todos',
      selected: true
    })

    this.filter.first = this.listFilter.first.find(x => x.selected);
  }

  getSuitabilityProfile() {
    return Object.keys(SuitabilityEnum).map(key => {
      return ({ value: key, description: SuitabilityEnum[key], selected: false })
    })
  }

  getFunds() {
    this.listFilter.second = [
      {
        value: '',
        description: 'Todos',
        selected: true
      },
      {
        value: '',
        description: 'Renda fixa - Pós',
        selected: false
      },
      {
        value: '',
        description: 'Renda fixa - Pré',
        selected: false
      },
      {
        value: '',
        description: 'Renda fixa - Inflação',
        selected: false
      },
      {
        value: '',
        description: 'Multimercados',
        selected: false
      },
      {
        value: '',
        description: 'Renda variável',
        selected: false
      },
      {
        value: '',
        description: 'Cambial',
        selected: false
      }
    ]

    this.filter.second = this.listFilter.second.find(x => x.selected);
  }

  getInsurers(first) {
    console.log({ asset: this.assetOption, actualWalletDataset: this.actualWalletDataset })
    const insures = [
      {
        value: '',
        description: 'Todas',
        selected: true
      },
      {
        value: '',
        description: 'Icatu',
        selected: false
      },
      {
        value: '',
        description: 'SulAmérica',
        selected: false
      },
      {
        value: '',
        description: 'XP Seguros',
        selected: false
      }
    ]
    if (first) {
      this.listFilter.first = insures
      this.filter.first = this.listFilter.first.find(x => x.selected);
    } else {
      this.listFilter.second = insures
      this.filter.second = this.listFilter.second.find(x => x.selected);
    }
  }

  getRescueOn() {
    this.listFilter.third = [
      {
        value: '',
        description: 'Até D + 1',
        selected: true
      },
      {
        value: '',
        description: 'Até D + 5',
        selected: false
      },
      {
        value: '',
        description: 'Até D + 10',
        selected: false
      },
      {
        value: '',
        description: 'Até D + 30',
        selected: false
      },
      {
        value: '',
        description: 'maior que D + 30',
        selected: false
      }
    ]

    this.filter.third = this.listFilter.third.find(x => x.selected);
  }

  getRisk() {
    this.listFilter.fourth = [
      {
        value: '',
        description: 'Todos',
        selected: true
      },
      {
        value: '',
        description: 'Risco Baixo',
        selected: false
      },
      {
        value: '',
        description: 'Risco Médio',
        selected: false
      },
      {
        value: '',
        description: 'Risco Muito Alto',
        selected: false
      }
    ]

    this.filter.fourth = this.listFilter.fourth.find(x => x.selected);
  }

  async getDirectTreasures() {
    let subSecTypes: AssetSubSecType[] = ['lft', 'ltn', 'ntn_f', 'ntn_b', 'ntn_b_principal', 'ntn_c']
    if (this.filter.sub_sectype)
      subSecTypes = this.filter.sub_sectype
    // TODO: Filtro de indexadores

    const result: any = await this.partnerService.getPartnerAssets('bond', this.currentPage, this.itemsPerPage, subSecTypes);
    this.totalItems = result.statistics.total_rows

    this.assetList = result.assets;
    this.filtered_assets = result.assets;
    const secSubTypesArray: string[] = [];
    this.assetList.forEach(x => secSubTypesArray.push(x.sub_sectype));
    const uniqueSecSubTypes = [...new Set(secSubTypesArray)];
    const mappedSecSubTypes = uniqueSecSubTypes.map(x => ({ value: x, description: x.replace('_', '-'), selected: false }));
    mappedSecSubTypes.unshift({ value: 'Todos', description: 'Todos', selected: true });

    this.listFilter.first = mappedSecSubTypes;

    this.columns = [
      { name: 'Nome', class: 'col-3 text-start' },
      { name: 'Vencimento', class: 'text-center' },
      { name: 'Taxa do cupom', class: 'text-center' },
      { name: 'Rentabilidade (a.a)', class: 'text-center' },
      { name: 'Preço de venda', class: 'text-center' },
      { name: 'Preço de compra', class: 'text-center' },
      { name: 'Aplicação mínima', class: 'text-center' },
    ]
  }

  getBrokers(assets: PartnerAsset[]) {
    const brokersArray: string[] = [];
    assets.forEach(x => brokersArray.push(x.partner_assets[0]?.broker?.name));
    const uniqueBrokers = [...new Set(brokersArray)];
    const mappedBrokers = uniqueBrokers.map(x => ({ value: x, description: x.replace('_', '-'), selected: false }));
    mappedBrokers.unshift({ value: 'Todos', description: 'Todos', selected: true });
    return mappedBrokers
  }

  getTypesOfFundInvestiment(assets: PartnerAsset[]) {
    const typesOfFundsArray: string[] = [];
    assets.forEach(x => typesOfFundsArray.push(x.partner_assets[0] ? x.partner_assets[0].classification?.class_2 : x.partner_assets[1]?.classification?.class_2));
    const uniqueTypesOfFunds = [...new Set(typesOfFundsArray)];
    const mappedTypesOfFunds = uniqueTypesOfFunds.filter(x => x !== null).map(x => ({ value: x, description: x, selected: false }));
    mappedTypesOfFunds.unshift({ value: 'Todos', description: 'Todos', selected: true });
    return mappedTypesOfFunds
  }

  getAssetLiquidityRescue(assets: PartnerAsset[]) {
    const liquiditiesArray: string[] = [];
    // assets.forEach(x => liquiditiesArray.push(x.partner_assets[0]?.liquidity_tenor_min));
    const uniqueLiquidity = [...new Set(liquiditiesArray)];
    const mappedLiquidity = uniqueLiquidity?.filter(x => x !== null).map(x => ({ value: x, description: `D + ${x}`, selected: false }));
    mappedLiquidity.unshift({ value: 'Todos', description: 'Todos', selected: true });
    return mappedLiquidity
  }

  getIssuers(issuers: any[]) {
    const issuersList = issuers?.filter(x => x !== null).map(x => ({ value: x.id, description: x.short_name, selected: false }));
    issuersList.unshift({ value: 'Todos', description: 'Todos', selected: true });
    return issuersList
  }

  async getFixedIncome() {
    let subSecTypes: AssetSubSecType[] = ['cdb', 'lca', 'lci', 'lc', 'debenture', 'rdb', 'cra', 'cri', 'dpge']
    if (this.filter.sub_sectype)
      subSecTypes = this.filter.sub_sectype

    let otherFilters: any = {}
    // TODO: criar filtro indexadores
    if (this.filter.broker_name)
      otherFilters.broker_name = this.filter.broker_name.join(',')
    if (this.filter.liquidity_tenor_min_less)
      otherFilters.liquidity_tenor_min_less = this.filter.liquidity_tenor_min_less
    if (this.filter.issuer_name_like)
      otherFilters.issuer_name_like = this.filter.issuer_name_like
    if (this.filter.issuer_ids)
      otherFilters.issuer_id = this.filter.issuer_ids.join(',')

    const result: any = await this.partnerService.getPartnerAssets('bond', this.currentPage, this.itemsPerPage, subSecTypes, otherFilters);
    this.totalItems = result.statistics.total_rows

    this.filtered_assets = result.assets;

    this.assetList = result.assets;

    this.columns = [
      { name: 'Tipo', class: 'text-center' },
      { name: 'Emissor', class: 'text-start' },
      { name: 'Rentabilidade &#8593', class: 'text-center' },
      { name: 'Vencimento', class: 'text-center' },
      { name: 'Liquidez', class: 'text-center' },
      { name: 'Valor mínimo', class: 'text-end' },
    ]

    this.listFilter.first = this.getBrokers(this.assetList);


    const secSubTypesArray: string[] = [];

    this.assetList.forEach(x => secSubTypesArray.push(x.sub_sectype));

    const uniqueSecSubTypes = [...new Set(secSubTypesArray)];

    const mappedSecSubTypes = uniqueSecSubTypes.map(x => ({ value: x, description: x.replace('_', '-').toUpperCase(), selected: false }));
    mappedSecSubTypes.unshift({ value: 'Todos', description: 'Todos', selected: true });
    this.listFilter.fourth = mappedSecSubTypes;
  }

  getAssetRentability(asset: PartnerAsset) {
    if (!asset.bond.is_yield_quote_mult) {
      if (asset.bond.principal_add_index.name === 'Cash in BRL') {
        return `${(asset.last_price * 100).toFixed(2)}% pré-fixado`.replace('.', ',')
      }
      return `${this.normalizeBondIndexName(asset.bond.principal_add_index.name)} + ${asset.bond.principal_add_index.last_price.toFixed(2)}%`.replace('.', ',')
    }
    if (asset.bond.principal_mult_index.name === 'Cash in BRL') {
      return `${(asset.last_price * 100).toFixed(2)}%`.replace('.', ',')
    }
    return `${(asset.last_price * 100).toFixed(2)} % ${this.normalizeBondIndexName(asset.bond.principal_mult_index.name)}`.replace('.', ',')
  }

  normalizeBondIndexName(name: string) {
    const indexes = {
      'IPCA_FACTOR': 'IPCA',
      'CDI_FACTOR': 'CDI',
      'Cash in BRL': null,
    }

    return indexes[name] || name
  }

  getAssetDueDate(asset: PartnerAsset) {
    return new Date(asset.bond.maturity_date * 1000);
  }

  getAssetLiquidity(asset: PartnerAsset) {
    return asset.bond.issuer_liquidity === 'daily' ? 'Diária' : 'Sem Liquidez'
  }

  getAssetMinAmount(asset: PartnerAsset) {
    if (asset.partner_assets.length && asset.partner_assets[0].min_invest_amount) {
      return asset.partner_assets[0].min_invest_amount;
    }
    return 0;
  }

  getExchange() {

    const exchanges = [
      {
        name: 'BV DOlAR CAMBIAL FIC DE FI',
        type: 'BTC',
        corretora: 'Mercado Bitcoin',
        rentabilidadeMensal: 0.22,
        rentabilidadeAnual: 3.45,
        rentabilidade12Meses: 11.45,
        valorMinimo: 1000,
        risk: 'low'
      },
      {
        name: 'DOLAR F1 CAMBIAL',
        type: 'ETH',
        corretora: 'Kraken',
        rentabilidadeMensal: 0.22,
        rentabilidadeAnual: 3.45,
        rentabilidade12Meses: 11.45,
        valorMinimo: 1000,
        risk: 'medium'
      },
      {
        name: 'OCCAM FUNDO DE INVESTIMENTO EUROPEU',
        type: 'BTCUSD',
        corretora: 'Binance',
        rentabilidadeMensal: 0.22,
        rentabilidadeAnual: 3.45,
        rentabilidade12Meses: 11.45,
        valorMinimo: 1000,
        risk: 'high',
      },
      {
        name: 'BV USD SHORT CAMBIAL FIC',
        type: 'LTC',
        corretora: 'Binance',
        rentabilidadeMensal: 0.22,
        rentabilidadeAnual: 3.45,
        rentabilidade12Meses: 11.45,
        valorMinimo: 1000,
        risk: 'high'
      },
    ];

    this.exchangeList = exchanges;
    this.totalItems = this.exchangeList.length

    // const result = await this.partnerService.getPartnerAssets('index', this.currentPage, this.itemsPerPage);

    this.columns = [
      { name: 'Nome do fundo', class: 'text-start' },
      { name: 'Tipo', class: 'text-center' },
      { name: 'Corretora', class: 'text-center' },
      { name: 'Valor mínimo', class: 'text-end' },
      { name: 'Mensal', class: 'text-center' },
      { name: 'Anual', class: 'text-center' },
      { name: '12 meses', class: 'text-center' },
    ]
  }

  async getVariableIncome() {
    let subSecTypes: AssetSubSecType[]
    if (this.filter.sub_sectype)
      subSecTypes = this.filter.sub_sectype

    let otherFilters: any = {}
    if (this.filter.broker_name)
      otherFilters.broker_name = this.filter.broker_name.join(',')

    const result: any = await this.partnerService.getPartnerAssets('stock', this.currentPage, this.itemsPerPage, subSecTypes, otherFilters);
    this.totalItems = result.statistics.total_rows
    this.assetList = result.assets;
    this.filtered_assets = result.assets;
    const secSubTypesArray: string[] = [];
    this.assetList.forEach(x => secSubTypesArray.push(x.sub_sectype));
    const uniqueSecSubTypes = [...new Set(secSubTypesArray)];
    const mappedSecSubTypes = uniqueSecSubTypes.map(x => ({ value: x, description: x.replace('_', '-'), selected: false }));
    mappedSecSubTypes.unshift({ value: 'Todos', description: 'Todos', selected: true });

    const brokersArray: string[] = [];
    this.assetList.forEach(x => brokersArray.push(x.partner_assets[0]?.broker?.name));
    const uniqueBrokers = [...new Set(brokersArray)];
    const mappedBrokers = uniqueBrokers.map(x => ({ value: x, description: x.replace('_', '-'), selected: false }));
    mappedBrokers.unshift({ value: 'Todos', description: 'Todos', selected: true });

    this.getPeriods();

    this.columns = [
      { name: 'Nome', class: 'text-start' },
      { name: 'Ticker', class: 'text-center' },
      { name: 'Preço atual', class: 'text-end' },
      { name: 'Variação (dia)', class: 'text-center' },
      { name: 'Detalhes', class: 'text-center' },
    ]
  }

  async getInvestmentFund() {
    let subSecTypes: AssetSubSecType[]
    if (this.filter.sub_sectype)
      subSecTypes = this.filter.sub_sectype

    let otherFilters: any = {}
    if (this.filter.asset_name_like) // Nome
      otherFilters.asset_name_like = this.filter.asset_name_like
    if (this.filter.issuer_name_like) // Gestora
      otherFilters.issuer_name_like = this.filter.issuer_name_like
    if (this.filter.min_invest_amount_less) // Valor mínimo
      otherFilters.min_invest_amount_less = this.filter.min_invest_amount_less
    if (this.filter.risk_level) {
      otherFilters.risk_level = this.filter.risk_level
    }

    const result: any = await this.partnerService.getPartnerAssets('fund', this.currentPage, this.itemsPerPage, subSecTypes, otherFilters);
    this.totalItems = result.statistics.total_rows

    this.assetList = result.assets;
    this.filtered_assets = result.assets;
    this.columns = [
      { name: 'Nome do fundo', class: 'text-start' },
      { name: 'Tipo', class: 'text-center' },
      { name: 'Gestora', class: 'text-center' },
      { name: 'Resgate', class: 'text-center' },
      { name: 'Valor mínimo', class: 'text-center' },
      { name: 'Mensal', class: 'text-center' },
      { name: 'Anual', class: 'text-center' },
      { name: '12 meses', class: 'text-center' },
    ]
  }

  getRetirement() {
    this.columns = [
      { name: 'Nome do fundo', class: 'text-start' },
      { name: 'Tipo', class: 'text-center' },
      { name: 'Resgate', class: 'text-center' },
      { name: 'Rent. Mensal', class: 'text-center' },
      { name: 'Rent. Anual', class: 'text-center' },
      { name: 'Rent. 12 meses', class: 'text-center' },
      { name: 'Valor mínimo', class: 'text-center' },
    ]
  }

  getAssetRiskLevel(asset: PartnerAsset) {
    const partnerWithRisk = asset?.partner_assets?.find(part => part.risk_level);

    if (!partnerWithRisk) {
      return 'low';
    }

    const { risk_level } = partnerWithRisk;

    const levelsOfRisks = {
      1: 'low',
      2: 'low',
      3: 'medium',
      4: 'medium',
      5: 'high',
      6: 'high',
      7: 'high',
      8: 'high',
    };

    if (risk_level >= 8) {
      return 'high';
    }
    return levelsOfRisks[risk_level];
  }

  getFilters() {
    const types = [{ 'label': 'Todos', 'value': 'all' }, { 'label': 'LFTs', 'value': 'lft' }, { 'label': 'LTNs', 'value': 'ltn' },
    { 'label': 'NTN-F', 'value': 'ntn_f' }, { 'label': 'NTN-B', 'value': 'ntn_b' }, { 'label': 'NTN-C', 'value': 'ntn_c' }]
    let type = this.assetOption.value;
    let filter_options: any = [];

    if (type == 'investment-fund') {
      let brokers = this.getBrokers(this.assetList);
      let liquidity = this.getAssetLiquidityRescue(this.assetList);

      for (let field of [brokers, liquidity]) {
        for (let o of field) {
          o['label'] = o['description'];
          o['value'] = o['value'].toString();
          delete o['description'];
        }
      }

      filter_options = [
        {
          'title': 'Nome',
          'model': '',
          'format': 'text',
          'placeholder': 'Filtre por nome do fundo',
          'secondary_placeholder': '',
          'items': []
        },
        {
          'title': 'Gestora',
          'model': '',
          'format': 'text',
          'placeholder': 'Filtre por gestora do fundo',
          'secondary_placeholder': '',
          'items': []
        },
        {
          'title': 'Tipos',
          'model': [{ 'label': 'Todos', 'value': 'all', 'exclusive': true }],
          'format': 'tags',
          'placeholder': 'Filtre por tipos de fundos',
          'secondary_placeholder': 'Outros fundos',
          'items': types
        },
        {
          'title': 'Valor mínimo',
          'model': '',
          'format': 'currency',
          'placeholder': 'Filtre por valor mínimo dos fundos',
          'secondary_placeholder': '',
          'items': []
        }
      ]
    }
    else if (['exchange', 'retirement'].includes(type)) {
      let brokers = this.getBrokers(this.assetList);
      // let types = this.getTypesOfFundInvestiment(this.assetList);
      let liquidity = this.getAssetLiquidityRescue(this.assetList);

      for (let field of [brokers, liquidity]) {
        for (let o of field) {
          o['label'] = o['description'];
          o['value'] = o['value'].toString();
          delete o['description'];
        }
      }


      filter_options = [
        {
          'title': 'Tipos',
          'model': [{ 'label': 'Todos', 'value': 'all', 'exclusive': true }],
          'format': 'tags',
          'placeholder': 'Filtre por tipos de fundos',
          'secondary_placeholder': 'Outros fundos',
          'items': types
        },
        {
          'title': 'Corretoras',
          'model': [{ 'label': 'Todos', 'value': 'all', 'exclusive': true }],
          'format': 'tags',
          'placeholder': 'Filtre por corretoras',
          'secondary_placeholder': 'Outras corretoras',
          'items': brokers
        },
        {
          'title': 'Liquidez',
          'model': [{ 'label': 'Todas', 'value': 'all', 'exclusive': true }],
          'format': 'tags',
          'placeholder': 'Filtre por diferentes liquidações',
          'secondary_placeholder': 'Outras',
          'items': liquidity
        }
      ]
    }
    else if (type == 'variable-income') {
      const secSubTypesArray: string[] = [];
      this.assetList.forEach(x => secSubTypesArray.push(x.sub_sectype));
      const uniqueSecSubTypes = [...new Set(secSubTypesArray)];
      const mappedSecSubTypes = uniqueSecSubTypes.map(x => ({ value: x, label: x.replace('_', '-'), selected: false }));
      mappedSecSubTypes.unshift({ value: 'all', label: 'Todos', selected: true });

      const brokersArray: string[] = [];
      this.assetList.forEach(x => brokersArray.push(x.partner_assets[0]?.broker?.name));
      const uniqueBrokers = [...new Set(brokersArray)];
      const mappedBrokers = uniqueBrokers.map(x => ({ value: x, label: x.replace('_', '-'), selected: false }));
      mappedBrokers.unshift({ value: 'all', label: 'Todos', selected: true });

      filter_options = [
        {
          'title': 'Tipos',
          'model': [{ 'label': 'Todos', 'value': 'all', 'exclusive': true }],
          'format': 'tags',
          'placeholder': 'Filtre por tipos de acoes',
          'secondary_placeholder': 'Outras acoes',
          'items': types
        },
        {
          'title': 'Corretoras',
          'model': [{ 'label': 'Todos', 'value': 'all', 'exclusive': true }],
          'format': 'tags',
          'placeholder': 'Filtre por corretoras',
          'secondary_placeholder': 'Outras corretoras',
          'items': mappedBrokers
        }
      ]
    }
    else if (type == 'direct-treasure') {
      filter_options = [
        {
          'title': 'Tipos',
          'model': [{ 'label': 'Todos', 'value': 'all', 'exclusive': true }],
          'format': 'tags',
          'placeholder': 'Filtre por tipos de tesouros diretos',
          'secondary_placeholder': '',
          'items': types
        },
        {
          'title': 'Indexadores',
          'model': [{ 'label': 'Todos', 'value': 'all', 'exclusive': true }],
          'format': 'tags',
          'placeholder': 'Filtre por indexadores ',
          'secondary_placeholder': '',
          'items': [{ 'label': 'CDI', 'value': 'CDI' }, { 'label': 'IPCA', 'value': 'IPCA' }]
        }
      ]
    }
    else if (type == 'fixed-income') {
      let brokers = this.getBrokers(this.assetList);

      for (let field of [brokers]) {
        for (let o of field) {
          o['label'] = o['description'];
          o['value'] = o['value'].toString();
          delete o['description'];
        }
      }

      filter_options = [
        {
          'title': 'Corretoras',
          'model': [{ 'label': 'Todas', 'value': 'all', 'exclusive': true }],
          'format': 'tags',
          'placeholder': 'Filtre por diferentes corretoras',
          'secondary_placeholder': 'Outras corretoras',
          'items': brokers,
        },
        {
          'title': 'Tipos',
          'model': [{ 'label': 'Todos', 'value': 'all', 'exclusive': true }],
          'format': 'tags',
          'placeholder': 'Filtre por diferentes tipos de Renda Fixa',
          'secondary_placeholder': 'Outros tipos',
          'items': types,
        },
        {
          'title': 'Emissores',
          'model': '',
          'format': 'text',
          'placeholder': 'Filtre por Emissores',
          'secondary_placeholder': '',
          'items': []
        },
        {
          'title': 'Liquidez',
          'model': '',
          'format': 'numeric',
          'placeholder': 'Filtre por liquidez',
          'secondary_placeholder': '',
          'items': [],
        },
        {
          'title': 'Vencimento',
          'type': 'date',
          'model': { label: 'À vencer', description: 'to_win', value: [moment().add(1, 'year'), moment().add(1, 'day')] },
          'format': 'single',
          'items': [
            { label: 'Personalizado', description: 'custom_date', value: '' },
            { label: 'Vencidos', description: 'overdue', value: [moment().subtract(1, 'year'), moment().subtract(1, 'day')] },
            { label: 'À vencer', description: 'to_win', value: [moment().add(1, 'year'), moment().add(1, 'day')] }
          ]
        }
      ]
    }

    return filter_options;
  }

  filterAssets(filter_data) {
    this.filter = {}
    for (let filter of filter_data) {
      if (filter.format == 'tags' && filter.model.map(x => x.value)?.includes('all'))
        continue;
      switch (filter.title) {
        case ('Tipos'):
          this.filter.sub_sectype = []
          filter.model.forEach(item => {
            this.filter.sub_sectype.push(item.value)
          })
          break
        case ('Corretoras'):
          this.filter.broker_name = []
          filter.model.forEach(item => {
            this.filter.broker_name.push(item.value)
          })
          break
        case ('Liquidez'):
          this.filter.liquidity_tenor_min_less = filter.model
          break
        case ('Indexadores'):
          // TODO: criar filtro indexadores
          break
        case ('Gestora'):
          this.filter.issuer_name_like = filter.model
          break
        case ('Valor mínimo'):
          this.filter.min_invest_amount_less = Utils.moneyTofloat(filter.model)
          break
        case ('Nome'):
          this.filter.asset_name_like = filter.model
          break
        case ('Emissores'):
          this.filter.issuer_name_like = filter.model
          break
        case ('Vencimento'):
          // this.filter.dt_ini = filter.model.value[0]
          // this.filter.dt_fim = filter.model.value[1]
          break
      }
    }
    this.pageChanged(1)
  }

  filterRiskLevel(risk_level) {
    this.filter.risk_level = risk_level
    this.pageChanged(1)
  }

  selectAsset(asset) {
    if (!asset.partner_assets[0]?.classification?.class_1) {
      asset.partner_assets[0].classification = {
        class_1: this.assetOption.name
      }
    }

    if (this.multipleChoice) {
      if (this.selectedAssets[asset.uuid]) {
        delete this.selectedAssets[asset.uuid];
      } else {
        this.selectedAssets[asset.uuid] = asset;
      }
    } else {
      this.selectedAssets = {}
      this.selectedAssets[asset.uuid] = asset;
    }
  }

  isSelected(asset) {
    return asset.uuid in this.selectedAssets;
  }

  confirmAndClose() {
    
    this.confirmResult.emit(this.selectedAssets);
    this.modalRef.hide();
  }

  get AssetColor() {
    return AssetColorsEnum;
  }
}
