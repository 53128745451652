<div class="modal-header mx-3 pb-0">
  <strong class="modal-title fs-4 pb-0">Alterar senha</strong>
  <button type="button" class="btn-close" (click)="modalRef.hide()"></button>
</div>
<div class="modal-body mx-3 pt-0">
  <p class="fs-7">Por motivos de segurança, confirme sua senha atual</p>
  <form [formGroup]="formGroup" class="row gap-0 row-gap-3 fs-8" autocomplete="off" novalidate>
    <div class="form-group">
      <label for="password" class="fw-bold">Senha atual</label>
      <div class="input-group">
        <input [type]="this.inputOptions[0].type" class="form-control border-end-0" placeholder="*****************"
          formControlName="password">
        <div class="input-group-text bg-transparent rounded-end"
          [ngClass]="{'border border-danger border-start-0': (!f.password.pristine || f.password.touched) && f.password.errors }">
          <i class="bi {{this.inputOptions[0].icon}}" (click)="previewPassword(0)"></i>
        </div>
        <invalid-feedback [field]="f.password"></invalid-feedback>
      </div>
    </div>
    <div class="form-group">
      <label for="new_password" class="fw-bold">Nova senha</label>
      <div class="input-group">
        <input [type]="this.inputOptions[1].type" class="form-control border-end-0" placeholder="*****************"
          formControlName="new_password">
        <div class="input-group-text bg-transparent rounded-end"
          [ngClass]="{'border border-danger border-start-0': (!f.new_password.pristine || f.new_password.touched) && f.new_password.errors }">
          <i class="bi {{this.inputOptions[1].icon}}" (click)="previewPassword(1)"></i>
        </div>
        <invalid-feedback [field]="f.new_password"></invalid-feedback>
      </div>
    </div>
    <div class="form-group">
      <label for="confirm_password" class="fw-bold">Redigite a nova senha</label>
      <div class="input-group">
        <input [type]="this.inputOptions[2].type" class="form-control border-end-0" placeholder="*****************"
          formControlName="confirm_password" PreventCopyPast>
        <div class="input-group-text bg-transparent rounded-end"
          [ngClass]="{'border border-danger border-start-0': (!f.confirm_password.pristine || f.confirm_password.touched) && f.confirm_password.errors }">
          <i class="bi {{this.inputOptions[2].icon}}" (click)="previewPassword(2)"></i>
        </div>
        <invalid-feedback [field]="f.confirm_password"></invalid-feedback>
      </div>
    </div>
  </form>
  <div class="row mt-2">
    <div class="col-12 text-primary fs-8" (click)="onForgotPassword()" role="button">Esqueci a senha</div>
  </div>
  <div class="d-flex justify-content-between mt-4">
    <button type="submit" class="btn btn-outline-dark px-5 mx-0" (click)="modalRef.hide()">CANCELAR</button>
    <button type="submit" class="btn btn-primary px-5 mx-0" (click)="showModalToken()">ALTERAR</button>
  </div>
</div>